import React from 'react';
import styled from 'styled-components';
import ButtonBack from './ButtonBack';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

export const ContentHeightFull = styled.div`
  height: 100%;
  h1,
  h2 {
  }
`;

export const ContentWHFull = styled.div`
  height: 100%;
  width: 100%;
`;

export const SvgRedirectBankLogoWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  img {
    min-width: 80px;
    max-width: 100px;
    max-height: 100px;
  }
`;
export const GridRowsWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
`;

export const ContentCenter = styled.div`
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
`;

export const ContentWHFullFlexXYCenter = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
`;

export const ButtonLinkStyled = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const TitleWithBackButton = styled(
  ({ className, children, ...props }) => {
    const history = useHistory();

    return (
      <div className={className}>
        <ButtonBack history={history} variant={'round'} {...props} />
        <span>{children || 'Back'}</span>
      </div>
    );
  },
)`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 1rem;
  align-items: center;
  padding: 0 0 1rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;
  }
`;

export const ControlButtonsSpaceBetweenWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  width: 100%;
  justify-content: space-between;
  grid-auto-columns: max-content;
`;

export const ControlButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  grid-auto-columns: max-content;
`;

export const LayoutTwoItemSpaceBetweenDotted = ({ leftItem, rightItem }) => {
  const wrapWithTypography = item => {
    if (React.isValidElement(item)) {
      return item;
    } else {
      return <Typography className={'overflowWrap'}>{item}</Typography>;
    }
  };

  return (
    <LayoutTwoItemSpaceBetweenDottedStyled>
      <div className={'dottedGrayText'}>
        <div className={'-left'}>{wrapWithTypography(leftItem)}</div>
        <div className={'-dotted'} />
        <div className={clsx('-right')}>{wrapWithTypography(rightItem)}</div>
      </div>
    </LayoutTwoItemSpaceBetweenDottedStyled>
  );
};

const LayoutTwoItemSpaceBetweenDottedStyled = styled.div`
  margin-bottom: 0.5rem;

  .overflowWrap {
    overflow-wrap: break-word;
    max-width: 100%;
  }

  .dottedGrayText {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content 1fr max-content;
    grid-auto-rows: max-content;
    & .MuiTypography-root {
      font-size: 0.9rem;
    }
    & .-dotted {
      border-bottom: 1px dotted #ccc;
    }
    & .-left .MuiTypography-root {
      color: #b6b6b6;
    }
    & .-right .MuiTypography-root {
      font-weight: 500;
      max-width: 250px;
      overflow: auto;
    }
  }
`;
