import React, { useContext } from 'react';
import { LayoutsTemplatesContext } from 'layouts/TemplatesContext';

const Layout = ({ children, ...props }) => {
  const contextData = useContext(LayoutsTemplatesContext);

  return <contextData.mainPage {...props}>{children}</contextData.mainPage>;
};

export default Layout;
