import React from 'react';

import './style.css';

const ConsentRedirectFromBank = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="214"
        height="51"
        viewBox="0 0 214 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M206.281 16.1986C202.708 16.1986 199.801 13.2915 199.801 9.71833C199.801 7.71508 200.715 5.92161 202.148 4.73191C198.517 2.47908 194.269 1.12913 189.721 1V10.6993C197.595 11.0652 203.935 17.4052 204.302 25.2804H214C213.886 21.257 212.812 17.4715 211.008 14.1372C209.824 15.4019 208.146 16.1986 206.281 16.1986Z"
          fill="#FAB833"
        />
        <path
          d="M188.28 10.6983V1C183.732 1.12862 179.484 2.47857 175.853 4.73191C177.285 5.92161 178.2 7.71457 178.2 9.71833C178.2 13.2915 175.293 16.1981 171.72 16.1981C169.855 16.1981 168.176 15.4019 166.993 14.1367C165.188 17.4715 164.114 21.2565 164 25.2799H173.699C174.065 17.4047 180.405 11.0642 188.28 10.6983Z"
          fill="#5BBDD7"
        />
        <path
          d="M173.699 26.7202H164C164.115 30.7441 165.188 34.5296 166.993 37.8629C168.176 36.5981 169.855 35.8014 171.719 35.8014C175.293 35.8014 178.2 38.7086 178.2 42.2817C178.2 44.285 177.285 46.0785 175.853 47.2687C179.484 49.5215 183.732 50.8709 188.28 51.0001V41.3013C180.405 40.9359 174.065 34.5954 173.699 26.7202Z"
          fill="#ED6C77"
        />
        <path
          d="M199.801 42.2817C199.801 38.7085 202.708 35.8019 206.281 35.8019C208.146 35.8019 209.824 36.5981 211.008 37.8633C212.812 34.529 213.886 30.7435 214 26.7207H204.301C203.935 34.5954 197.595 40.9358 189.72 41.3013V50.9995C194.268 50.8704 198.516 49.5215 202.147 47.2681C200.715 46.0784 199.801 44.285 199.801 42.2817Z"
          fill="#B8B4B3"
        />
        <path
          d="M201.24 9.71841C201.24 12.4974 203.501 14.7584 206.28 14.7584C209.06 14.7584 211.321 12.4974 211.321 9.71841C211.321 6.93939 209.06 4.67841 206.28 4.67841C203.501 4.67841 201.24 6.93939 201.24 9.71841Z"
          fill="#FAB833"
        />
        <path
          d="M206.281 37.2417C203.502 37.2417 201.241 39.5032 201.241 42.2817C201.241 45.0607 203.502 47.3217 206.281 47.3217C209.06 47.3217 211.321 45.0607 211.321 42.2817C211.321 39.5032 209.06 37.2417 206.281 37.2417Z"
          fill="#B8B4B3"
        />
        <path
          d="M171.719 14.7589C174.498 14.7589 176.759 12.498 176.759 9.71893C176.759 6.93939 174.498 4.67841 171.719 4.67841C168.94 4.67841 166.679 6.93939 166.679 9.71893C166.679 12.4974 168.94 14.7589 171.719 14.7589Z"
          fill="#5BBDD7"
        />
        <path
          d="M171.719 47.3217C174.498 47.3217 176.759 45.0607 176.759 42.2817C176.759 39.5032 174.498 37.2417 171.719 37.2417C168.94 37.2417 166.679 39.5032 166.679 42.2817C166.679 45.0613 168.94 47.3217 171.719 47.3217Z"
          fill="#ED6C77"
        />
        <path
          className={'el1'}
          d="M116 18L124 25L116 32L116 18Z"
          fill="#5ABDD7"
        />
        <path
          className={'el2'}
          d="M103 18L111 25L103 32L103 18Z"
          fill="#5ABDD7"
        />
        <path className={'el3'} d="M90 18L98 25L90 32L90 18Z" fill="#5ABDD7" />
        <circle cx="25" cy="25" r="25" fill="#3C3C3B" />
        <path
          d="M15 13V37H19.5V35.5H31.5V37H36V13H15ZM34.5 28H33V20.5H34.5V28ZM34.5 17.5H33V16H18V32.5H33V31H34.5V34H16.5V14.5H34.5V17.5ZM26.25 24.25C26.25 25.9 24.9 27.25 23.25 27.25C21.6 27.25 20.25 25.9 20.25 24.25C20.25 22.6 21.6 21.25 23.25 21.25C24.9 21.25 26.25 22.6 26.25 24.25Z"
          fill="white"
        />
        <path
          d="M24.75 24.25C24.75 24.6478 24.592 25.0294 24.3107 25.3107C24.0294 25.592 23.6478 25.75 23.25 25.75C22.8522 25.75 22.4706 25.592 22.1893 25.3107C21.908 25.0294 21.75 24.6478 21.75 24.25C21.75 23.8522 21.908 23.4706 22.1893 23.1893C22.4706 22.908 22.8522 22.75 23.25 22.75C23.6478 22.75 24.0294 22.908 24.3107 23.1893C24.592 23.4706 24.75 23.8522 24.75 24.25Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ConsentRedirectFromBank;
