import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid } from '@material-ui/core';
import crossIcon from 'assets/images/cross.png';
import styles from './styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(styles);

function ModalModal({
  className,
  open,
  handleClose,
  onClose,
  body,
  header,
  title,
  children,
}) {
  const classes = useStyles();

  return (
    <div className={clsx(className, 'modalWrapper')}>
      <Modal
        className="modalModal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={onClose || handleClose}
      >
        <div className={clsx(classes.modal, 'modalInnerWrapper')}>
          <div onClick={onClose || handleClose}>
            <img alt="close-icon" src={crossIcon} className={'closeBtn'} />
          </div>
          <Typography variant="h1" className="modalHeader">
            <span className={'innerText'}>{title || header}</span>
          </Typography>
          <div className="modalBody">
            <Grid item xs={12}>
              {children || body}
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModalModal;
