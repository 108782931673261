import React, { useState, useContext, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import ExpansionPanelView from '../../../parts/ExpansionPanelView';
import Button from 'components/Button';
import { Autorenew } from '@material-ui/icons';
import clsx from 'clsx';
import { Form as FinalForm } from 'react-final-form';
import InputSelectBank from './InputSelectBank';
import InputJointAccount from './InputJointAccount';
import AcceptDataSharing from './AcceptDataSharing';
import { apiCallWrapper, post } from '../../../../../utils/fetch';
import appConfig from '../../../../../../config';
import { mapperPsuPreSignUp } from '../apiReqBodyMappers';
import { ConsentContext } from '../../../index';
import { useHistory } from 'react-router-dom';

const SelectYourBankForm = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const {
    customerName,
    isProcessing,
    setIsProcessing,
    consentDetails,
    bankProviders,
    userData,
  } = useContext(ConsentContext);

  const { psu_email, token, id } = consentDetails || {};

  const banksListMapped =
    (bankProviders && [
      ...bankProviders.map(b => ({
        value: b.name,
        label: b['display_name'],
      })),
    ]) ||
    [];

  const ref = useRef();
  const history = useHistory();

  const handleOnSubmit = async values => {
    const currentBankDetails = bankProviders.find(
      bank => bank.name === values.banksList,
    );

    const isJoint = values.is_joint === 'true';

    if (values.create_account) {
      apiCallWrapper({
        onStart: () => {
          setIsProcessing(true);
        },
        apiCall: () =>
          post(
            appConfig.endpoints.preSignUp,
            mapperPsuPreSignUp(userData, psu_email),
          ),
        onAny: () => setIsProcessing(false),
        notify: false,
      });
    }

    apiCallWrapper({
      onStart: () => setIsProcessing(true),
      apiCall: () =>
        post(`${appConfig.endpoints.consentConfirm}`, {
          bank: values.banksList,
          consent_id: id,
          is_joint: isJoint,
          token: token,
        }),
      onFinish: resp => {
        window.open(resp.url, '_self');
      },
      onAny: () => setIsProcessing(false),
      notify: true,
    });

    history.push(`/consent/processing`, {
      customerName,
      consentId: id,
      bank: currentBankDetails && currentBankDetails.display_name,
      bankLogo: currentBankDetails && currentBankDetails.logo,
    });
  };

  const handleOnReject = async () => {
    const reqBody = {
      consent_id: id,
    };

    apiCallWrapper({
      apiCall: () => post(`${appConfig.endpoints.consentReject}`, reqBody),
      onFinish: resp => {
        if (resp.redirect_url) {
          window.open(resp.redirect_url, '_self');
          return;
        }
        ref.timeout = setTimeout(
          () => history.push(`/consent/rejected`, { customerName }),
          100,
        );
      },
    });
  };

  useEffect(() => {
    return () => {
      if (ref.timeout) {
        clearTimeout(ref.timeout);
      }
    };
  }, []);

  return (
    <FinalForm
      onSubmit={handleOnSubmit}
      initialValues={{
        banksList: 'Select Your Bank',
        create_account: false,
      }}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12}>
              <InputSelectBank banksList={banksListMapped} />
              <InputJointAccount />
              <AcceptDataSharing
                checked={isAccepted}
                onChange={() => setIsAccepted(isAccepted => !isAccepted)}
                customerName={customerName}
              />
              <ExpansionPanelView isExpanded={false} />
              {/* Hiding as per TL-132
              {!isAuthSuccess && <CreateAccountCheckboxBlock />} 
              */}
            </Grid>
            <Grid item xs={12}>
              <Grid
                justifyContent={'center'}
                alignItems={'center'}
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  <Button
                    wide
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting || isProcessing || !isAccepted}
                    style={{ backgroundColor: (submitting || isProcessing || !isAccepted) ? 'rgb(160 194 201)' : ""}}
                  >
                    {submitting || isProcessing ? (
                      <Autorenew
                        color="action"
                        className={clsx('isProcessingAnimationIcon')}
                      />
                    ) : (
                      'Confirm'
                    )}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    wide
                    customColor={'mutedWarning'}
                    variant="contained"
                    color="secondary"
                    onClick={handleOnReject}
                    disabled={isProcessing}
                  >
                    Reject
                  </Button>
                  <br/><br/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default SelectYourBankForm;
