import React from 'react';
import SelectYourBankForm from './SelectYourBankForm';

const Pending = () => {
  {/* Hiding as per TL-212 */}
  // const {
  //   handleOnGoBack,
  //   consentDetails: { created_at, lifetime, bank },
  //   customerName,
  // } = useContext(ConsentContext);
  return [
    // <TitleWithBackButton
    //   key={'TitleWithBackButton'}
    //   handleOnGoBack={handleOnGoBack}
    // >
    //   <Typography variant="h1">{'BankLoop Request'}</Typography>
    // </TitleWithBackButton>,
    // <Steps key={'SelectBankStep'} activeStep={1} />,
    // <Status
    //   key={'Status'}
    //   created_at={created_at}
    //   lifetime={lifetime}
    //   status={'pending'}
    //   customerName={customerName}
    //   bankName={bank}
    // />,
    <SelectYourBankForm key={'SelectYourBankForm'} />,
    // <BottomTextBlock
    //   key={'BottomTextBlock'}
    //   created_at={created_at}
    //   lifetime={lifetime}
    //   status={'pending'}
    // />,
    // <Footer key={'Footer'} />,
  ];
};

export default Pending;
