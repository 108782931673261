import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

const Link = styled(ReactRouterLink)`
  &,
  &:visited,
  &:focus {
    color: ${({ theme, color }) =>
      color ? theme.colors[color] : theme.colors.primary}
    text-decoration: none;
    cursor: pointer;
    transition: all 300ms;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }

  font-weight: bold;
`;

export default Link;
