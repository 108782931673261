import { actionTypes } from 'store/actions/profile';
import produce from 'immer';

const initialState = {
  userData: null,
  isProcessing: false,
};

const setProfileUserData = produce((draft, userData) => {
  draft.userData = userData;
});

const setProfileIsProcessing = produce((draft, isProcessing) => {
  draft.isProcessing = isProcessing;
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE_IS_PROCESSING_TRUE:
      return setProfileIsProcessing(state, true);
    case actionTypes.SET_PROFILE_IS_PROCESSING_FALSE:
      return setProfileIsProcessing(state, false);
    case actionTypes.SET_PROFILE_USER_DATA:
      return setProfileUserData(state, action.data);
    default:
      return state;
  }
};

export default reducer;
