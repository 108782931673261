import React from 'react';
import Box from '@material-ui/core/Box';
import { LayoutTwoItemSpaceBetweenDotted } from '../../../../../components';
import Typography from '@material-ui/core/Typography';

const Revoked = ({ customerName, bankName, account }) => {
  return (
    <Box py={2}>
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Requested by'}
        rightItem={customerName}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Reason of request'}
        rightItem={'BankLoop'}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Bank'}
        rightItem={<strong>{bankName.toUpperCase()}</strong> || '-'}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Bank account(s)'}
        rightItem={account || 'Current account'}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Status'}
        rightItem={
          <div>
            <Typography color={'primary'} style={{ fontWeight: '800' }}>
              REVOKED
            </Typography>
          </div>
        }
      />
    </Box>
  );
};

export default Revoked;
