import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import styles from './styles';
import Pending from './Pending';
import { ConsentContext } from '../index';
import LayoutsPage from '../../../../layouts/Page';
import Confirmed from './Confirmed';
import ExpiredConsent from './ExpiredConsent';
import ExpiredRequest from './ExpiredRequest';
import Rejected from './Rejected';
import Revoked from './Revoked';

const useStyles = makeStyles(styles);

const getRenderedComponent = status => {
  if (!status) return null;

  switch (status) {
    case 'pending':
      return Pending;
    case 'confirmed':
    case 'consent-data-received':
    case 'consent-data-shared':
    case 'consent-data-sharing-rejected':
      return Confirmed;
    case 'consent-expired':
      return ExpiredConsent;
    case 'request-expired':
      return ExpiredRequest;
    case 'rejected':
      return Rejected;
    case 'revoked':
      return Revoked;
    default:
      return null;
  }
};

const ConsentsByStatus = ({ propsConsentDetails }) => {
  const classes = useStyles();
  let { consentDetails, customerName } = useContext(ConsentContext);

  if (propsConsentDetails) {
    consentDetails = propsConsentDetails;
  }

  if (!consentDetails) return null;

  const RenderedComponent = getRenderedComponent(consentDetails.status);

  return (
    <LayoutsPage>
      <div className={classes.root}>
        <RenderedComponent
          {...consentDetails}
          consentId={consentDetails.id}
          customerName={customerName}
        />
      </div>
    </LayoutsPage>
  );
};

export default ConsentsByStatus;
