import { useEffect, useState } from 'react';
import { apiCallWrapper, get } from '../../utils/fetch';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useGetConsentDetails = (location) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { url, systemLogin } = location;

  useEffect(() => {
    const fetchConsentWithRecaptcha = async () => {
      if (url) {
        const token = await executeRecaptcha('consent');
        apiCallWrapper({
          apiCall: () => get(`${url}`),
          onFinish: resp => {
            if (!resp || !resp.status) {
              setError('No Status');
              return;
            }
  
            if (!resp || !resp.id) {
              setError('No consent ID');
              return;
            }
  
            setData(resp);
          },
          recaptchaToken: systemLogin ? token : null,
          onFail: e => setError(e),
        });
      }
    }
    fetchConsentWithRecaptcha();
  }, [url, executeRecaptcha, systemLogin]);

  return [data, error];
};

export default useGetConsentDetails;
