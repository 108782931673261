import React from 'react';
import styled from 'styled-components';

const LayoutPage = ({ children }) => {
  return <LayoutPageStyled>{children}</LayoutPageStyled>;
};

const LayoutPageStyled = styled.div``;

export default LayoutPage;
