import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& .MuiStepper-root': {
      padding: '20px 0',
    },
    '& .MuiStepLabel-label': {
      display: 'none',
    },
    '& .MuiStepLabel-active': {
      display: 'block',
      margin: '15px -60px 0',
    },
  }
}));

const Steps = ({ activeStep }) => {
  const classes = useStyles();
  // Hiding as per TL-212
  const steps = [
    // 'Welcome',
    // 'Your data',
    'Select a bank',
    'Confirm data share',
  ];

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default Steps;