import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Container from 'components/Container';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '2rem 0 2rem 0',
  },
});

/* eslint-disable react/display-name */
export default ({ children, className, isFullHeight}) => {
  const classes = useStyles();
  let additionalStyles = {};
  if (isFullHeight) {
    additionalStyles = {
      height: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
    };
  }

  return (
    <div className={`${classes.root} ${className}`} style={additionalStyles}>
      <Container
        maxWidth={'xs'}
        classesOverride={{}}
        isFullHeight={isFullHeight}
      >
        {children}
      </Container>
    </div>
  );
};
