import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import CollectUserData from './CollectUserData';
import LayoutsPage from 'layouts/Page';
import WelcomePage from './WelcomePage';
import queryString from 'query-string';
import appConfig from '../../../config';
import useGetConsentDetails from './useGetConsentDetails';
import useGetBankProviders from './useGetBankProviders';
import PreloaderBranding from '../../../components/PreloaderBranding';
import { ContentWHFullFlexXYCenter } from '../../../components';
import ErrorWrapper from '../../components/ErrorWrapper';
import ConsentsByStatus from './ConsentsByStatus';
import { getCustomerName } from './utils';

// Hiding as per TL-212
// const pages = [WelcomePage, CollectUserData, ConsentsByStatus];
const pages = [WelcomePage];
const maxPage = pages.length;

export const userDataInitValues = {
  first_name: '',
  last_name: '',
  account_holders_num: 1,
};

export const ConsentContextInitValues = {
  consentDetails: null,
  customerName: null,
  userData: userDataInitValues,
  setUserData: () => {},
  bankProviders: [],
  isProcessing: false,
  setIsProcessing: () => {},
  handleOnGoBack: () => {},
  handleOnGoForward: () => {},
};

export const ConsentContext = createContext(ConsentContextInitValues);

const fetchConsentDataUrl = (location, consentId) => {
  const { code, id } = queryString.parse(location.search);

  if (id || consentId) {
    return {
      url: `${appConfig.endpoints.consent}/${id || consentId}`,
      systemLogin: false
    };
  }
  if (code) {
    return {
      url: `${appConfig.endpoints.consentOtp}/${code}`,
      systemLogin: true
    };
  }

  return null;
};

const Consent = ({ consentId }) => {
  const location = useLocation();
  const [userData, setUserData] = useState(userDataInitValues);
  const [errors, setErrors] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [page, setPage] = useState(0);
  const [consentDetails, consentDetailsError] = useGetConsentDetails(
    fetchConsentDataUrl(location, consentId),
  );
  const [bankProviders, bankProvidersError] = useGetBankProviders();
  const handleOnGoForward = useCallback(
    () => setPage(page => (page < maxPage ? page + 1 : maxPage)),
    [],
  );
  const handleOnGoBack = useCallback(
    () => setPage(page => (page > 0 ? page - 1 : 0)),
    [],
  );
  const userDataContextValue = {
    consentDetails,
    customerName: getCustomerName(consentDetails),
    userData,
    setUserData,
    bankProviders,
    isProcessing,
    setIsProcessing,
    handleOnGoBack,
    handleOnGoForward,
  };

  useEffect(() => {
    const errList = [consentDetailsError, bankProvidersError].filter(e => !!e);

    if (errList.length > 0) {
      setErrors(errList.join(' & '));
    }
    if (consentDetails) {
      let newConsentDetails = {
        ...consentDetails.psu_account,
        psu_email: consentDetails.psu_email,
      };
      // eslint-disable-next-line no-prototype-builtins
      if (consentDetails.hasOwnProperty('psu_company')) {
        newConsentDetails = {
          ...newConsentDetails,
          ...consentDetails['psu_company'],
        };
      }
      setUserData(newConsentDetails);
    }
  }, [consentDetails, setUserData, consentDetailsError, bankProvidersError]);

  const CurPage = pages[page];

  if (errors) {
    return (
      <LayoutsPage isFullHeight>
        <ContentWHFullFlexXYCenter>
          <ErrorWrapper>
            {errors || 'Sorry, something went wrong. Please try again later.'}
          </ErrorWrapper>
        </ContentWHFullFlexXYCenter>
      </LayoutsPage>
    );
  }

  if (!fetchConsentDataUrl || !consentDetails || !bankProviders) {
    return (
      <LayoutsPage isFullHeight>
        <ContentWHFullFlexXYCenter>
          <PreloaderBranding />
        </ContentWHFullFlexXYCenter>
      </LayoutsPage>
    );
  }

  if (consentDetails.status === 'pending') {
    return (
      <ConsentContext.Provider value={userDataContextValue}>
        <CurPage />
      </ConsentContext.Provider>
    );
  }

  return (
    <ConsentContext.Provider value={userDataContextValue}>
      <ConsentsByStatus />
    </ConsentContext.Provider>
  );
};

export default Consent;
