import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileResetPassword } from 'store/actions/profile';
import queryString from 'query-string';
import LayoutsPageInitAuthForm from 'layouts/PageInitAuthForm';
import { rePasswordMatch } from '../../../../utils/validators';
import { selectIsProcessing } from '../../../../store/selectors/profile';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const formFieldsMarkup = [
  {
    type: 'password_re',
  },
];

const title = 'Reset Password';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isProcessing = useSelector(selectIsProcessing);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { code } = queryString.parse(location.search);

  const handleOnSubmit = useCallback(
    async values => {
      const data = {
        newPassword: values.password,
        code,
      };
      const token = await executeRecaptcha('reset_password')
      dispatch(setProfileResetPassword({data, token}));
    },
    [dispatch, code, executeRecaptcha],
  );

  const LayoutsPageInitAuthFormParams = {
    pageTitle: title,
    formParams: {
      loading: isProcessing,
      validate: rePasswordMatch,
      fieldsMarkup: formFieldsMarkup,
      handleOnSubmit,
    },
  };

  useEffect(() => {});

  return <LayoutsPageInitAuthForm {...LayoutsPageInitAuthFormParams} />;
};

export default ResetPassword;
