import React from 'react';
import LayoutsPage from '../../../../layouts/Page';
import ConfirmSvg from '../../../../svg/Confirm';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import ExpansionPanelsView from '../../Consent/parts/ExpansionPanelView';
import Steps from '../../Consent/parts/Steps';
import Box from '@material-ui/core/Box';
import Button from '../../../../components/Button';
import { useHistory } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { selectIsAuthSuccess } from '../../../../store/selectors/profile';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  svg: {
    textAlign: 'center'
  }
}));

const DataSharingConfirmed = ({ customerName }) => {
  const classes = useStyles();
  const history = useHistory();
  // const isAuthSuccess = useSelector(selectIsAuthSuccess);

  return (
    <LayoutsPage>
      <div className={classes.root}>
        <Grid container spacing={1} justify={'center'} alignItems={'center'}>
          <Grid item xs={12}>
            <Steps activeStep={5} />
          </Grid>
          <Grid item xs={12}>
            <ConfirmSvg className={classes.svg} />
          </Grid>
          <Grid item xs={12}>
            <Typography align={'center'} variant={'h4'}>Confirmed</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align={'center'}>
              You successfully shared your online banking data with{' '}
              {customerName}.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanelsView isShared superWide />
          </Grid>
          <Grid item xs={12}>
            <Box width="100%" pt={2}>
              <Button
                wide
                style={{ flexGrow: '1' }}
                variant={'contained'}
                color={'primary'}
                onClick={() => history.push('/')}
              >
                CONTINUE
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </LayoutsPage>
  );
};

export default DataSharingConfirmed;
