import React from 'react';
import Box from '@material-ui/core/Box';
import { LayoutTwoItemSpaceBetweenDotted } from '../../../../../components';
import Typography from '@material-ui/core/Typography';

const Rejected = ({ customerName }) => {
  return (
    <Box py={2}>
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Requested by'}
        rightItem={customerName}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Reason of request'}
        rightItem={'BankLoop'}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Status'}
        rightItem={
          <div>
            <Typography style={{ color: '#EB5757', fontWeight: '800' }}>
              REJECTED
            </Typography>
          </div>
        }
      />
    </Box>
  );
};

export default Rejected;
