import _ from 'lodash';


export const getCustomerName = consentDetails => {
  if (
    _.has(consentDetails, ['customer', 'first_name']) &&
    _.has(consentDetails, ['customer', 'last_name'])
  ) {
    if (consentDetails.customer && consentDetails.customer.company_name) {
      return consentDetails.customer.company_name;
    } else {
      return `${consentDetails.customer.first_name} ${consentDetails.customer.last_name}`;
    }
  }

  return null;
};
