import React from 'react';
import Box from '@material-ui/core/Box';
import { LayoutTwoItemSpaceBetweenDotted } from '../../../../../components';
import Typography from '@material-ui/core/Typography';
import Strong from '../../../../components/Strong';

const DataSharingRequest = ({ customerName, bankName, account }) => {
  return (
    <Box py={2}>
      <Box pb={2}>
        <Typography key={'Typography'} style={{ textAlign: 'justify' }}>
          Thank you for giving <Strong>TrustLoop</Strong> consent to connect to
          your online banking data.
        </Typography>
      </Box>
      <Box pb={2}>
        <Typography key={'Typography'} style={{ textAlign: 'justify' }}>
          Now <Strong>TrustLoop</Strong> needs your consent to share your online
          banking data with <Strong>{customerName}</Strong>
        </Typography>
      </Box>
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Requested by'}
        rightItem={customerName}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Reason of request'}
        rightItem={'Incoming Verification'}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Bank'}
        rightItem={<strong>{bankName.toUpperCase()}</strong> || '-'}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Bank account(s)'}
        rightItem={account || 'Current account'}
      />
    </Box>
  );
};

export default DataSharingRequest;
