import React from 'react';
import columns from './columns';
import ReactTable from 'react-table';
import { makeStyles } from '@material-ui/styles';
import styles from 'components/Table/styles';
import 'components/Table/initStyles.css';
import reducer, { actions, initState } from 'components/Table/reducer';
import { handlePagination, onPageChange } from 'components/Table/utils';
import useDataFetch from './useDataFetch';
import styled from 'styled-components';
import PreloaderBrandingStyled from '../../../../../../../components/PreloaderBranding';

export const defPageSize = 10;
const useStyles = makeStyles(styles);

const ReactTableStyled = styled(props => <ReactTable {...props}/>)`
  overflow: hidden;
  margin: 0 -0.25rem;
`;

const Transactions = ({ consentId }) => {
  const classes = useStyles();

  const [state, dispatch] = React.useReducer(reducer, {
    ...initState,
    sortBy: 'date',
  });
  const { total, isProcessing, offset, data, sortBy, sortOrder } = state;

  useDataFetch({ consentId, state, dispatch });

  return (
    <ReactTableStyled
      rowMinWidth={'120px'}
      manual={true}
      minRow={defPageSize}
      className={classes.root}
      showPageSizeOptions={false}
      defaultSorted={[
        {
          id: 'date',
          desc: true,
        },
      ]}
      loadingText={<PreloaderBrandingStyled />}
      defaultPageSize={defPageSize}
      data={data}
      loading={isProcessing}
      columns={columns}
      nextText={
        <span>
          <i className="fa fa-chevron-right" aria-hidden="true" />
        </span>
      }
      previousText={
        <span>
          <i className="fa fa-chevron-left" aria-hidden="true" />
        </span>
      }
      onPageChange={onPageChange({
        defPageSize,
        setOffset: offset => dispatch(actions.setOffset(offset)),
      })}
      getPaginationProps={handlePagination({
        total,
        defPageSize,
        sortOrder,
        sortBy,
        setSort: sort => dispatch(actions.setSort(sort)),
        offset,
      })}
    />
  );
};

export default Transactions;
