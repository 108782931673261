import React from 'react';
import { ContentHeightFull } from 'components';
import Container from 'components/Container';
import ButtonBack from 'components/ButtonBack';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const TitleWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  grid-template-columns: max-content 1fr;

  h1 {
    font-size: 22px;
  }
`;

const ContentWrapper = styled.div``;

const PublicPagesWrapper = ({ title, children }) => {
  const history = useHistory();

  return (
    <Container>
      <ContentHeightFull>
        <TitleWrapper>
          <ButtonBack
            history={history}
            variant={'round'}
          />
          <h1>{title}</h1>
        </TitleWrapper>
        <ContentWrapper>{children}</ContentWrapper>
      </ContentHeightFull>
    </Container>
  );
};

export default PublicPagesWrapper;
