import React, { useEffect } from 'react';
import LayoutsPage from 'layouts/Page';
import SvgConsentRedirectToBankWithCustomLogo from 'svg/ConsentRedirectToBankWithCustomLogo';
import SvgConsentRedirectToBank from 'svg/ConsentRedirectToBank';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Container from 'components/Container';
import { useLocation } from 'react-router-dom';
import { SvgRedirectBankLogoWrapper } from '../../../../components';

const ConsentRedirectToBank = () => {
  const { state } = useLocation();

  const bankLogo = (() => {
    if (state && state.bankLogo !== 'https://google.com') {
      return state.bankLogo;
    }

    return null;
  })();

  useEffect(() => {
    localStorage.removeItem('OBG_bankLogo');
    localStorage.removeItem('consentDetails');
    if (state) {
      localStorage.setItem('consentDetails', JSON.stringify(state));
    }
    if (bankLogo) {
      localStorage.setItem('OBG_bankLogo', bankLogo);
    }
  }, [bankLogo, state]);

  return (
    <LayoutsPage>
      <Container>
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
          direction={'column'}
          wrap={'nowrap'}
        >
          <Grid item>
            {(bankLogo && (
              <SvgRedirectBankLogoWrapper>
                <SvgConsentRedirectToBankWithCustomLogo />
                <img alt="Bank logo" src={bankLogo} />
              </SvgRedirectBankLogoWrapper>
            )) || <SvgConsentRedirectToBank />}
          </Grid>
          <Grid item>
            <Typography
              style={{
                color: '#ccc',
              }}
            >
              Transferring you to
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'h5'}>
              {(state && state.bank) || 'Your bank'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography align={'justify'} variant={'body1'}>
              You are now leaving TrustLoop and we are securely transferring you
              over {(state && state.bank) || 'your bank'} to authenticate.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </LayoutsPage>
  );
};

export default ConsentRedirectToBank;
