import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import config from 'config';

const PrivateRoute = ({ component: Component, isAuthSuccess, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthSuccess === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: config.defaultRedirectPublicRoute,
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
