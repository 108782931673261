import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Link from '../../../components/Link';

const Footer = () => {
  return (
    <Box py={2}>
      <Typography style={{ fontSize: '.8rem' }}>
        By confirming the consent request you are agreeing with{' '}
        <Link to={'/terms-of-use'} className="-primary">
          Terms of use
        </Link>{' '}
        and{' '}
        <Link to={'/privacy-policy'} className="-primary">
          Privacy Policy
        </Link>
      </Typography>
      <Typography style={{ fontSize: '.8rem' }}>
        TrustLoop is a trading style of Open B Gateway Limited, regulated by the
        UK Financial Conduct Authority under the Payment Services Regulations
        2017 as an Authorised Payment Institution to provide account information
        services (Firm Reference Number: xxxxxx)
      </Typography>
    </Box>
  );
};

export default Footer;
