import React from 'react';

/* eslint-disable react/display-name */
export default ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="40" cy="40" r="40" fill="#5ABDD7" />
        <path
          d="M24.5283 40.2895L36.3553 52.1165L59.7358 28.736"
          stroke="white"
          strokeWidth="3"
        />
      </svg>
    </div>
  );
};
