import React from 'react';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  icon: {
      fill: '#EB5757',
  },
})

const InputSelectBank = ({ banksList = [] }) => {
  const classes = useStyles();
  return(
  <Field
    required
    name={`banksList`}
    fullWidth={true}
    validate={value => {
      if (!value || value === 'Select Your Bank') {
        return 'Required';
      }
    }}
  >
    {({ input, meta }) => (
      <FormControl
        className="form-field"
        fullWidth
        component="div"
        error={!!(meta.touched && meta.error)}
      >
        <Select defaultValue={'Select Your Bank'} {...input} id={'your_bank'} color={'secondary'} inputProps={{
                classes: {
                    icon: classes.icon
                },
            }}>
          <MenuItem
            disabled
            key={'Select Your Bank'}
            value={'Select Your Bank'}      
            >
            <Typography variant="h2" color={'secondary'}>
              <strong>
              Select Your Bank
              </strong>
            </Typography>
          </MenuItem>
       
          {banksList.map(v => (
            <MenuItem key={v.label} value={v.value}>
              {v.label}
            </MenuItem>
          ))}
        </Select>
        {meta.touched && meta.error && (
          <FormHelperText id={`your_bank-error`}>{meta.error}</FormHelperText>
        )}
      </FormControl>
    )}
  </Field>)
};

export default InputSelectBank;
