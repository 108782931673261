import React from 'react';
import styled from 'styled-components';
import Emblem from '../../svg/Emblem';

const PreloaderBranding = ({ className }) => {
  return (
    <div className={`${className} component_ui_Preloader`}>
      <Emblem />
    </div>
  );
};

const PreloaderBrandingStyled = styled(PreloaderBranding)`
  @keyframes __spin {
    to {
      transform: rotate(360deg);
    }
  }
  position: relative;

  &::after {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    transition: all 300ms;
    z-index: 100;
  }

  .assets_svg svg {
    width: 3.5rem;
    height: 3.5rem;
    animation: __spin 2s linear infinite;
  }
`;

export default PreloaderBrandingStyled;
