import React from 'react';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import Strong from '../../../../components/Strong';

const Pending = () => {
  return (
    <Box py={2}>
      <Box pb={2}>
        <Typography key={'Typography'} style={{ textAlign: 'justify' }}>
          <Strong>TrustLoop</Strong> needs your consent to create a{' '}
          <Strong color={'secondary'}>secure, read only, time limited</Strong>{' '}
          connection to your online banking data.
        </Typography>
      </Box>
      {/* TL-212 Hiding Requested by,Reason of request,Status */}
      {/* <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Requested by'}
        rightItem={customerName}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Reason of request'}
        rightItem={'Incoming Verification'}
      />
      <LayoutTwoItemSpaceBetweenDotted leftItem={'Status'} rightItem={'NEW'} /> */}
    </Box>
  );
};

export default Pending;
