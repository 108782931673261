import React, { useEffect, useState } from 'react';
import appConfig from 'config';
import { useSelector } from 'react-redux';
import { selectUserEmail } from 'store/selectors/profile';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AutoRenewIcon from 'components/AutoRenewIcon';
import LayoutsPage from 'layouts/Page';
import moment from 'moment';
import clsx from 'clsx';
import { post, apiCallWrapper } from '../../../../@src/utils/fetch';

// eslint-disable-next-line
const useStyles = makeStyles(theme => {
  return {
    btnFetchMore: {
      marginTop: '1rem',
    },
    renderRows_root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      paddingTop: '1.5rem',
      '&:first-child': {
        paddingTop: 0,
      },

      '& .action_date': {
        color: '#B8B4B2',
      },
      '& .action_msg': {
        paddingTop: '.5rem',
        overflow: 'hidden',
        maxWidth: 'calc(100vw - 2rem)',
        '& .typography_root': {
          overflowWrap: 'break-word',
        },
        '& .customerCompany, .customerName, .productName': {
          fontWeight: 800,
        },
        '& .customerCompany, .customerName': {
          wordBreak: 'break-all',
        },
        '& .eventType': {
          '&.-expired': {
            color: '#B8B4B2',
          },
          '&.-data_share_rejected': {
            color: '#EB5757',
          },
          '&.-rejected': {
            color: '#EB5757',
          },
          '&.-waiting_for_data': {
            color: '#FAB733',
          },
          '&.-pending': {
            color: '#FAB733',
          },
          ' &.-created': {
            color: '#FAB733',
          },
          '&.-expiry_notification': {
            color: '#FAB733',
          },
          '&.-confirmed': {
            color: '#5ABDD7',
          },
          '&.-renewed': {
            color: '#5ABDD7',
          },
          '&.-data_shared': {
            color: '#5ABDD7',
          },
          '&.-revoked': {
            color: '#5ABDD7',
          },
        },
      },
    },
  };
});

const BtnFetchMore = ({ handleOnClickMore, isProcessing }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.btnFetchMore}
      onClick={handleOnClickMore}
      variant={'contained'}
      color={'primary'}
    >
      {!isProcessing ? 'More' : <AutoRenewIcon />}
    </Button>
  );
};

const RenderRows = ({ data, classes }) =>
  data &&
  data.map(v => {
    const { created_at, event_type } = v;

    const date = moment(created_at).format('MMM DD, YYYY, HH:mm');
    const wrapWithUniqKeys = elArr =>
      elArr.map((v, i) => <span key={i}> {v} </span>);

    const customerCompany = v.customer.company_name;
    const customerName =
      v.customer.first_name &&
      v.customer.last_name &&
      wrapWithUniqKeys([
        <>{v.customer.first_name}</>,
        <>{v.customer.last_name}</>,
      ]);
    const defProdName = 'BankLoop';

    const eventTypeRender = (event_type, text) => (
      <span className={clsx(`eventType`, `-${event_type}`)}>{text}</span>
    );

    const customerNameRender = (
      <>
        <span className="customerName">{customerName}</span>`&apos;`s
      </>
    );
    const customerCompanyRender = wrapWithUniqKeys([
      <>from</>,
      <>
        <span className="customerCompany">{customerCompany}</span>
      </>,
    ]);
    const productNameRender = (
      <span className="productName">{defProdName}</span>
    );

    const msgRejected = [
      <>You</>,
      eventTypeRender('rejected', 'rejected'),
      customerNameRender,
      customerCompany && customerCompanyRender,
      <>consent request for</>,
      productNameRender,
    ];

    const msgDataSharedRejected = [
      <>You</>,
      eventTypeRender('data_shared_rejected', 'rejected data sharing'),
      customerNameRender,
      customerCompany && customerCompanyRender,
      <>consent request for</>,
      productNameRender,
    ];

    const msgCreated = [
      customerNameRender,
      customerCompany && customerCompanyRender,
      eventTypeRender('created', 'requested'),
      <>your consent for</>,
      productNameRender,
    ];

    const msgRevoked = [
      <>The request for</>,
      customerNameRender,
      customerCompany && customerCompanyRender,
      <>is</>,
      eventTypeRender('revoked', 'revoked'),
      <>for</>,
      productNameRender,
    ];

    const msgRenewed = [
      <>The request for</>,
      customerNameRender,
      customerCompany && customerCompanyRender,
      <>is</>,
      eventTypeRender('renewed', 'renewed'),
      <>for</>,
      productNameRender,
    ];

    const msgExpired = [
      <>The consent for</>,
      customerNameRender,
      customerCompany && customerCompanyRender,
      <>is</>,
      eventTypeRender('expired', 'expired'),
      <>for</>,
      productNameRender,
    ];

    const msgDataShared = [
      <>You</>,
      eventTypeRender('data_shared', 'shared data'),
      customerNameRender,
      customerCompany && customerCompanyRender,
      <>consent request for</>,
      productNameRender,
    ];

    const msgConfirmed = [
      <>You</>,
      eventTypeRender('confirmed', 'received data'),
      customerNameRender,
      customerCompany && customerCompanyRender,
      <>consent request for</>,
      productNameRender,
    ];

    const msgExpiryNotification = [
      <>Your consent for</>,
      customerNameRender,
      customerCompany && customerCompanyRender,
      <>will</>,
      eventTypeRender('expiry_notification', 'expired soon'),
      <>for</>,
      productNameRender,
    ];

    const msgWaitingForData = [
      customerNameRender,
      customerCompany && customerCompanyRender,
      eventTypeRender('waiting_for_data', 'waiting for data'),
      <>on your consent for</>,
      productNameRender,
    ];

    const getMsgForEventType = event_type => {
      switch (event_type) {
        case 'rejected':
          return msgRejected;
        case 'created':
          return msgCreated;
        case 'renewed':
          return msgRenewed;
        case 'expiry_notification':
          return msgExpiryNotification;
        case 'revoked':
          return msgRevoked;
        case 'confirmed':
          return msgConfirmed;
        case 'expired':
          return msgExpired;
        case 'data_shared':
          return msgDataShared;
        case 'data_share_rejected':
          return msgDataSharedRejected;
        case 'waiting_for_data':
          return msgWaitingForData;
        default:
          return [];
      }
    };

    return (
      <Grid key={created_at} className={classes} container>
        <Grid item className={'action_date'}>
          <Typography>{date}</Typography>
        </Grid>
        <Grid item className={'action_msg'}>
          <Typography className={'typography_root'}>
            {wrapWithUniqKeys(getMsgForEventType(event_type))}
          </Typography>
        </Grid>
      </Grid>
    );
  });

const useDataFetch = id => {
  const [data, setData] = useState({ data: [], hasMore: true });
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState('');
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    setIsProcessing(true);

    const getReqBody = () => {
      return {
        psu_email: id,
        offset: offset,
        limit: 5,
        event_type: '',
      };
    };

    const reqBody = getReqBody();

    apiCallWrapper({
      onStart: () => {},
      apiCall: () => post(appConfig.endpoints.actionLog, reqBody),
      onFinish: ({ actions, has_more }) => {
        setData(data => {
          setIsProcessing(false);

          const prev = offset === 0 ? [] : data.data;

          return { data: [...prev, ...actions], has_more };
        });
      },
    });
  }, [offset, id, status, setIsProcessing]);

  return [
    data.data,
    data.has_more,
    offset,
    setOffset,
    status,
    setStatus,
    isProcessing,
  ];
};

const ActionLog = () => {
  const classes = useStyles();

  const id = useSelector(selectUserEmail);
  const [data, hasMore, , setOffset, , , isProcessing] = useDataFetch(id);

  const handleOnClickMore = React.useCallback(() => {
    setOffset(offset => offset + 5);
  }, [setOffset]);

  return (
    <LayoutsPage>
      <Typography variant={'h4'}>Action Log</Typography>
      <Grid container direction={'column'}>
        <Grid item>
          <RenderRows classes={classes.renderRows_root} data={data} />
        </Grid>
        <Grid item>
          {hasMore && (
            <BtnFetchMore
              handleOnClickMore={handleOnClickMore}
              isProcessing={isProcessing}
            />
          )}
        </Grid>
      </Grid>
    </LayoutsPage>
  );
};

export default ActionLog;
