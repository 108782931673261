import { toast } from 'react-toastify';
import { actionTypes, setProfileUserData } from 'store/actions/profile';
import {
  getUserDataFromStorage,
  writeUserDataToStorage,
} from 'utils/handleStorage';
import appConfig from 'config';
import { handleMessages } from 'utils/messages';
import { apiCallWrapper, post } from '../../@src/utils/fetch';
import history from '../../utils/history';

const autoSignIn = (location, dispatch) => {
  const userData = getUserDataFromStorage();

  if (userData) {
    dispatch(setProfileUserData(userData));
  }
};

const validationCodeFromSms = ({ data, token }, dispatch) => {
  apiCallWrapper({
    onStart: () =>
      dispatch({ type: actionTypes.SET_PROFILE_IS_PROCESSING_TRUE }),
    onAny: () => {
      dispatch({ type: actionTypes.SET_PROFILE_IS_PROCESSING_FALSE });
    },
    apiCall: () => post(appConfig.endpoints.validationSms, data),
    onFinish: userData => {
      writeUserDataToStorage(userData);
      toast.success(handleMessages('validationSuccess'));
      dispatch(setProfileUserData(userData));
    },
    recaptchaToken: token
  });
};

const signUp = (data, dispatch) => {
  apiCallWrapper({
    onStart: () =>
      dispatch({ type: actionTypes.SET_PROFILE_IS_PROCESSING_TRUE }),
    onAny: () => {
      dispatch({ type: actionTypes.SET_PROFILE_IS_PROCESSING_FALSE });
    },
    apiCall: () => post(appConfig.endpoints.signUp, data),
    onFinish: userData => {
      writeUserDataToStorage(userData);
      toast.success(handleMessages('signUpSuccess'));
      dispatch(setProfileUserData(userData));
      history.push('/validation');
    },
  });
};

const forgotPassword = ({ values, token }, dispatch) => {
  apiCallWrapper({
    onStart: () =>
      dispatch({ type: actionTypes.SET_PROFILE_IS_PROCESSING_TRUE }),
    onAny: () => {
      dispatch({ type: actionTypes.SET_PROFILE_IS_PROCESSING_FALSE });
    },
    apiCall: () => post(appConfig.endpoints.forgotPassword, values),
    onFinish: () => {
      toast.success(handleMessages('forgotPasswordSuccess'));
      history.push('/sign_in');
    },
    recaptchaToken: token
  });
};

const resetPassword = ({ data, token }, dispatch) => {
  apiCallWrapper({
    onStart: () =>
      dispatch({ type: actionTypes.SET_PROFILE_IS_PROCESSING_TRUE }),
    onAny: () => {
      dispatch({ type: actionTypes.SET_PROFILE_IS_PROCESSING_FALSE });
    },
    apiCall: () => post(appConfig.endpoints.resetPassword, data),
    onFinish: () => {
      toast.success(handleMessages('resetPasswordSuccess'));
      setTimeout(() => {
        history.push('/sign_in');
      }, 500);
    },
    recaptchaToken: token
  });
};

const signIn = (data, dispatch) => {
  apiCallWrapper({
    onStart: () =>
      dispatch({ type: actionTypes.SET_PROFILE_IS_PROCESSING_TRUE }),
    onAny: () => {
      dispatch({ type: actionTypes.SET_PROFILE_IS_PROCESSING_FALSE });
    },
    apiCall: () => post(appConfig.endpoints.signIn, data),
    onFinish: userData => {
      writeUserDataToStorage(userData);
      dispatch(setProfileUserData(userData));
    },
  });
};

const logOut = dispatch => {
  localStorage.clear();
  sessionStorage.clear();
  dispatch(setProfileUserData(null));
  fetch(appConfig.endpoints.signOut, { method: 'GET' }).then(r => r);
};

const profile = ({ getState, dispatch }) => next => async action => {
  const result = next(action);
  switch (action.type) {
    case actionTypes.SET_PROFILE_VALIDATION_CODE_FROM_SMS:
      validationCodeFromSms(action.data, dispatch);
      break;
    case actionTypes.SET_PROFILE_SIGN_IN:
      signIn(action.data, dispatch);
      break;
    case actionTypes.SET_PROFILE_SIGN_UP:
      signUp(action.data, dispatch);
      break;
    case actionTypes.SET_PROFILE_FORGOT_PASSWORD:
      forgotPassword(action.data, dispatch);
      break;
    case actionTypes.SET_PROFILE_RESET_PASSWORD:
      resetPassword(action.data, dispatch);
      break;
    case actionTypes.SET_PROFILE_LOG_OUT:
      logOut(dispatch, getState);
      break;
    case actionTypes.SET_PROFILE_AUTO_SIGN_IN:
      autoSignIn(action.data, dispatch);
      break;
    default:
      break;
  }
  return Promise.resolve(result);
};

export default profile;
