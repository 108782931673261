import React from 'react';
import { Container as MuiContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '5rem',
    paddingRight: '5rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
}));

const Container = ({
  fixed = false,
  maxWidth = 'xs',
  children,
  classesOverride,
  className,
  style,
  isHeightFull,
}) => {
  const classes = useStyles();

  let additionalStyles = {};

  if (isHeightFull) {
    additionalStyles = {
      height: '100%',
    };
  }

  return (
    <MuiContainer
      maxWidth={maxWidth}
      fixed={fixed}
      style={{ ...style, ...additionalStyles }}
      className={clsx(classesOverride, className, classes.root)}
    >
      {children}
    </MuiContainer>
  );
};

export default Container;
