import React from 'react';
import styled from 'styled-components';
import PublicPagesWrapper from '../Wrapper';
import H2 from '@src/components/H2';
import H3 from '@src/components/H3';
import LinkHref from '@src/components/LinkHref';

const List = styled.ul`
  padding-left: 1.5em;
  li {
    list-style-type: none;
  }
`;

const PrivacyPolicy = () => {
  return (
    <PublicPagesWrapper title={'Privacy Policy'}>
      <H2>Contents</H2>
      <List>
        <li>
          <LinkHref href="#introduction">Introduction</LinkHref>
        </li>
        <li>
          <LinkHref href="#interpretationAndDefinitions">
            Interpretation and Definitions
          </LinkHref>
          <List>
            <li>
              <LinkHref href="#interpretation">Interpretation</LinkHref>
            </li>
            <li>
              <LinkHref href="#definitions">Definitions</LinkHref>
            </li>
          </List>
        </li>
        <li>
          <LinkHref href="#collectingAndUsing">
            Collecting and Using Your Personal Data
          </LinkHref>
          <List>
            <li>
              <LinkHref href="#typesOfData">Types of Data Collected</LinkHref>
              <List>
                <li>
                  <LinkHref href="#personalData">Personal Data</LinkHref>
                </li>
                <li>
                  <LinkHref href="#usageData">Usage Data</LinkHref>
                </li>
              </List>
            </li>
            <li>
              <LinkHref href="#trackingTechnologies">
                Tracking Technologies and Cookies
              </LinkHref>
              <List>
                <li>
                  <LinkHref href="#necessaryCookies">
                    Necessary / Essential Cookies
                  </LinkHref>
                </li>
                <li>
                  <LinkHref href="#cookiesPolicy">
                    Cookies Policy / Notice Acceptance Cookies
                  </LinkHref>
                </li>
                <li>
                  <LinkHref href="#functionalityCookies">
                    Functionality Cookies
                  </LinkHref>
                </li>
              </List>
            </li>
          </List>
        </li>
        <li>
          <LinkHref href="#useOfPersonalData">
            Use of Your Personal Data
          </LinkHref>
          <List>
            <li>
              <LinkHref href="#retention">Retention of Your Personal Data</LinkHref>
              <List>
                <li>
                  <LinkHref href="#transfer">
                    Transfer of Your Personal Data
                  </LinkHref>
                </li>
              </List>
            </li>
            <li>
              <LinkHref href="#disclosure">
                Disclosure of Your Personal Data
              </LinkHref>
              <List>
                <li>
                  <LinkHref href="#businessTransactions">
                    Business Transactions
                  </LinkHref>
                </li>
                <li>
                  <LinkHref href="#lawEnforcement">Law enforcement</LinkHref>
                </li>
                <li>
                  <LinkHref href="#otherLegalRequirements">
                    Other legal requirements
                  </LinkHref>
                </li>
              </List>
            </li>
          </List>
        </li>
        <li>
          <LinkHref href="#securityOfData">
            Security of Your Personal Data
          </LinkHref>
        </li>
        <li>
          <LinkHref href="#emailMarketing">Email Marketing</LinkHref>
          <List>
            <li>
              <LinkHref href="#mailchimp">Mailchimp</LinkHref>
            </li>
          </List>
        </li>
        <li>
          <LinkHref href="#usagePerformance">
            Usage, Performance and Miscellaneous
          </LinkHref>
        </li>
        <li>
          <LinkHref href="#invisibleReCAPTCHA">Invisible reCAPTCHA</LinkHref>
        </li>
        <li>
          <LinkHref href="#GDPRPrivacy">GDPR Privacy</LinkHref>
          <List>
            <li>
              <LinkHref href="#legalBasis">
                Legal Basis for Processing Personal Data under GDPR
              </LinkHref>
            </li>
            <li>
              <LinkHref href="#yourRights">Your Rights under the GDPR</LinkHref>
            </li>
            <li>
              <LinkHref href="#exercisingGDPR">
                Exercising of Your GDPR Data Protection Rights
              </LinkHref>
            </li>
          </List>
        </li>
        <li>
          <LinkHref href="#childrenPrivacy">Children`&apos;`s Privacy</LinkHref>
        </li>
        <li>
          <LinkHref href="#linksToOther">Links to Other Web Sites</LinkHref>
        </li>
        <li>
          <LinkHref href="#changesToPolicy">
            Changes to this Privacy Policy
          </LinkHref>
        </li>
        <li>
          <LinkHref href="#contactUs">Contact Us</LinkHref>
        </li>
      </List>

      <H2 id="introduction">Introduction</H2>
      <p>
        This Privacy Policy describes our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service, and tells You about Your privacy rights and how the law
        protects You.
      </p>
      <p>
        We use Your Personal Data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>
      <H2 id='interpretationAndDefinitions'>Interpretation and Definitions</H2>
      <H3 id='interpretation'>Interpretation</H3>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions.
      </p>
      <p>
        The following definitions shall have the same meaning regardless of
        whether they appear in singular or in plural.
      </p>
      <H3 id='definitions'>Definitions</H3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <em>You</em> means the individual accessing or using the Service, or
          the company, or other legal entity on behalf of which such individual
          is accessing or using the Service, as applicable. Under GDPR (General
          Data Protection Regulation), You can be referred to as the Data
          Subject or as the user, as You are the individual using the Service.
        </li>
        <li>
          <em>Company</em> (referred to as either `&quot;`the Company`&quot;`, `&quot;`We`&quot;`, `&quot;`Us`&quot;` or
          `&quot;`Our`&quot;` in this policy) refers to OPEN B GATEWAY LIMITED trading as
          TrustLoop, company number 11910674, FCA registration number 913211,
          registered office address: Langdon House C/O Bevan Buckland Llp,
          Langdon Road, Swansea, Wales, SA1 8QY.
        </li>
        <li>
          <em>Affiliate</em> means an entity that controls, is controlled by or
          is under common control with a party, where `&quot;`control`&quot;` means ownership
          of 50% or more of the shares, equity interest or other securities
          entitled to vote for election of directors or other managing
          authority.
        </li>
        <li>
          <em>Accounts</em> means a unique account created for You to access the
          Service or parts of the Service.
        </li>
        <li>
          <em>Web Site</em> refers to trustloop.io, accessible from{' '}
          <LinkHref href="www.trustloop.io">www.trustloop.io</LinkHref>.
          Services refers to the Web Site and web application hosted and
          accessed through the Web Site.
        </li>
        <li>
          <em>Country</em> refers to: United Kingdom.
        </li>
        <li>
          <em>Service Providers</em> means any natural or legal person who
          processes the data on behalf of the Company. It refers to third-party
          companies or individuals employed by the Company to facilitate the
          Service, to provide the Service on behalf of the Company, to perform
          services related to the Service or to assist the Company in analysing
          how the Service is used. For the purpose of the GDPR, Service
          Providers are considered Data Processors.
        </li>
        <li>
          <em>Personal Data</em> is any information that relates to an
          identified or identifiable individual. For the purposes for GDPR,
          Personal Data means any information relating to You such as a name, an
          identification number, location data, online identifier or to one or
          more factors specific to the physical, physiological, genetic, mental,
          economic, cultural or social identity.
        </li>
        <li>
          <em>Cookies</em> are small files that are placed on Your computer,
          mobile device or any other device by a Web Site. Usage Data refers to
          data collected automatically, either generated using the Service or
          from the Service infrastructure itself (for example, the duration of a
          web page visit).
        </li>
        <li>
          <em>Data Controller</em>, for the purposes of the GDPR (General Data
          Protection Regulation), refers to the Company as the legal person
          which alone or jointly with others determines the purposes and means
          of the processing of Personal Data.
        </li>
      </ul>
      <H2 id='collectingAndUsing'>Collecting and Using Your Personal Data</H2>
      <H3 id='typesOfData'>Types of Data Collected</H3>
      <H3 id='personalData'>Personal Data</H3>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally, identifiable information may include, but is
        not limited to:
      </p>
      <ul>
        <li>Email Address</li>
        <li>First Name and Last Name</li>
        <li>Phone Number</li>
        <li>Physical Address</li>
        <li>Date of Birth</li>
      </ul>
      <H3 id='usageData'>Usage Data</H3>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>
        Usage Data may include information such as Your device`&apos;`s internet
        protocol address (IP address), browser type, browser version, the pages
        of Our Service that You visit, the time and date of Your visit, the time
        spent on those pages, unique device identifiers and other diagnostic
        data.
      </p>
      <p>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile internet browser You use, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </p>
      <H3 id='trackingTechnologies'>Tracking Technologies and Cookies</H3>
      <p>
        We use Cookies and similar tracking technologies to track Your activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyse Our Service.
      </p>
      <p>
        You can instruct Your browser to refuse all Cookies or to indicate when
        a Cookie is being sent. However, if You do not accept Cookies, You may
        not be able to use some parts of our Service.
      </p>
      <p>
        Cookies can be `&quot;`Persistent`&quot;` or `&quot;`Session`&quot;` Cookies. Persistent Cookies
        remain on your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close your web browser.
      </p>
      <p>
        We use both session and persistent Cookies for the purposes set out
        below:
      </p>
      <H3 id='necessaryCookies'>Necessary / Essential Cookies</H3>
      <p>Type: Session Cookies</p>
      <p>Administered by: Us</p>
      <p>
        Purpose: These Cookies are essential to provide You with services
        available through the Web Site and to enable You to use some of its
        features. They help to authenticate users and prevent fraudulent use of
        user accounts. Without these Cookies, the services that You have asked
        for cannot be provided, and We only use these Cookies to provide You
        with those services.
      </p>
      <H3 id='cookiesPolicy'>Cookies Policy / Notice Acceptance Cookies</H3>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Us</p>
      <p>
        Purpose: These Cookies identify if users have accepted the use of
        cookies on the Web Site.
      </p>
      <H3 id='functionalityCookies'>Functionality Cookies</H3>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Us</p>
      <p>
        Purpose: These Cookies allow us to remember choices You make when You
        use the Web Site, such as remembering your login details or language
        preference. The purpose of these Cookies is to provide You with a more
        personal experience and to avoid You having to re-enter your preferences
        every time You use the Web Site.
      </p>
      <H2 id='useOfPersonalData'>Use of Your Personal Data</H2>
      <p>The Company may use Personal Data for the following purposes:</p>
      <ul>
        <li>
          To provide and maintain our Services, including to monitor the usage
          of our Service.
        </li>
        <li>
          To manage Your Account to manage Your registration as a user of the
          Service. The Personal Data You provide can give You access to
          different functionalities of the Service that are available to You as
          a registered user.
        </li>
        <li>
          For the performance of a contract, the development, compliance and
          undertaking of the purchase contract for the products, items or
          services You have purchased or of any other contract with Us through
          the Service.
        </li>
        <li>
          To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application`&apos;`s push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation. To provide You with news, special offers and
          general information about other goods, services and events which we
          offer that are like those that you have already purchased or enquired
          about unless You have opted not to receive such information.
        </li>
        <li>To attend to and manage Your requests to Us.</li>
      </ul>
      <p>We may share your personal information in the following situations:</p>
      <ul>
        <li>
          With Third Party Service Providers. We may use the services of third
          parties (“Third Party Service Providers”) who help us to deliver the
          Service to you (for example third party technology companies who may
          provide elements of the Service’s functionality). Third Party Service
          Providers will only use your information to help provide the Service -
          they will not market to you.
        </li>
        <li>
          For Business transfers. We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </li>
        <li>
          With Affiliates. We may share Your information with Our affiliates, in
          which case we will require those affiliates to honour this Privacy
          Policy. Affiliates include Our parent company (if any) and any other
          subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </li>
      </ul>
      <H3 id='retention'>Retention of Your Personal Data</H3>
      <p>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </p>
      <p>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </p>
      <H3 id='transfer'>Transfer of Your Personal Data</H3>
      <p>
        Your information, including Personal Data, is processed at the Company`&apos;`s
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </p>
      <p>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </p>
      <p>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </p>
      <H3 id='disclosure'>Disclosure of Your Personal Data</H3>
      <H3 id='businessTransactions'>Business Transactions</H3>
      <p>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </p>
      <H3 id='lawEnforcement'>Law enforcement</H3>
      <p>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </p>
      <H3 id='otherLegalRequirements'>Other legal requirements</H3>
      <p>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </p>
      <ul>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          Protect the personal safety of Users of the Service or the public
        </li>
        <li>Protect against legal liability</li>
      </ul>
      <H2 id='securityOfData'>Security of Your Personal Data</H2>
      <p>
        The security of Your Personal Data is important to Us but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </p>
      <p>
        Service Providers have access to Your Personal Data only to perform
        their tasks on Our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>
      <H2 id='emailMarketing'>Email Marketing</H2>
      <p>
        We may use Your Personal Data to contact You with newsletters, marketing
        or promotional materials and other information that may be of interest
        to You. You may opt-out of receiving any, or all, of these
        communications from Us by following the unsubscribe link or instructions
        provided in any email We send or by contacting Us.
      </p>
      <p>
        We may use Email Marketing Service Providers to manage and send emails
        to You.
      </p>
      <H3 id='mailchimp'>Mailchimp</H3>
      <p>
        Mailchimp is an email marketing sending service provided by The Rocket
        Science Group LLC.
      </p>
      <p>
        For more information on the privacy practices of Mailchimp, please visit
        their Privacy policy:{' '}
        <LinkHref href="https://mailchimp.com/legal/privacy/">
          https://mailchimp.com/legal/privacy/
        </LinkHref>
        .
      </p>
      <H2 id='usagePerformance'>Usage, Performance and Miscellaneous</H2>
      <p>
        We may use third-party Service Providers to provide improvement of our
        Service.
      </p>
      <H2 id='invisibleReCAPTCHA'>Invisible reCAPTCHA</H2>
      <p>
        We may use an invisible captcha service named reCAPTCHA. reCAPTCHA is
        operated by Google. The reCAPTCHA service may collect information from
        You and from Your Device for security purposes. The information gathered
        by reCAPTCHA is held in accordance with the Privacy Policy of Google:{' '}
        <LinkHref href="https://www.google.com/intl/en/policies/privacy/">
          https://www.google.com/intl/en/policies/privacy/
        </LinkHref>
        .
      </p>
      <H2 id='GDPRPrivacy'>GDPR Privacy</H2>
      <H3 id='legalBasis'>Legal Basis for Processing Personal Data under GDPR</H3>
      <p>We may process Personal Data under the following conditions:</p>
      <ul>
        <li>
          Consent. You have given Your consent for processing Personal Data for
          one or more specific purposes.
        </li>
        <li>
          Performance of a contract. Provision of Personal Data is necessary for
          the performance of an agreement with You and/or for any
          pre-contractual obligations thereof.
        </li>
        <li>
          Legal obligations. Processing Personal Data is necessary for
          compliance with a legal obligation to which the Company is subject.
        </li>
        <li>
          Vital interests. Processing Personal Data is necessary in order to
          protect Your vital interests or of another natural person.
        </li>
        <li>
          Public interests. Processing Personal Data is related to a task that
          is carried out in the public interest or in the exercise of official
          authority vested in the Company.
        </li>
        <li>
          Legitimate interests. Processing Personal Data is necessary for the
          purposes of the legitimate interests pursued by the Company.
        </li>
      </ul>
      <p>
        In any case, the Company will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </p>
      <H3 id='yourRights'>Your Rights under the GDPR</H3>
      <p>
        The Company undertakes to respect the confidentiality of Your Personal
        Data and to guarantee You can exercise Your rights.
      </p>
      <p>
        You have the right under this Privacy Policy, and by law if You are
        within the EU, to:
      </p>
      <ul>
        <li>
          Request access to Your Personal Data. The right to access, update or
          delete the information We have on You. Whenever made possible, You can
          access, update or request deletion of Your Personal Data directly
          within Your account settings section. If you are unable to perform
          these actions yourself, please contact Us to assist You. This also
          enables You to receive a copy of the Personal Data We hold about You.
        </li>
        <li>
          Request correction of the Personal Data that We hold about You. You
          have the right to have any incomplete or inaccurate information We
          hold about You corrected.
        </li>
        <li>
          Object to processing of Your Personal Data. This right exists where We
          are relying on a legitimate interest as the legal basis for Our
          processing and there is something about Your particular situation
          which makes You want to object to our processing of Your Personal Data
          on this ground. You also have the right to object where We are
          processing Your Personal Data for direct marketing purposes.
        </li>
        <li>
          Request erasure of Your Personal Data. You have the right to ask Us to
          delete or remove Personal Data when there is no good reason for Us to
          continue processing it.
        </li>
        <li>
          Request the transfer of Your Personal Data. We will provide to You, or
          to a third-party You have chosen, Your Personal Data in a structured,
          commonly used, machine-readable format. Please note that this right
          only applies to automated information which You initially provided
          consent for Us to use or where We used the information to perform a
          contract with You.
        </li>
        <li>
          Withdraw Your consent. You have the right to withdraw Your consent on
          using your Personal Data. If You withdraw Your consent, We may not be
          able to provide You with access to certain specific functionalities of
          the Service.
        </li>
      </ul>
      <H3 id='exercisingGDPR'>Exercising of Your GDPR Data Protection Rights</H3>
      <p>
        You may exercise Your rights of access, rectification, cancellation and
        opposition by contacting Us. Please note that we may ask You to verify
        Your identity before responding to such requests. If You make a request,
        We will try our best to respond to You as soon as possible.
      </p>
      <p>
        You have the right to complain to a Data Protection Authority about Our
        collection and use of Your Personal Data. For more information, if You
        are in the European Economic Area (EEA), please contact Your local data
        protection authority in the EEA.
      </p>
      <H2 id='childrenPrivacy'>Children`&apos;`s Privacy</H2>
      <p>
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, we take steps to
        remove that information from Our servers.
      </p>
      <p>
        We also may limit how We collect, use, and store some of the information
        of Users between 13 and 18 years old. In some cases, this means We will
        be unable to provide certain functionality of the Service to these
        users.
      </p>
      <p>
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, we may
        require Your parent`&apos;`s consent before We collect and use that
        information.
      </p>
      <H2 id='linksToOther'>Links to Other Web Sites</H2>
      <p>
        Our Service may contain links to other Web Sites that are not operated
        by Us. If You click on a third-party link, You will be directed to that
        third party`&apos;`s site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third-party sites or services.
      </p>
      <H2 id='changesToPolicy'>Changes to this Privacy Policy</H2>
      <p>
        We may update our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the `&quot;`Last updated`&quot;`
        date at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <H2 id='contactUs'>Contact Us</H2>
      <p>If you have any questions about this Privacy Policy, You can contact us:</p>
      <p>By email: <LinkHref href='mailto:info@trustloop.io'>info@trustloop.io</LinkHref></p>
      <p>By phone number: <LinkHref href='tel:+443301330506'>+443301330506</LinkHref></p>
      <p>By Mail: Open B Gateway Ltd, Langdon House C/O Bevan Buckland Llp, Langdon Road, Swansea, Wales, SA1 8QY</p>
    </PublicPagesWrapper>
  );
};

export default PrivacyPolicy;
