import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProfileLogOut } from 'store/actions/profile';

const LogOut = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProfileLogOut());
  }, [dispatch]);

  return <Redirect to={'/sign_in'} />;
};

export default LogOut;
