import React, { useContext } from 'react';
import { ConsentContext } from '../index';
import { TitleWithBackButton } from '../../../../components';
import { Typography } from '@material-ui/core';
import Status from '../parts/Status';

const Revoked = () => {
  const {
    customerName,
    consentDetails: { bank, account },
  } = useContext(ConsentContext);

  return [
    <TitleWithBackButton key={'TitleWithBackButton'}>
      <Typography variant="h1">{'BankLoop Request'}</Typography>
    </TitleWithBackButton>,
    <Status
      account={account}
      bankName={bank}
      key={'Status'}
      status={'revoked'}
      customerName={customerName}
    />,
  ];
};

export default Revoked;
