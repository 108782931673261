import moment from 'moment';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const isFunction = functionToCheck => {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  );
};

export const getDatePeriodFromDateTime = dateTime => {
  let timeDiff = moment.duration(moment().diff(dateTime));
  
  if (timeDiff.asMinutes() < 2) {
    return '1 minute ago';
  }
  if (timeDiff.asMinutes() < 60) {
    return `${Math.floor(timeDiff.asMinutes())} minutes ago`;
  }
  if (timeDiff.asHours() >= 1 && timeDiff.asHours() < 2) {
    return '1 hour ago';
  }
  if (timeDiff.asHours() < 24) {
    return `${Math.floor(timeDiff.asHours())} hours ago`;
  }
  if (timeDiff.asDays() >= 1) {
    return `${Math.floor(timeDiff.asDays()) + 1} days ago`;
  }
};

export function domCopyInputContent(elem) {
  const copyText = elem.current.children.input;
  copyText.select();
  document.execCommand('copy');
}
