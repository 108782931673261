import { handleMessages } from 'utils/messages';

export const postcodePattern = /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;

export const textDefaultLength = 50;
export const textDefaultMintLength = 2;
export const specialChars = '!"#$%&\'()*+,./:;<=>?@[\\]^_`{|}~\\-\\\\';

const passMaxLength = 50;
const passMinLength = 8;

export const atLeast = ({ min, propName }) => {
  let reg = new RegExp(null);

  switch (propName) {
    case 'n':
    case 'number':
      reg = /\d/g;
      break;
    case 'l':
    case 'lower':
    case 'lowercase':
      reg = /[a-z]/g;
      break;
    case 'u':
    case 'upper':
    case 'uppercase':
      reg = /[A-Z]/g;
      break;
    case 's':
    case 'special':
    case 'symbols':
    case 'special symbols':
      reg = new RegExp(`[${specialChars}]`, 'g');
      break;
    default:
      break;
  }

  return value => value.match(reg) && value.match(reg).length >= min;
};

/*
 * Validator conditions
 */
export const validatorPatterns = {
  atLeast: atLeast,
  commonUserName: value =>
    /^[0-9a-zA-Z-"@',. ]+$/g.test(value) &&
    !/[#&<=>~;:[\]$^_`%()*+{|}\\?]/g.test(value),
  numbersOnly: value => /^[0-9 ]+$/g.test(value),
  lettersOnly: value => /^[\w ]+$/g.test(value),
  phone: value => {
    return (
      !value ||
      /^\+?[0-9 ]+?\(?([0-9 ]+)\)?[-. ]?([0-9 ]+)[-. ]?([0-9 ]+)$/.test(value)
    );
  },
  email: value =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    ),
  withoutSpecialCharacters: value =>
    !RegExp(`[${specialChars}]`, 'g').test(value),
  minNumValue: min => value => value >= min,
  maxNumValue: max => value => value <= max,
  minLength: min => value => value.length >= min,
  maxLength: max => value => value.length <= max,
  exactLength: length => value => value.length === length,
  isMatched: valueThatMustBeMatched => value => {
    return value.localeCompare(valueThatMustBeMatched);
  },
  // eslint-disable-next-line
  isRegExpTest: ({ regExp, message }) => value => {
    return regExp.test(value);
  },
  isRequired: value => {
    return !!value;
  },
  // eslint-disable-next-line
  isRequiredWithAddMsg: message => value => {
    return !!value;
  },
};

/*
 * Used for handling proper messages. Can be using with translate
 */
export const applyValidatorPattern = (pattern, argsObj) => {
  let matchedPattern = validatorPatterns[pattern];

  if (!validatorPatterns[pattern]) {
    throw new Error(
      `There is no pattern called '${pattern}' inside $regexPattern`,
    );
  }

  if (argsObj) {
    matchedPattern = validatorPatterns[pattern](argsObj);
  }

  return value => {
    return matchedPattern(value || '')
      ? undefined
      : handleMessages(pattern, argsObj);
  };
};

/*
 * Validators grouped by types, for using with from fields for example
 */

const passwordValidatorPattern = [
  applyValidatorPattern('isRequired'),
  applyValidatorPattern('atLeast', { min: 1, propName: 'number' }),
  applyValidatorPattern('atLeast', { min: 1, propName: 'lowercase' }),
  applyValidatorPattern('atLeast', { min: 1, propName: 'uppercase' }),
  applyValidatorPattern('atLeast', {
    min: 1,
    propName: 'special symbols',
  }),
  applyValidatorPattern('maxLength', passMaxLength),
  applyValidatorPattern('minLength', passMinLength),
];

export const validationByFieldType = {
  validationCode: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('numbersOnly'),
    applyValidatorPattern('maxLength', 6),
    applyValidatorPattern('minLength', 6),
  ],
  phone: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('phone'),
    applyValidatorPattern('minLength', 12),
  ],
  phone_notReq: [
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('phone'),
    applyValidatorPattern('minLength', textDefaultMintLength),
  ],
  email: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('email'),
    applyValidatorPattern('minLength', textDefaultMintLength),
  ],
  name: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('commonUserName'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaultMintLength),
  ],
  first_name: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('commonUserName'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaultMintLength),
  ],
  last_name: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('commonUserName'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaultMintLength),
  ],
  company_name: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('commonUserName'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaultMintLength),
  ],
  company_number: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaultMintLength),
    applyValidatorPattern('numbersOnly'),
  ],
  company_address: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaultMintLength),
  ],
  company_postcode: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaultMintLength),
  ],
  consent_exp_notify_days: [
    applyValidatorPattern(
      'isRequiredWithAddMsg',
      'Required. Minimum value is 2 and maximum is 90 days',
    ),
    applyValidatorPattern('minNumValue', 2),
    applyValidatorPattern('maxNumValue', 90),
  ],
  title: [
    applyValidatorPattern('isRequired'),
    applyValidatorPattern('maxLength', textDefaultLength),
    applyValidatorPattern('minLength', textDefaultMintLength),
  ],
  address: [
    applyValidatorPattern('minLength', textDefaultMintLength),
    applyValidatorPattern('maxLength', textDefaultLength),
  ],
  description: [applyValidatorPattern('maxLength', 1000)],
  postcode: [applyValidatorPattern('maxLength', 50)],
  select: [applyValidatorPattern('isRequired')],
  password: passwordValidatorPattern,
};

export const rePasswordMatch = values => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Required';
  }
  if (!values.re_password) {
    errors.re_password = 'Required';
  } else if (values.re_password !== values.password) {
    errors.re_password = 'Must match';
  }
  return errors;
};

export const composeValidators = validators => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const validationCodeFromSmsRequire = value => {
  const reg = /^[0-9]{6}$/;
  return value && reg.test(value)
    ? undefined
    : 'Should be valid code from SMS (6 digits)';
};
