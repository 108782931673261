import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/Button';
import ConfirmSvg from 'svg/Confirm';
import { Grid, Typography } from '@material-ui/core';
import LayoutsPage from 'layouts/Page';
import Box from '@material-ui/core/Box';
import { useHistory, useLocation } from 'react-router-dom';
import Strong from '../../../../@src/components/Strong';
import BottomTextBlock from '../../../../@src/containers/Consent/parts/BottomTextBlock';
import ExpansionPanelsView from '../../../../@src/containers/Consent/parts/ExpansionPanelView';
import Steps from '../../../../@src/containers/Consent/parts/Steps';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  svg: {
    textAlign: 'center',
  },
}));

function ConsentConfirmSuccess() {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();

  const { lifetime, customerName, redirect_url } = state || {};
  return (
    <LayoutsPage isFullHeight>
      <div className={classes.root}>
        <Grid spacing={1} container justifyContent={'center'} alignItems={'center'}>
          <Grid item xs={12}>
            <Steps activeStep={3} />
          </Grid>
          <Grid item xs={12}>
            <ConfirmSvg className={classes.svg} />
          </Grid>
          <Grid item xs={12}>
            <Typography align={'center'} variant={'h4'}>
              Confirmed
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align={'center'}>
              <Strong>TrustLoop</Strong> has created a{' '}
              <Strong color="secondary">secure, read only, time limited</Strong>{' '}
              connection to your online banking data. 
              It will be available for {customerName} in a couple of minutes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BottomTextBlock status={'confirmed'} lifetime={lifetime} />
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanelsView isShared superWide isExpanded/>
          </Grid>
          <Grid item xs={12}>
            <Box pt={2}>
              <Button
                wide
                style={{ flexGrow: '1' }}
                variant={'contained'}
                color={'primary'}
                onClick={() => redirect_url && redirect_url!='' ? window.location.href = redirect_url : history.push('/')}
              >
                CONTINUE
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </LayoutsPage>
  );
}

export default ConsentConfirmSuccess;
