import React from 'react';
import Confirmed from './Confirmed';
import Pending from './Pending';
import Revoked from './Revoked';
import ExpiredConsent from './ExpiredConsent';
import ExpiredRequest from './ExpiredRequest';
import Rejected from './Rejected';
import DataSharingRequest from './DataSharingRequest';

const Status = ({ status, customerName, bankName, account = '' }) => {
  const childrenProps = {
    customerName,
    bankName,
    account,
    status,
  };

  const RenderComponent = (() => {
    switch (status) {
      case 'pending':
        return Pending;
      case 'revoked':
        return Revoked;
      case 'confirmed':
      case 'consent-data-received':
      case 'consent-data-shared':
      case 'consent-data-sharing-rejected':
        return Confirmed;
      case 'consent-expired':
        return ExpiredConsent;
      case 'request-expired':
        return ExpiredRequest;
      case 'rejected':
        return Rejected;
      case 'data-sharing-request':
        return DataSharingRequest;
      default:
        return Pending;
    }
  })();

  return <RenderComponent {...childrenProps} />;
};
export default Status;
