import React, { useContext } from 'react';
import LayoutsPage from '../../../layouts/Page';
import H2 from '../../components/H2';
import LinkHref from '../../components/LinkHref';
import Strong from '../../components/Strong';
import Link from '../../components/Link';
import { ConsentContext } from './index';
import Steps from './parts/Steps';
import ConsentsByStatus from './ConsentsByStatus';

const WelcomePage = () => {
  // const [isAccepted, setIsAccepted] = useState(false);
  // const isAuth = useSelector(selectIsAuthSuccess);

  const {
    // handleOnGoForward,
    // userData,
    // isProcessing,
    customerName,
    consentDetails,
    // setIsProcessing
  } = useContext(ConsentContext);
  {/* Hiding as per TL-212 */}
  // const handleOnProceed = () => {
  //   apiCallWrapper({
  //     onStart: () => setIsProcessing(true),
  //     apiCall: () =>
  //       post(
  //         endpoints.consentAccount,
  //         mapperPsuConsentAccount(userData, consentDetails.id),
  //       ),
  //     onFinish: () => {
  //       setIsProcessing(false);
  //       handleOnGoForward();
  //     },
  //     onAny: () => {
  //       setIsProcessing(false);
  //     },
  //     notify: true,
  //   });
  // };
  return (
    <LayoutsPage isFullHeight>
      {/* {isAuth ? (
        <TitleWithBackButton>
          <h1>Welcome to TrustLoop</h1>
        </TitleWithBackButton>
      ) : (
        <h1>Welcome to TrustLoop</h1>
      )} */}
      <Steps activeStep={0} />
      <H2>Welcome to TrustLoop</H2>
      <p>
        We are <Strong>TrustLoop</Strong>, an authorised provider of account
        information services, regulated by the Financial Conduct Authority,
        reference number 913211. You can find our website here:{' '}
        <LinkHref color={'third'} href={'https://www.trustloop.io'}>
          www.trustloop.io
        </LinkHref>
        .
      </p>
      <H2>What do we do?</H2>
      <p>
        <Strong>TrustLoop</Strong> can create a{' '}
        <Strong color={'secondary'}>secure, read only</Strong> and{' '}
        <Strong color="secondary">time limited</Strong> connection to your
        online bank account data. The connection can only be created with your
        explicit consent which you may revoke at any time.
      </p>
      {/* Hiding as per TL-212 */}
      {/* <H2>What can TrustLoop do for me?</H2>
      <p>
        We have been asked by <Strong>{customerName}</Strong> to create a{' '}
        <Strong color={'secondary'}>secure, read only</Strong> and{' '}
        <Strong color="secondary">time limited</Strong> connection to your
        online bank account data for the folowing reason:
      </p> */}
      <p>
        We will share your online bank account data with{' '}
        <Strong>{customerName}</Strong> for the folowing reason:
      </p>
      <p className="-alignCenter">
        <Strong>
          {consentDetails.customer.reason_of_request
            ? consentDetails.customer.reason_of_request
            : 'To review your income and expenditure to make sure that you can afford to repay the loan.'}
        </Strong>
      </p>
      <ConsentsByStatus value={consentDetails}/>
      {/* Hiding as per TL-212 */}
      {/* <CheckboxLabelStyled>
        <Checkbox
          checked={isAccepted}
          onChange={() => setIsAccepted(isAccepted => !isAccepted)}
        />
        <p>
          Please tick to confirm that you have read and understood this
          information.
        </p>
      </CheckboxLabelStyled>
      <Button
        loading={isProcessing}
        wide
        disabled={!isAccepted}
        onClick={handleOnProceed}
      >
        Proceed
      </Button> */}
      <p>
        By proceeding you are agreeing with{' '}
        <Link color="secondary" to={'/terms-of-use'}>
          Terms of use
        </Link>{' '}
        and{' '}
        <Link color="secondary" to="/privacy-policy">
          Privacy Policy
        </Link>
      </p>
      <p style={{ fontSize: '.8rem', marginTop: '1rem' }}>
        <Strong>TrustLoop</Strong> is a trading name of OPEN B GATEWAY LIMITED
        (Company Number: 11910674), regulated by the UK Financial Conduct
        Authority under the Payment Services Regulations 2017 as an Authorised
        Payment Institution to provide account information services (Firm
        Reference Number: 913211).
      </p>
    </LayoutsPage>
  );
};

// const CheckboxLabelStyled = styled.div`
//   display: grid;
//   margin-left: -1rem;
//   height: max-content;
//   justify-items: center;
//   align-items: center;
//   grid-auto-flow: column;
//   grid-auto-columns: 3rem 1fr;
//   grid-auto-rows: minmax(100px, auto);
//   margin-bottom: -1rem;
//   p {
//     padding: 0;
//     margin: 0;
//   }
// `;

export default WelcomePage;
