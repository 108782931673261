import React from 'react';
import styled from 'styled-components';
import PublicPagesWrapper from '../Wrapper';
import LinkHref from '@src/components/LinkHref';
import H2 from '@src/components/H2';

const List = styled.ul`
  li {
    margin-bottom: 0.5em;
  }
`;

const TermsOfUse = () => {
  return (
    <PublicPagesWrapper title={'Terms of Use for End Users'}>
      <p>
        These are terms of use for TrustLoop, a trading name of OPEN B GATEWAY
        LIMITED (“us”, “we”, “our”), company number 11910674, a registered
        Account Information Service Provider FCA registration number 913211,
        registered office address: Langdon House C/O Bevan Buckland Llp, Langdon
        Road, Swansea, Wales, SA1 8QY.
      </p>
      <p>
        TrustLoop’s services (the “Services”) consists of the “Website” and “Web
        Application” hosted at trustloop.io made available by us, and any other
        related content and materials, however you access them, whether via the
        Site or via mobile or tablet app.
      </p>
      <p>
        If you have any questions, please email us at{' '}
        <LinkHref href="mailto:info@trustloop.io">info@trustloop.io</LinkHref>.
      </p>
      <H2>What Service does TrustLoop provide?</H2>
      <p>
        Our Service to you constitutes an Account Information Service (an
        activity regulated by the Financial Conduct Authority) which always
        requires that you provide your explicit consent to us before we can
        perform the Account Information Service and, as a part of that service,
        retrieve relevant account information about and from your online bank
        accounts and also make the information available to the Partner that you
        have designated.
      </p>
      <p>
        TrustLoop can retrieve account information about payment accounts,
        credit or debit card accounts, savings accounts, investment savings
        accounts etc as long as the account is accessible online.
      </p>
      <H2>How TrustLoop works</H2>
      <p>
        Before using the Service review the following points that explain how
        the Service works:
      </p>
      <List>
        <li>
          A Partner of TrustLoop’s will engage TrustLoop to carry out Account
          Information Services on your behalf.
        </li>
        <li>
          We will send you an email outlining the Service along with details of
          our Partner including a link to the Service.
        </li>
        <li>
          You will be asked to confirm that you understand the Service being
          offered and review our Privacy Policy and Terms of Use prior to
          agreeing to use the Service.
        </li>
        <li>
          Next you will be presented with a list of available banks that
          TrustLoop is able to retrieve account information from. Based on the
          presented list, you choose the bank you want to retrieve the account
          information from and choose to give your explicit consent to the
          provision of the Service. In the same step, you are asked to create
          your End User account.
        </li>
        <li>
          When we have received your consent on the provision of the Account
          Information Service, you identify yourself towards the bank by using
          your online banking login details. TrustLoop retrieves the account
          information about the account(s) that you have instructed TrustLoop to
          retrieve account information on.
        </li>
        <li>
          Next, we will ask for your explicit consent to share the account
          information with the designated Partner designated by you.
        </li>
        <li>
          The account information is then made available to you via your
          TrustLoop account, and also the Partner designated by you.
        </li>
        <li>
          You may revoke your consent at any time by signing in to your
          TrustLoop account.
        </li>
      </List>
      <H2>Your TrustLoop account</H2>
      <p>
        Your TrustLoop account gives you control over your consent and your
        data.
      </p>
      <p>You can use your account to:</p>
      <List>
        <li>View the data we hold about you.</li>
        <li>View an Action Log that shows your account history.</li>
        <li>View your consents.</li>
        <li>Revoke a consent.</li>
        <li>Delete your account.</li>
      </List>
      <p>
        When you revoke a consent we break the read-only connection to your bank
        account provider for that specific consent, and your designated Partner
        won’t be able to see your bank account information associated with that
        consent any longer.
      </p>
      <p>
        When you delete your account, we break all of your read-only connections
        to your bank account providers, and the respective designated Partners
        won’t be able to see your bank account information any longer. We also
        delete all of the information we hold about you.
      </p>
      <p>We will close your account after 90 days of inactivity.</p>
      <p>
        TrustLoop delivers the Services to you in accordance with our regulatory
        responsibilities and other laws governing and protecting your personal
        data.
      </p>
      <p>
        The Terms constitute an agreement between the End User (“you”) and
        TrustLoop (“we”, “us”, “our”), and you agree to these Terms by creating
        an account with us.
      </p>
      <p>
        Please read through the Terms thoroughly before you agree to them. If
        you do not accept all provisions of the Terms, you are not entitled to
        use the Services.
      </p>
      <List as='ol'>
        <li>
          By using the Service, you authorise us to access your account
          information as explained at the point at which the Service is offered
          to you.
        </li>
        <li>
          You understand that you may revoke TrustLoop’s read-only access to
          your account information at any time via your TrustLoop account.
        </li>
        <li>
          You are responsible for and guarantee that
          <List>
            <li>
              to provide accurate and up-to-date information whenever necessary,
              including the email address associated with your TrustLoop
              account.
            </li>
            <li>
              to protect the security of your TrustLoop account by managing your
              password appropriately.
            </li>
            <li>
              your use of the Services is made in accordance with the Terms and
              applicable laws and regulations.
            </li>
            <li>
              you protect your technical equipment from unauthorised usage, e.g.
              with pin code or your biometrical characteristics (e.g.
              fingerprint reader).
            </li>
            <li>
              you immediately inform TrustLoop if you suspect or have knowledge
              about unauthorised usage of the Services or of your technical
              equipment.
            </li>
            <li>
              the information you provide in the Services does not infringe the
              rights of anybody else or in any other way violates law or someone
              else’s rights.
            </li>
            <li>
              you do not copy, modify, distribute or publish content provided by
              TrustLoop within the Services or in any other way misuse the
              Services or use it for any other purpose than they are intended
              for.
            </li>
            <li>
              you do not try to circumvent the security measures or other
              limitations of the Services.
            </li>
          </List>
        </li>
        <li>
          TrustLoop agrees to use your account information and any other
          personal information we hold about you in accordance with our Privacy
          Policy and only for the predefined purposes you have agreed to that
          facilitate delivery of the Service.
        </li>
        <li>
          Our Service may be offered through one of our Partners. TrustLoop is
          only responsible for the services that are provided to you by us in
          accordance with these Terms, and our Partner’s services are provided
          to you in accordance with the terms and conditions that apply for the
          respective Partner’s services provided by the Partner.
        </li>
        <li>
          TrustLoop provides the Services to you free of charge. Fees imposed by
          your mobile phone operator or internet provider may apply. For Partner
          Services it is the Partner`&apos;`s fees and terms and conditions that apply.
        </li>
        <li>
          We may use the services of third parties (“Third Party Service
          Providers”) who help us to deliver the Service to you (for example
          third party technology companies who may provide elements of the
          Service’s functionality). Third Party Service Providers will only use
          your information to help provide the Service - they will not market to
          you.
        </li>
        <li>
          TrustLoop has the right to transfer its rights and obligations under
          the Agreement and has the right to engage subcontractors for the
          fulfilment of its obligations.
        </li>
        <li>
          The Services are normally available 24/7 however we do not guarantee
          the Services are free from errors or interruptions. We cannot
          guarantee the availability, reliability or accuracy of the information
          presented in the Services. The performance of the Services can be
          dependent on services provided by a third party (e.g. mobile phone
          operator or internet provider). We are not responsible for such
          services provided by a third party.
        </li>
        <li>
          TrustLoop assumes no responsibility for any financial or other type of
          decision you take based on your use of the Services or for services or
          goods you choose to purchase from independent third parties or
          TrustLoop’s collaboration partners, including Partners.
        </li>
        <li>
          If you violate the Terms or otherwise harm us, we are entitled to
          terminate your access to the Service. We reserve the right to suspend
          you from using the Services at any time if you violate the Terms or
          otherwise use the Services in a manner that may cause damage to
          TrustLoop or a third party.
        </li>
        <li>
          We reserve the right to, at any time, change the Services, amend the
          Terms or cease the provision of the Services. If you do not accept the
          amended Terms, you have the right to at any time terminate the
          Agreement. If you continue to use the Services after an amendment of
          the Terms has come into force, you are deemed to have accepted the
          amended Terms.
        </li>
      </List>
      <p>
        We care about our users. Therefore, we are keen to answer your questions
        or to know if you are dissatisfied with us and our goal is to address
        all inquiries as soon as possible. For contact with our customer
        service, please email <LinkHref href='mailto:info@trustloop.io'>info@trustloop.io</LinkHref>.
      </p>
    </PublicPagesWrapper>
  );
};

export default TermsOfUse;
