import React from 'react';
import LayoutsAppMobile from 'layouts/AppMobile';

const LayoutsTemplates = ({ children }) => {
  let Layout = LayoutsAppMobile;

  return <Layout>{children}</Layout>;
};

export default LayoutsTemplates;
