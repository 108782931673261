import React from 'react';
import styled from 'styled-components';

const ErrorWrapper = ({ children }) => {
  return <ErrorWrapperStyled>{children}</ErrorWrapperStyled>;
};

export const ErrorWrapperStyled = styled('div')`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  grid-auto-columns: max-content;
  background-color: #ed6c78;
  color: white;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  margin: 5rem auto;
  max-width: max-content;
  padding: 2rem 5rem;
`;

export default ErrorWrapper;
