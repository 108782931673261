export default {
  appMobile: {
    dashboard: {
      main: {
        gridArea: 'main',
        display: 'flex',
        overflowY: 'auto',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: 'white',
      },
    },
    dashboardCenter: {
      main: {
        gridArea: 'main',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
      },
    },
  },
  appDesktop: {
    dashboard: {
      main: {
        gridArea: 'main',
        backgroundColor: 'white',
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: `160px 1fr`,
        gridTemplateAreas: '"top" "content"',
      },
    },
    authForm: {
      footer: {
        gridArea: 'footer',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'flex-end',
      },
      header: {
        gridArea: 'header',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
      },
      main: {
        gridArea: 'main',
        display: 'flex',
        paddingTop: '5rem',
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
      form: {
        '& .MuiGrid-justify-xs-space-between': {
          justifyContent: 'center',
          alignItems: 'center',
        },
        display: 'flex',
        maxWidth: '400px',
        justifyContent: 'center',
      },
      paper: {
        boxShadow: '0px 10px 20px rgba(184, 180, 179, 0.15)',
        padding: '5rem',
      },
    },
  },
};
