/* eslint-disable no-undef */
const __ENV__ = process.env['REACT_APP_ENV'] || 'qa';
const __IS_LOCAL__ = process.env['REACT_APP_IS_LOCAL'];
/* eslint-enable no-undef */

const beUrls = {
  qa: 'https://dev-backend.open-banking-gateway.com',
  stage: 'https://stage-backend.open-banking-gateway.com',
  local: 'http://localhost:8080',
  prod: 'https://prod-backend.trustloop.io',
};

const getBeUrl = () => {
  if (__IS_LOCAL__) {
    return beUrls['local'];
  }

  return beUrls[__ENV__];
};

const be = getBeUrl();

const apiv = be + '/api/v0';
const _psu = apiv + '/psu';

const endpoints = {
  common: {
    providers: apiv + '/providers',
    consentCallback: apiv + '/consent/callback',
  },
  systemLogin: _psu + '/system/signin',
  preAuthorize: _psu + '/preauthorize',
  actionLog: _psu + '/consent/actions',
  consentConfirm: _psu + '/consent/confirm',
  consentReject: _psu + '/consent/reject',
  consent: _psu + '/consent',
  consentDataApprove: _psu + '/consent/data/approve',
  consentDataReject: _psu + '/consent/data/reject',
  consentOtp: _psu + '/consent/otp',
  consentsTransactions: _psu + '/consents/transactions',
  consents: _psu + '/consents',
  consentsReconfirm: _psu + '/consent/reconfirm',
  consentsRevoke: _psu + '/consent/revoke',
  consentAdd: _psu + '/consent/add',
  consentAccount: _psu + '/consent/account',
  signIn: _psu + '/signin',
  signOut: _psu + '/signout',
  signUp: _psu + '/signup',
  preSignUp: _psu + '/pre-signup',
  finishRegistration: _psu + '/reg_info_by_otp',
  updateUserInfoByOtp: _psu + '/update_by_otp',
  reSendConfirmCode: _psu + '/resend_confirmation_code',
  forgotPassword: _psu + '/reset_password',
  resetPassword: _psu + '/confirm_reset_password',
  validationSms: _psu + '/confirm_signup',
  deleteAccount: _psu + '/delete_account',
  postcode: _psu + '/postcode/address',
};

export default endpoints;
