export const paletteBrand = {
  gray: '#B8B4B2',
  blue: '#5ABDD7',
  red: '#EB5757',
  trustloop: {
    gray: '#B8B4B2',
    blue: '#5ABDD7',
    red: '#EB5757',
  },
};

export const palette = {
  primary: {
    main: paletteBrand.blue,
    light: paletteBrand.blue,
    dark: paletteBrand.blue,
    contrastText: '#fff',
  },
  secondary: {
    main: paletteBrand.red,
  },
  brand: paletteBrand,
};

export default palette;
