import routesPsu from 'config/routes_psu';
import endpoints from './endpoints';

// eslint-disable-next-line no-unused-vars,no-undef
// var __DEV__ = process.env.NODE_ENV;
// eslint-disable-next-line no-unused-vars,no-undef
var __ENV__ = process.env['REACT_APP_ENV'] || 'qa';
// eslint-disable-next-line no-unused-vars,no-undef
var __IS_LOCAL__ = !!process.env['REACT_APP_IS_LOCAL'];

console.debug({
  __ENV__: __ENV__,
  __IS_LOCAL__: __IS_LOCAL__,
});

const appConfig = {
  routes: routesPsu,
  siteTitle: 'TrustLoop',
  defaultRedirectPrivateRoute: '/dashboard',
  defaultRedirectPublicRoute: '/sign_in',
  redirectOnError: __ENV__ === 'qa',
  toastAutoCloseTimeout: 3000,
  endpoints,
  storage: sessionStorage,
};

export default appConfig;
