import { createSelector } from 'reselect';

const getUserData = state => state.profile.userData;
const getUserDataStatus = state =>
  state.profile.userData && state.profile.userData.status;
const getUserEmail = state =>
  state.profile.userData && state.profile.userData.email;
const getIsProcessing = state => state.profile.isProcessing;

export const selectIsAuthSuccess = createSelector(
  getUserData,
  getUserDataStatus,
  (userData, userDataStatus) => !!(userData && userDataStatus === '1'),
);

export const selectIsProcessing = createSelector(
  getIsProcessing,
  isProcessing => isProcessing,
);

export const selectIsValidationNeeded = createSelector(
  getUserData,
  getUserDataStatus,
  (userData, userDataStatus) => !!(userData && userDataStatus === '0'),
);

export const selectUserEmail = createSelector(
  getUserEmail,
  getUserEmail => getUserEmail,
);

export const selectUserData = createSelector(
  getUserData,
  userData => userData,
);
