import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ExpansionPanelsView from '../../parts/ExpansionPanelView';
import Button from 'components/Button';
import Box from '@material-ui/core/Box';
import { apiCallWrapper, get, post } from '@src/utils/fetch';
import General from './ConsentDetails/General';
import { toast } from 'react-toastify';
import AutoRenewIcon from '../../../../../components/AutoRenewIcon';
import Grid from '@material-ui/core/Grid';
import appConfig from '../../../../../config';
import Status from '../../parts/Status';

const Common = ({ customerName, consentId, account, status, bankName }) => {
  const [consentDetailedData, setConsentDetailedData] = React.useState({
    consent: {
      psu_account: {},
      created_at: null,
      psu_email: null,
    },
  });
  const [isProcessing, setIsProcessing] = React.useState(true);
  const [isRevokeProcessing, setIsRevokeProcessing] = React.useState(false);
  const history = useHistory();

  const handleOnRevoke = useCallback(() => {
    apiCallWrapper({
      notify: true,
      onStart: () => setIsRevokeProcessing(true),
      onAny: () => setIsRevokeProcessing(false),
      apiCall: () =>
        post(appConfig.endpoints.consentsRevoke, {
          consent_id: consentId,
        }),
      // eslint-disable-next-line
      onFinish: resp => {
        toast.success('Successfully revoked');
        history.push('/');
      },
    });
  }, [consentId, history]);

  useEffect(() => {
    apiCallWrapper({
      onStart: () => setIsProcessing(true),
      apiCall: () =>
        get(`${appConfig.endpoints.consents}/${consentId}/general`),
      onFinish: resp => {
        setConsentDetailedData(resp);
      },
      onFail: () => {},
      onAny: () => {
        setIsProcessing(false);
      },
    });
  }, [consentId]);

  return (
    <Grid container justifyContent={'center'} alignItems={'center'} spacing={1}>
      <Grid item xs={12}>
        <Status
          status={status}
          customerName={customerName}
          account={account}
          bankName={bankName}
        />
      </Grid>
      <Grid item xs={12}>
        <General isProcessing={isProcessing} data={consentDetailedData} />
      </Grid>
      <Grid item xs={12}>
        <ExpansionPanelsView superWide isExpanded />
      </Grid>
      <Grid item xs={12}>
        <Box py={2}>
          <Grid
            container
            display="flex"
            style={{ flexDirection: 'row' }}
            justifyContent={'space-between'}
            spacing={1}
          >
            <Grid style={{ flexGrow: '1' }} item>
              <Button
                wide
                customColor={'warning'}
                variant={'contained'}
                color={'primary'}
                onClick={handleOnRevoke}
              >
                {!isRevokeProcessing ? 'REVOKE' : <AutoRenewIcon />}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Common;
