import React from 'react';
import { Autorenew } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  animation: {
    animationName: 'spin',
    animationDuration: '1.2s',
    animationTimingFunction: 'linear',
    animationIteration: 'infinite',
  },
});

const AutoRenewIcon = ({ className }) => {
  const classes = useStyles();
  return (
    <Autorenew color="action" className={clsx(classes.animation, className)} />
  );
};

export default AutoRenewIcon;
