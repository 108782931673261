import React, { useState } from 'react';
import { apiCallWrapper, get } from '../../../@src/utils/fetch';
import TextField from '@material-ui/core/TextField';
import appConfig from '../../../config';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import PreloaderBrandingStyled from '../../PreloaderBranding';
import styled from 'styled-components';

const filter = createFilterOptions();

const PostcodeAutocomplete = ({
  handleOnOptionSelect,
  input,
  meta,
  validateFunc,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [value, setValue] = useState({ title: input.value } || {});
  const [options, setOptions] = useState([]);

  // eslint-disable-next-line
  const onInputChange = (event, newValue, reason) => {
    if (validateFunc(newValue)) {
      if (options.length > 0) {
        setOptions([]);
      }
      apiCallWrapper({
        onStart: () => setIsProcessing(true),
        onAny: () => setIsProcessing(false),
        apiCall: () => get(`${appConfig.endpoints.postcode}/${newValue}`),
        onFinish: resp => {
          setOptions([
            {
              title: `${resp.county} ${resp.town} ${resp.postcode} ${resp.address_1} ${resp.address_2}  ${resp.address_3} ${resp.address_4}`,
              value: resp,
            },
          ]);
        },
        notify: false,
      });
    } else {
      if (options.length > 0) {
        setOptions([]);
      }
    }
  };

  const onChange = (event, newValue) => {
    if (newValue && newValue.inputValue) {
      setValue({
        title: newValue.inputValue,
      });

      return;
    }

    if (newValue && newValue.value && newValue.value.postcode) {
      setValue({ title: newValue.value.postcode });
      handleOnOptionSelect(newValue.value);

      return;
    }

    setValue({ title: newValue });
    handleOnOptionSelect({ postcode: newValue });
  };

  return (
    <Autocomplete
      value={value}
      name={'postcode'}
      id="postcode"
      onChange={onChange}
      onInputChange={onInputChange}
      options={options}
      freeSolo
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '' && options.length === 0) {
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: params.inputValue,
              notFound: true,
            });
          }
        }

        return filtered;
      }}
      getOptionLabel={option => option.title || ''}
      renderOption={option => {
        if (option.notFound) {
          return (
            <NotFoundStyled>
              <div>Cant find postcode</div>
              <div>
                Add manually{' '}
                <span className={'newPostcode'}>{option.title}</span>?
              </div>
            </NotFoundStyled>
          );
        }

        return <React.Fragment>{option.title}</React.Fragment>;
      }}
      renderInput={params => {
        return (
          <AutocompleteFieldStyled>
            <TextField
              {...input}
              {...params}
              label="Autofill address by postcode"
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
            />
            <div>{isProcessing && <PreloaderBrandingStyled />} </div>
          </AutocompleteFieldStyled>
        );
      }}
    />
  );
};

const AutocompleteFieldStyled = styled.div`
  display: grid;
  width: 100%;
  grid-auto-flow: column;
  grid-auto-columns: minmax(200px, 1fr) minmax(3rem, 3rem);
  grid-auto-rows: max-content;
  gap: 1rem;
  justify-items: center;
  align-items: center;

  .assets_svg {
    margin-bottom: -2rem;
  }
  .assets_svg svg {
    height: 2rem;
  }
`;

const NotFoundStyled = styled.div`
  display: grid;
  grid-auto-flow: row;

  & div:first-child {
    color: #777;
    font-size: 0.8rem;
  }

  .newPostcode {
    color: #5abdd7;
  }
`;

export default PostcodeAutocomplete;
