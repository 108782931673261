import React from 'react';

const LayoutsTemplatesContext = React.createContext(null);

const LayoutsTemplatesContextProvider = props => {
  const value = props => <div>{props.children}</div>;

  return (
    <LayoutsTemplatesContext.Provider value={value}>
      {props.children}
    </LayoutsTemplatesContext.Provider>
  );
};

export { LayoutsTemplatesContext, LayoutsTemplatesContextProvider };
