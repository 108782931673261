import React from 'react';

import { useWindowSize } from 'utils/hooks';

/* eslint-disable react/display-name */
export default ({ height = 5, className }) => {
  const { width } = useWindowSize();

  return (
    <div className={className}>
      <svg
        width={width}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x={0} width={width / 4} height={height} fill="#5ABDD7" />
        <rect x={width / 4} width={width / 4} height={height} fill="#FAB733" />
        <rect
          x={(width / 4) * 2}
          width={width / 4}
          height={height}
          fill="#ED6C78"
        />
        <rect
          x={(width / 4) * 3}
          width={width / 4}
          height={height}
          fill="#B8B4B2"
        />
      </svg>
    </div>
  );
};
