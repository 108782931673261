import React, { useState, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { apiCallWrapper, get } from '@src/utils/fetch';
import endpoints from 'config/endpoints'
import PreloaderBrandingStyled from './components/PreloaderBranding';

const ReCaptchaProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [key, setKey] = useState('');

  useEffect(() => {
    apiCallWrapper({
      apiCall: () => get(endpoints.preAuthorize),
      onFinish: resp => {
        setKey(resp.site_key);
        setLoading(false);
      },
      addMsg: 'Preauthorize error',
      notify: true
    })
  }, [])

  return (
    isLoading ? 
      <PreloaderBrandingStyled />
    :
      <GoogleReCaptchaProvider reCaptchaKey={key}>
        {children}
      </GoogleReCaptchaProvider>
  )
};

export default ReCaptchaProvider;
