import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import Form from 'components/Form';
import Layout from 'layouts/Page';

const PageInitAuthForm = ({
  pageTitle,
  textUnderTheForm,
  textAboveTheForm,
  formParams,
  headerNavLinkParams,
}) => {
  const {
    fieldsMarkup,
    submitBtnLabel = pageTitle,
    handleOnSubmit,
    loading,
    validate,
    initValues,
  } = formParams;

  const theme = useTheme();

  const defTextAboveTheForm = (
    <Grid item>
      <Typography variant={'h2'}>{pageTitle}</Typography>
    </Grid>
  );

  return (
    <Layout
      mainPageType={'authForm'}
      maxWidth={'xs'}
      headerNavLinkParams={headerNavLinkParams}
    >
      <Grid
        container
        spacing={theme.defaults.spacing.layouts.page.primary}
        justifyContent="center"
        alignItems="baseline"
      >
        <Box width={'100%'}>
          {textAboveTheForm || defTextAboveTheForm}
          <Form
            initValues={initValues}
            validate={validate}
            debugPre={false}
            submitBtnLabel={submitBtnLabel}
            handleOnSubmit={handleOnSubmit}
            loading={loading}
            fieldsMarkup={fieldsMarkup}
          />
          <Box pt={3}>{textUnderTheForm || null}</Box>
        </Box>
      </Grid>
    </Layout>
  );
};

export default PageInitAuthForm;
