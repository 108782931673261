import React, { useContext } from 'react';
import { TitleWithBackButton } from '../../../../../components';
import { Typography } from '@material-ui/core';
import Status from '../../parts/Status';
import { ConsentContext } from '../../index';

const ExpiredConsent = () => {
  const {
    customerName,
    consentDetails: { bank, account },
  } = useContext(ConsentContext);

  return [
    <TitleWithBackButton key={'TitleWithBackButton'}>
      <Typography variant="h1">{'BankLoop Request'}</Typography>
    </TitleWithBackButton>,
    <Status
      key={'Status'}
      status={'consent-expired'}
      customerName={customerName}
      account={account}
      bankName={bank}
    />,
  ];
};

export default ExpiredConsent;
