import React from 'react';
import { makeStyles } from '@material-ui/styles';
import SvgFooterBaseline from 'svg/FooterBaseline';
import Container from 'components/Container';
import config from 'config';
import { ButtonLinkStyled } from 'components';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => theme.components.footer);

const Footer = () => {
  const year = new Date().getFullYear();
  const history = useHistory();
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Container>
        <p>
          &copy; {year} • {config.siteTitle} •{' '}
          <ButtonLinkStyled
            onClick={() => history.push('/terms-of-use')}
          >
            Terms of Use
          </ButtonLinkStyled>{' '}
          •{' '}
          <ButtonLinkStyled
            onClick={() => history.push('/privacy-policy')}
          >
            Privacy Policy
          </ButtonLinkStyled>
        </p>
      </Container>
      <SvgFooterBaseline height={5} className={classes.svgFooterBaseline} />
    </footer>
  );
};

export default Footer;
