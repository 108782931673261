export default {
  appMobile: {
    container: {},
    page: {},
    root: {
      display: 'grid',
      gridTemplateColumns: '100%',
      gridTemplateRows: `60px 1fr 60px`,
      gridTemplateAreas: '"header" "main" "footer"',
      minHeight: '100vh',
      height: '100%',
      width: '100vw',
    },
    header: {
      gridArea: 'header',
    },
    main: {
      gridArea: 'main',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      minHeight: 'calc(1fr - 120px)',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-start',
      gridArea: 'footer',
      textAlign: 'center',
      backgroundColor: 'white',
    },
  },
  appDesktop: {
    root: {
      display: 'grid',
      gridTemplateColumns: '100%',
      gridTemplateRows: `120px 1fr auto`,
      gridTemplateAreas: '"header" "main" "footer"',
      height: '100vh',
      width: '100vw',
    },
    header: {
      gridArea: 'header',
    },
    main: {
      gridArea: 'main',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
    },
    footer: {
      backgroundColor: 'white',
      gridArea: 'footer',
      textAlign: 'center',
    },
  },
};
