import { useEffect, useState } from 'react';
import { apiCallWrapper, get } from '../../utils/fetch';
import appConfig from '../../../config';

const useGetBankProviders = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    apiCallWrapper({
      apiCall: () => get(`${appConfig.endpoints.common.providers}`),
      onFinish: resp => setData((resp && resp.providers) || null),
      onFail: e => setError(e),
    });
  }, []);

  return [data, error];
};

export default useGetBankProviders;
