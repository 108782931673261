import React, { useEffect } from 'react';
import LayoutsPage from 'layouts/Page';
import { useHistory, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import queryString from 'query-string';
import { apiCallWrapper, get } from '@src/utils/fetch';
import { toast } from 'react-toastify';
import appConfig from 'config';
import Container from 'components/Container';
import { useSelector } from 'react-redux';
import { selectUserEmail } from '../../../../store/selectors/profile';
import SvgConsentRedirectFromBank from '../../../../svg/ConsentRedirectFromBank';
import _ from 'lodash';
import { SvgRedirectBankLogoWrapper } from '../../../../components';
import SvgConsentRedirectFromBankWithCustomLogo from '../../../../svg/ConsentRedirectFromBankWithCustomLogo';

const ConsentRedirectFromBank = () => {
  const location = useLocation();
  const bankLogo = localStorage.getItem('OBG_bankLogo');
  const consentDetails = JSON.parse(localStorage.getItem('consentDetails'));
  const { customerName = '', bank = '' } = consentDetails || {};

  let parsedLocation;

  parsedLocation = queryString.parse(location.hash);

  if (_.isEmpty(parsedLocation)) {
    parsedLocation = queryString.parse(location.search);
  }

  const { code, state } = parsedLocation;

  const history = useHistory();
  const userEmail = useSelector(selectUserEmail);
  const isLoggedIn = !!userEmail || false;
  const ref = React.useRef(false);

  useEffect(() => {
    if (!ref.current && code && state) {
      apiCallWrapper({
        onStart: () => {
          ref.current = true;
        },
        apiCall: () =>
          get(
            `${appConfig.endpoints.common.consentCallback}?code=${code}&state=${state}`,
          ),
        onFinish: resp => {
          const timeNow = Date.now();
          const lifetime = resp.expires_at || timeNow;
          const consentId = resp.consent_id;
          const redirect_url = resp.redirect_url || '';
          toast.success('Consent successfully confirmed');
          history.push(`/consent/success`, {
            consentId,
            lifetime,
            customerName,
            bank,
            redirect_url
          });
        },
      });
    } else if (!code || !state) {
      console.error('Wrong code or state');
    }
  }, [code, state, history, isLoggedIn, bank, customerName]);

  return (
    <LayoutsPage>
      <Container>
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
          direction={'column'}
          wrap={'nowrap'}
        >
          <Grid item>
            {(bankLogo && (
              <SvgRedirectBankLogoWrapper>
                <img alt="Bank logo" src={bankLogo} />
                <SvgConsentRedirectFromBankWithCustomLogo />
              </SvgRedirectBankLogoWrapper>
            )) || <SvgConsentRedirectFromBank />}
          </Grid>
          <Grid item>
            <Typography
              style={{
                color: '#ccc',
              }}
            >
              Transferring you back to
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'h5'}>TRUSTLOOP</Typography>
          </Grid>
        </Grid>
      </Container>
    </LayoutsPage>
  );
};

export default ConsentRedirectFromBank;
