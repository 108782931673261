export default {
  modal: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    width: '500px',
    maxWidth: '100%',
    backgroundColor: '#fcfcfc',
    outline: 'none',
    padding: '0',

    '& .modalHeader': {
      width: '100%',
      padding: '3rem 2rem 3rem',
    },
    '& .modalBody': {
      padding: '0 2rem 3rem',
      '& ul': {
        margin: '1rem',
        padding: 0,
        '& li': {
          paddingBottom: '.5rem',
        },
      },
    },

    '& .closeBtn': {
      cursor: 'pointer',
      float: 'right',
      margin: '1rem',
      width: '20px',
      height: '20px',
    },
  },
};
