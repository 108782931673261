import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import config from 'config';

const PublicRoute = ({ component: Component, isAuthSuccess, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthSuccess === false ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: config.defaultRedirectPrivateRoute,
          }}
        />
      )
    }
  />
);

export default PublicRoute;
