import React from 'react';
import { Typography } from '@material-ui/core';
import styles from './styles';
import Grid from '@material-ui/core/Grid';

const Revoked = () => (
  <Grid container justifyContent={'center'} alignItems={'center'} spacing={1}>
    <Grid item xs={12}>
      <Typography style={{ fontSize: '1.1rem' }}>
        Access to Bank (CURRENT ACCOUNT) requires renewal
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography>
        Please re-authenticate with your account provider for us to continue
        accessing the information you previously consented to.
        <br />
        Check details in our <span style={styles.primary}>Terms</span> of use
        and <span style={styles.primary}>Privacy Policy</span>
      </Typography>
    </Grid>
  </Grid>
);

export default Revoked;
