import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileForgotPassword } from 'store/actions/profile';
import LayoutsPageInitAuthForm from 'layouts/PageInitAuthForm';
import { Grid, Typography } from '@material-ui/core';
import { selectIsProcessing } from '../../../../store/selectors/profile';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const formFieldsMarkup = [
  {
    type: 'email',
    label: 'Email',
  },
];

const title = 'Forgot Password?';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const isProcessing = useSelector(selectIsProcessing);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleOnSubmit = useCallback(
    async values => {
      const token = await executeRecaptcha('forgot_password');
      dispatch(setProfileForgotPassword({values, token}));
    },
    [dispatch, executeRecaptcha],
  );

  const LayoutsPageInitAuthFormParams = {
    pageTitle: title,
    textAboveTheForm: (
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <Typography variant={'h2'}>{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'subtitle2'}>
            Enter the email address you used when you joined and we’ll send you
            instructions to reset your password.
          </Typography>
          <Typography variant={'subtitle2'}>&nbsp;</Typography>
          <Typography variant={'subtitle2'}>
            For security reasons, we do NOT store your password. So rest assured
            that we will never send your password via email.
          </Typography>
        </Grid>
      </Grid>
    ),
    formParams: {
      loading: isProcessing,
      fieldsMarkup: formFieldsMarkup,
      handleOnSubmit,
      submitBtnLabel: 'Send reset instructions',
    },
  };

  return <LayoutsPageInitAuthForm {...LayoutsPageInitAuthFormParams} />;
};

export default ForgotPassword;
