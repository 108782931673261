import { createTheme } from '@material-ui/core/styles';
import typography from './typography';
import overrides from './overrides';
import palette from './palette';
import components from './components';
import layouts from './layouts';
import layoutsByPageType from './layouts_byPageType';
import defaults from './defaults';
const shape = {
  borderRadius: 0,
};

export default createTheme({
  typography,
  overrides,
  shape,
  palette,
  layouts,
  defaults,
  layoutsByPageType,
  components,
});
