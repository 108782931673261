import React, { useCallback } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import ArrowLeft from '../../svg/ArrowLeft';

const RenderContentRound = styled.div`
  .svgIcon {
    background-color: #5abdd7;
    border-radius: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1px;
  }

  svg path {
    fill: white;
  }
`;

const ButtonBack = ({
  className,
  variant,
  handleOnGoBack,
  goBackLink,
  history,
}) => {
  const handleOnClickBack = useCallback(() => {
    if (handleOnGoBack) {
      return handleOnGoBack();
    }

    if (history) {
      if (goBackLink) {
        return history.push(goBackLink);
      }

      history.goBack();
    } else {
      console.warn('ButtonBack: history object not defined');
    }
  }, [history, handleOnGoBack, goBackLink]);

  let content = (
    <>
      <ArrowLeft className={'-pr1'} />
      Back
    </>
  );

  if (variant === 'round') {
    content = (
      <RenderContentRound>
        <ArrowLeft className={'svgIcon'} />
      </RenderContentRound>
    );
  }

  return (
    <button onClick={handleOnClickBack} className={clsx('backBtn', className)}>
      {content}
    </button>
  );
};

const ButtonBackStyled = styled(ButtonBack)`
  padding: 0 !important;
  margin: 0 !important;
  width: min-content !important;
  min-width: min-content !important;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export default ButtonBackStyled;
