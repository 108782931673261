import React from 'react';
import { Typography } from '@material-ui/core';
import styles from '../styles';

const ExpiredConsentPart2 = ({ consentValidTill }) => (
  <Typography>
    <strong>TrustLoop</strong> will have access to your bank data until:{' '}
    <strong>{consentValidTill}</strong>. You can revoke your consent at any
    time.
    <br />
    Check details in our <span style={styles.primary}>Terms</span> of use and{' '}
    <span style={styles.primary}>Privacy Policy</span>
  </Typography>
);

export default ExpiredConsentPart2;
