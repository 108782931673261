import SignIn from 'containers/psu/Profile/SignIn';
import SignUp from 'containers/psu/Profile/SignUp';
import LogOut from 'containers/psu/Profile/LogOut';
import Validation from 'containers/psu/Profile/Validation';
import ResetPassword from 'containers/psu/Profile/ResetPassword';
import ForgotPassword from 'containers/psu/Profile/ForgotPassword';
import ConsentLog from 'containers/psu/Dashboard/ConsentsList';
import Consent from '@src/containers/Consent';
import ConsentRedirectFromBank from 'containers/psu/Dashboard/ConsentRedirectFromBank';
import ConsentConfirmSuccess from 'containers/psu/Dashboard/ConsentConfirmSuccess';
import ConsentConfirmRejected from 'containers/psu/Dashboard/ConsentConfirmRejected';
import ConsentRedirectToBank from 'containers/psu/Dashboard/ConsentRedirectToBank';
import ActionLog from 'containers/psu/Dashboard/ActionLog';
import Account from 'containers/psu/Dashboard/Account';
import DataSharingRequest from '@src/containers/Dashboard/DataSharingRequest';
import PublicTermsOfUse from 'containers/public/TermsOfUse';
import PublicPrivacyPolicy from 'containers/public/PrivacyPolicy';

export const routes = [
  {
    component: SignUp,
    title: 'Sign Up',
    path: '/finish_registration',
    isExact: true,
    type: 'public',
    pageType: 'authForm',
  },
  {
    component: SignIn,
    title: 'Sign In',
    path: '/sign_in',
    isExact: true,
    type: 'public',
    pageType: 'authForm',
  },
  {
    component: Validation,
    title: 'Validation',
    path: '/validation',
    isExact: true,
    pageType: 'authForm',
    type: 'public',
  },
  {
    component: ForgotPassword,
    title: 'Forgot Password?',
    path: '/forgot_password',
    pageType: 'authForm',
    isExact: true,
    type: 'public',
  },
  {
    component: ResetPassword,
    title: 'Reset Password',
    path: '/set_password',
    pageType: 'authForm',
    isExact: true,
    type: 'public',
  },
  {
    component: ConsentLog,
    title: 'Dashboard',
    path: '/dashboard',
    isExact: true,
    type: 'private',
    pageType: 'dashboard',
  },
  {
    component: ActionLog,
    title: 'Dashboard',
    path: '/action_log',
    isExact: true,
    type: 'private',
    pageType: 'dashboard',
  },
  {
    component: Account,
    title: 'Account',
    path: '/account',
    isExact: true,
    pageType: 'dashboard',
    type: 'private',
  },
  {
    component: ConsentConfirmSuccess,
    title: 'Confirm Consent',
    path: '/consent/success',
    isExact: true,
    pageType: 'dashboard',
    type: 'common',
  },
  {
    component: DataSharingRequest,
    title: 'Confirm Consent',
    path: '/consent/data-sharing-request',
    isExact: true,
    pageType: 'dashboard',
    type: 'common',
  },
  {
    component: ConsentConfirmRejected,
    title: 'Confirm Consent',
    path: '/consent/rejected',
    isExact: true,
    pageType: 'dashboardCenter',
    type: 'common',
  },
  {
    component: ConsentRedirectFromBank,
    title: 'Confirm Consent',
    path: '/consent/accepted',
    isExact: true,
    pageType: 'dashboardCenter',
    type: 'common',
  },
  {
    component: ConsentRedirectToBank,
    title: 'Confirm Consent',
    path: '/consent/processing',
    isExact: true,
    pageType: 'dashboardCenter',
    type: 'common',
  },
  {
    component: Consent,
    title: 'Confirm Request',
    path: '/consent',
    isExact: true,
    pageType: 'dashboard',
    type: 'common',
  },
  {
    component: Consent,
    title: 'Confirm Consent',
    path: '/consent/confirm',
    isExact: true,
    pageType: 'dashboard',
    type: 'common',
  },
  {
    component: LogOut,
    title: 'Log Out',
    path: '/log_out',
    isExact: true,
    pageType: 'common',
  },
  {
    component: PublicPrivacyPolicy,
    title: 'Privacy Policy',
    path: '/privacy-policy',
    isExact: true,
    pageType: 'common',
  },
  {
    component: PublicTermsOfUse,
    title: 'Terms of Use',
    path: '/terms-of-use',
    isExact: true,
    pageType: 'common',
  },
];

export default routes;
