import styled, { css } from 'styled-components';

const Strong = styled.span(({ color: colorProp, theme }) => {
  const color = theme.colors[colorProp] || 'black';

  return css`
    color: ${color};
    font-weight: bold;
  `;
});

export default Strong;
