import {
  cellRender,
  cellRenderDate,
  renderTHeaderTitle,
} from 'components/Table/utils';

const columns = [
  {
    Header: renderTHeaderTitle({
      title: 'Date',
      sort: true,
    }),
    accessor: 'date',
    Cell: cellRenderDate({ title: 'date' }),
  },
  {
    Header: renderTHeaderTitle({
      title: 'Amount',
      sort: true,
    }),
    minWidth: 120,
    sortable: true,
    accessor: 'amount',
    Cell: cellRender({ title: 'amount' }),
  },
  {
    Header: renderTHeaderTitle({
      title: 'Type',
      sort: false,
    }),
    sortable: false,
    accessor: 'type',
    Cell: cellRender({ title: 'type' }),
  },
  {
    Header: renderTHeaderTitle({
      title: 'Balance',
      sort: false,
    }),
    sortable: false,
    accessor: 'balance',
    Cell: cellRender({ title: 'balance' }),
  },
];

export default columns;
