import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import ModalModal from '../../../../components/Modal/Modal';
import LayoutsPage from '../../../../layouts/Page';
import Button from '../../../../components/Button';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { apiCallWrapper, del } from '@src/utils/fetch';
import appConfig from '../../../../config';
import { setProfileLogOut } from '../../../../store/actions/profile';
import { toast } from 'react-toastify';
import AutoRenewIcon from '../../../../components/AutoRenewIcon';
import ButtonBack from '../../../../components/ButtonBack';
import Checkbox from '@material-ui/core/Checkbox';

const ReportRows = styled.div`
  margin: 0 0 1rem;
  max-width: 500px;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  grid-auto-rows: min-content;

  .row-title {
    align-items: center;
    display: grid;
    width: 100%;
    height: min-content;
    justify-content: space-between;
    grid-auto-flow: column;
    grid-auto-columns: max-content max-content;
  }

  .reportRowsInnerWrapper {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-gap: 0.5rem;
  }

  .reportSingleRow {
    border-bottom: 1px dotted #b8b4b2;
    height: min-content;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    grid-auto-rows: 100%;
    grid-auto-columns: min-content;
    .label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      white-space: nowrap;
      color: #b8b4b2;
      background-color: white;
      position: relative;
      top: 1px;
    }
    .value {
      background-color: white;
      white-space: nowrap;
      position: relative;
      top: 1px;
    }
    .capitalized {
      text-transform: capitalize;
    }
  }
`;

const ModalDeleteConfirmBody = styled.div`
  display: grid;
  grid-auto-flow: row;
  text-align: center;
`;

const DialogButtonWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  margin: 3rem 0;
`;

const RowTitle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  align-items: center;
  grid-gap: 1rem;
  word-break: keep-all;
  width: 100%;

  .title {
    padding: 0;
  }
  padding-bottom: 3rem;
`;

const Account = ({ className }) => {
  const history = useHistory();
  const userData = useSelector(state => state.profile.userData);
  const {
    id,
    firstName,
    lastName,
    email,
    phone,
    business,
    company_name,
    company_number,
    postcode,
    salutation,
    county,
    town,
    address_line_1,
    address_line_2,
  } = userData;
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleOnAccountDelete = () => {
    const url = appConfig.endpoints.deleteAccount;
    apiCallWrapper({
      dispatch,
      onStart: () => {
        dispatch({ type: 'ACCOUNT_DELETE_START', id });
        setIsProcessing(true);
      },
      apiCall: () => del(url, { id }),
      onFinish: () => {
        setIsProcessing(false);
        toast.success('Account deleted');
      },
      onFail: () => {
        setIsProcessing(false);
      },
      onAny: () => {
        dispatch(setProfileLogOut());
        setTimeout(() => history.push('/'), 1000);
      },
      notify: true,
    });
  };

  const onDeleteConfirmClose = () => {
    setIsDeleteConfirmOpen(false);
  };

  const onDeleteConfirmOpen = () => {
    setIsDeleteConfirmOpen(true);
  };

  return (
    <div className={className}>
      <LayoutsPage>
        <RowTitle>
          <ButtonBack
            handleOnGoBack={() => {
              history.push('/');
            }}
            variant={'round'}
          />
          <Typography className={'title'} variant={'h4'}>
            Account Settings
          </Typography>
        </RowTitle>
        <ReportRows>
          <div className={'reportSingleRow'}>
            <div className={'label'}>Salutation</div>
            <div className={'value capitalized'}>{salutation}</div>
          </div>
          <div className={'reportSingleRow'}>
            <div className={'label'}>First Name</div>
            <div className={'value'}>{firstName}</div>
          </div>
          <div className={'reportSingleRow'}>
            <div className={'label'}>Last Name</div>
            <div className={'value'}>{lastName}</div>
          </div>
          <div className={'reportSingleRow'}>
            <div className={'label'}>Email</div>
            <div className={'value'}>{email}</div>
          </div>
          <div className={'reportSingleRow'}>
            <div className={'label'}>Phone</div>
            <div className={'value'}>{phone}</div>
          </div>
          <div className={'reportSingleRow'}>
            <div className={'label'}>Postcode</div>
            <div className={'value'}>{postcode}</div>
          </div>
          <div className={'reportSingleRow'}>
            <div className={'label'}>County</div>
            <div className={'value'}>{county}</div>
          </div>
          <div className={'reportSingleRow'}>
            <div className={'label'}>Town</div>
            <div className={'value'}>{town}</div>
          </div>
          <div className={'reportSingleRow'}>
            <div className={'label'}>Address Line 1</div>
            <div className={'value'}>{address_line_1}</div>
          </div>
          <div className={'reportSingleRow'}>
            <div className={'label'}>Address Line 2</div>
            <div className={'value'}>{address_line_2}</div>
          </div>
          <div className={'reportSingleRow'}>
            <div className={'label'}>Business</div>
            <div className={'value'}>
              <Checkbox
                style={{ margin: 0, padding: 0 }}
                color={'primary'}
                checked={!!business}
              />
            </div>
          </div>
          {business && (
            <>
              <div className={'reportSingleRow'}>
                <div className={'label'}>Company Name</div>
                <div className={'value'}>{company_name}</div>
              </div>
              <div className={'reportSingleRow'}>
                <div className={'label'}>Company Number</div>
                <div className={'value'}>{company_number}</div>
              </div>
            </>
          )}
        </ReportRows>

        <div className={'deleteButtonWrapper'}>
          <Button
            wide
            onClick={() => dispatch(setProfileLogOut())}
            color={'default'}
          >
            Log out
          </Button>
          <Button
            wide
            onClick={onDeleteConfirmOpen}
            variant={'contained'}
            color={'secondary'}
            customColor={'low'}
          >
            Delete Account
          </Button>
        </div>
        <ModalModal open={isDeleteConfirmOpen} onClose={onDeleteConfirmClose}>
          <ModalDeleteConfirmBody>
            <Typography variant={'h3'}>
              Your account will be permanently deleted. Are you sure?
            </Typography>
            <DialogButtonWrapper>
              <Button
                wide
                customColor={'warning'}
                variant={'contained'}
                disabled={isProcessing}
                color="secondary"
                onClick={handleOnAccountDelete}
              >
                {isProcessing && <AutoRenewIcon />} Delete
              </Button>
              <Button wide color="default" onClick={onDeleteConfirmClose}>
                Cancel
              </Button>
            </DialogButtonWrapper>
          </ModalDeleteConfirmBody>
        </ModalModal>
      </LayoutsPage>
    </div>
  );
};

const AccountStyled = styled(Account)`
  width: 100%;
  .title {
    word-break: keep-all;
    width: 100%;
    white-space: nowrap;
    //padding-bottom: 3rem;
  }

  .deleteButtonWrapper {
    padding: 3rem 0;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem;
  }
`;

export default AccountStyled;
