import styled from 'styled-components';

const LinkHref = styled.a`
  &,
  &:visited,
  &:focus {
    color: ${({ color, active, theme }) => {
      if (active) {
        return theme.colors.primary;
      }

      if (color) {
        return theme.colors[color];
      }

      return theme.colors.primary;
    }};
    text-decoration: none;
    cursor: pointer;
    transition: all 300ms;
    font-weight: bold;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export default LinkHref;
