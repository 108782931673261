import React from 'react';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
    width: '100%'
  },
  radioGroup: {
    marginTop: '0.5rem'
  },
  formControlLabel: {
    flexGrow: '2'
  }
}));

const InputJointAccount = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Field
        name='is_joint'
        type='radio'
        validate={value => !value }
      >
        {({ input, meta }) => (
          <FormControl 
            {...input}
            className={classes.formControl}
            error={meta.touched && meta.error}
          >
            <FormLabel>Choose the type of your account</FormLabel>
            <RadioGroup 
              row
              aria-label='is_joint' 
              name='is_joint'
              className={classes.radioGroup}
            >
              <FormControlLabel
                className={classes.formControlLabel}
                value='false'
                control={<Radio color='primary' />}
                label='Single account'
              />
              <FormControlLabel
                className={classes.formControlLabel}
                value='true'
                control={<Radio color='primary' />}
                label='Joint account'
              />
            </RadioGroup>
            {
              meta.touched && meta.error &&
                <FormHelperText>Required</FormHelperText>
            }
          </FormControl>
        )}
      </Field>
    </Grid>
  );
};

export default InputJointAccount;
