import React from 'react';
import Box from '@material-ui/core/Box';
import { LayoutTwoItemSpaceBetweenDotted } from '../../../../../components';
import Typography from '@material-ui/core/Typography';

const ExpiredConsent = ({ customerName }) => {
  return (
    <Box py={2}>
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Requested by'}
        rightItem={customerName}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Reason of request'}
        rightItem={'BankLoop'}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Bank'}
        rightItem={'ForgeRock'}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Bank account(s)'}
        rightItem={'Current account'}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Status'}
        rightItem={
          <div>
            <Typography style={{ color: '#b6b6b6', fontWeight: '800' }}>
              EXPIRED
            </Typography>
          </div>
        }
      />
    </Box>
  );
};

export default ExpiredConsent;
