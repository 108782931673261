import React from 'react';

import './style.css';

const ConsentRedirectToBank = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="214"
        height="51"
        viewBox="0 0 214 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.281 16.1986C38.7078 16.1986 35.8007 13.2915 35.8007 9.71833C35.8007 7.71508 36.7153 5.92161 38.1479 4.73191C34.5165 2.47908 30.2687 1.12913 25.7206 1V10.6993C33.5953 11.0652 39.9353 17.4052 40.3017 25.2804H50C49.8857 21.257 48.8123 17.4715 47.0076 14.1372C45.824 15.4019 44.1459 16.1986 42.281 16.1986Z"
          fill="#FAB833"
        />
        <path
          d="M24.2804 10.6983V1C19.7318 1.12862 15.484 2.47857 11.8526 4.73191C13.2852 5.92161 14.1998 7.71457 14.1998 9.71833C14.1998 13.2915 11.2927 16.1981 7.71952 16.1981C5.85458 16.1981 4.17594 15.4019 2.99288 14.1367C1.18817 17.4715 0.114325 21.2565 0 25.2799H9.69878C10.0647 17.4047 16.4052 11.0642 24.2804 10.6983Z"
          fill="#5BBDD7"
        />
        <path
          d="M9.69875 26.7202H0.000488281C0.114814 30.7441 1.18814 34.5296 2.99285 37.8629C4.17642 36.5981 5.85456 35.8014 7.71949 35.8014C11.2927 35.8014 14.1998 38.7086 14.1998 42.2817C14.1998 44.285 13.2852 46.0785 11.8531 47.2687C15.4839 49.5215 19.7318 50.8709 24.2798 51.0001V41.3013C16.4052 40.9359 10.0647 34.5954 9.69875 26.7202Z"
          fill="#ED6C77"
        />
        <path
          d="M35.8007 42.2817C35.8007 38.7085 38.7078 35.8019 42.281 35.8019C44.1459 35.8019 45.8241 36.5981 47.0076 37.8633C48.8124 34.529 49.8857 30.7435 50 26.7207H40.3012C39.9353 34.5954 33.5948 40.9358 25.7202 41.3013V50.9995C30.2682 50.8704 34.5161 49.5215 38.1474 47.2681C36.7153 46.0784 35.8007 44.285 35.8007 42.2817Z"
          fill="#B8B4B3"
        />
        <path
          d="M37.2405 9.71841C37.2405 12.4974 39.5015 14.7584 42.2805 14.7584C45.06 14.7584 47.321 12.4974 47.321 9.71841C47.321 6.93939 45.06 4.67841 42.2805 4.67841C39.5015 4.67841 37.2405 6.93939 37.2405 9.71841Z"
          fill="#FAB833"
        />
        <path
          d="M42.281 37.2417C39.502 37.2417 37.241 39.5032 37.241 42.2817C37.241 45.0607 39.502 47.3217 42.281 47.3217C45.06 47.3217 47.321 45.0607 47.321 42.2817C47.321 39.5032 45.06 37.2417 42.281 37.2417Z"
          fill="#B8B4B3"
        />
        <path
          d="M7.71952 14.7589C10.4985 14.7589 12.7595 12.498 12.7595 9.71893C12.7595 6.93939 10.4985 4.67841 7.71952 4.67841C4.94049 4.67841 2.6795 6.93939 2.6795 9.71893C2.6795 12.4974 4.94049 14.7589 7.71952 14.7589Z"
          fill="#5BBDD7"
        />
        <path
          d="M7.71952 47.3217C10.4985 47.3217 12.7595 45.0607 12.7595 42.2817C12.7595 39.5032 10.4985 37.2417 7.71952 37.2417C4.94049 37.2417 2.6795 39.5032 2.6795 42.2817C2.6795 45.0613 4.94049 47.3217 7.71952 47.3217Z"
          fill="#ED6C77"
        />
        <path
          className={'el1'}
          d="M116 18L124 25L116 32L116 18Z"
          fill="#5ABDD7"
        />
        <path
          className={'el2'}
          d="M103 18L111 25L103 32L103 18Z"
          fill="#5ABDD7"
        />
        <path
          className={'el3'}
          d="M90.0001 18L98.0001 25L90.0001 32L90.0001 18Z"
          fill="#5ABDD7"
        />
        <circle cx="189" cy="25" r="25" fill="#3C3C3B" />
        <path
          d="M179 13V37H183.5V35.5H195.5V37H200V13H179ZM198.5 28H197V20.5H198.5V28ZM198.5 17.5H197V16H182V32.5H197V31H198.5V34H180.5V14.5H198.5V17.5ZM190.25 24.25C190.25 25.9 188.9 27.25 187.25 27.25C185.6 27.25 184.25 25.9 184.25 24.25C184.25 22.6 185.6 21.25 187.25 21.25C188.9 21.25 190.25 22.6 190.25 24.25Z"
          fill="white"
        />
        <path
          d="M188.75 24.25C188.75 24.6478 188.592 25.0294 188.311 25.3107C188.029 25.592 187.648 25.75 187.25 25.75C186.852 25.75 186.471 25.592 186.189 25.3107C185.908 25.0294 185.75 24.6478 185.75 24.25C185.75 23.8522 185.908 23.4706 186.189 23.1893C186.471 22.908 186.852 22.75 187.25 22.75C187.648 22.75 188.029 22.908 188.311 23.1893C188.592 23.4706 188.75 23.8522 188.75 24.25Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ConsentRedirectToBank;
