export const actionTypes = {
  SET_PROFILE_IS_PROCESSING: 'SET_PROFILE_IS_PROCESSING',
  SET_PROFILE_IS_PROCESSING_TRUE: 'SET_PROFILE_IS_PROCESSING_TRUE',
  SET_PROFILE_IS_PROCESSING_FALSE: 'SET_PROFILE_IS_PROCESSING_FALSE',
  SET_PROFILE_SIGN_IN: 'SET_PROFILE_SIGN_IN',
  SET_PROFILE_SIGN_UP: 'SET_PROFILE_SIGN_UP',
  SET_PROFILE_AUTO_SIGN_IN: 'SET_PROFILE_AUTO_SIGN_IN',
  SET_PROFILE_USER_DATA: 'SET_PROFILE_USER_DATA',
  SET_PROFILE_LOG_OUT: 'SET_PROFILE_LOG_OUT',
  SET_PROFILE_VALIDATION_CODE_FROM_SMS: 'SET_PROFILE_VALIDATION_CODE_FROM_SMS',
  SET_PROFILE_FORGOT_PASSWORD: 'SET_PROFILE_FORGOT_PASSWORD',
  SET_PROFILE_RESET_PASSWORD: 'SET_PROFILE_RESET_PASSWORD',
};

export const setProfileValidationCodeFromSms = data => {
  return {
    type: actionTypes.SET_PROFILE_VALIDATION_CODE_FROM_SMS,
    data,
  };
};

export const setProfileAutoSignIn = data => {
  return {
    type: actionTypes.SET_PROFILE_AUTO_SIGN_IN,
    data,
  };
};

export const setProfileSignIn = data => {
  return {
    type: actionTypes.SET_PROFILE_SIGN_IN,
    data,
  };
};

export const setProfileForgotPassword = data => {
  return {
    type: actionTypes.SET_PROFILE_FORGOT_PASSWORD,
    data,
  };
};

export const setProfileResetPassword = data => {
  return {
    type: actionTypes.SET_PROFILE_RESET_PASSWORD,
    data,
  };
};

export const setProfileSignUp = data => {
  return {
    type: actionTypes.SET_PROFILE_SIGN_UP,
    data,
  };
};

export const setProfileUserData = data => {
  return {
    type: actionTypes.SET_PROFILE_USER_DATA,
    data: data,
  };
};

export const setProfileLogOut = () => {
  return {
    type: actionTypes.SET_PROFILE_LOG_OUT,
  };
};
