import React from 'react';
import Button from '@material-ui/core/Button';
import UserMenuLogo from 'svg/UserMenuLogo';
import { useHistory } from 'react-router-dom';

export default function CustomizedMenus() {
  const history = useHistory();

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        style={{
          padding: 0,
          minWidth: 'auto',
          backgroundColor: 'white',
        }}
        onClick={() => {
          history.push('/account');
        }}
      >
        <UserMenuLogo />
      </Button>
    </div>
  );
}
