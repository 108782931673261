import React from 'react';
import { Typography } from '@material-ui/core';

const Pending = ({ consentValidTill }) => (
  <Typography>
    <strong>TrustLoop</strong> will have access to your bank data until:{' '}
    <strong>{consentValidTill}</strong>. You can revoke your consent at any
    time.
  </Typography>
);

export default Pending;
