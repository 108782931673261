export default theme => ({
  buttonsGroupWrapper: {
    marginTop: '2rem',
  },
  submitButton_spinAnim: {
    animationName: 'spin',
    animationDuration: '1.2s',
    animationTimingFunction: 'linear',
    animationIteration: 'infinite',
  },
  root: {
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiInput-underline': {
      '&:before': {
        borderBottomColor: '#DDDDDD',
      },
      '&:after': {},
    },
    '& .MuiInputBase-input': {
      paddingBottom: '10px',
    },
    '& .MuiInput-formControl': {},
    '& .MuiInputLabel-root': {
      lineHeight: '15px',
      color: theme.palette.brand.gray,
    },
    '& .MuiButtonBase': {
      '&-root': {
        boxShadow: 'none',
        '& .MuiButton-label': {
          fontWeight: 500,
        },
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
    },
  },
});
