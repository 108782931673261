import React from 'react';
import RoutePrivate from 'components/RoutePrivate';
import RoutePublic from 'components/RoutePublic';
import config from 'config';
import { Route } from 'react-router-dom';

export const getRouteByParam = (
  paramValue,
  param = 'title',
  combinedRoutes = [...config.routes],
  path = undefined,
) => {
  const routerFind = combinedRoutes.find(r => {
    if (r.routes) {
      getRouteByParam(paramValue, r.routes, r.path);
    }
    return r[param] === paramValue;
  });

  if (routerFind) {
    return {
      ...routerFind,
      label: routerFind.title,
      path: [routerFind.path, path].join(''),
    };
  }
};

export const renderRoutes = (routes = config.routes, isAuthSuccess) =>
  routes.map((r, index) => {
    let Component = Route;
    switch (r.type) {
      case 'public':
        Component = RoutePublic;
        break;
      case 'private':
        Component = RoutePrivate;
        break;
      case 'common':
        Component = Route;
        break;
      default:
        Component = Route;
        break;
    }

    return (
      <Component
        key={index}
        {...r}
        exact={r.isExact}
        component={r.component}
        isAuthSuccess={isAuthSuccess}
      />
    );
  });
