import { paletteBrand } from './palette';

export default {
  textField: {
    root: {},
  },
  header: {
    root: {
      '& .MuiPaper-root': {
        position: 'relative',
        height: '100%',
        '& .MuiContainer-root': {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          '& .MuiGrid-container': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
        '& .navLinks': {
          height: '100%',
          alignItems: 'center',
          '& .navLinksWrapper': {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            '& .navLink': {
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              '& .MuiButton-label': {
                fontWeight: 300,
              },
              '&.-selected': {
                position: 'relative',
                '& .MuiButton-label': {
                  fontWeight: 800,
                },
                '&::after': {
                  position: 'absolute',
                  content: '""',
                  left: '50%',
                  right: '0',
                  bottom: '0',
                  transform: 'translate(-50%, 0)',
                  width: '30px',
                  height: '5px',
                  backgroundColor: paletteBrand.trustloop.blue,
                },
              },
            },
            '& .MuiGrid-item': {
              '& .MuiButton-label': {
                fontSize: '.8rem',
              },
              paddingLeft: '1rem',
              paddingRight: '1rem',
            },
          },
        },
      },
    },
    toolbar: {
      height: '100%',
      backgroundColor: 'white',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    gutters: {},
    button: {
      padding: 0,
    },
    logo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  footer: {
    root: {
      flexGrow: 1,
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-end',
      textAlign: 'center',
      color: paletteBrand.trustloop.gray,
      '& a': {
        color: paletteBrand.trustloop.blue,
        textDecoration: 'none',
      },
    },
    svgFooterBaseline: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
  },
  typographyCustom: {},
  form: {
    buttonsGroupWrapper: {
      marginTop: '2rem',
    },
    submitButton_isProcessingAnimationIcon: {
      animationName: 'spin',
      animationDuration: '1.2s',
      animationTimingFunction: 'linear',
      animationIteration: 'infinite',
    },
    root: {
      paddingTop: '20px',
      '& .MuiInput-underline': {
        '&:before': {
          borderBottomColor: '#DDDDDD',
        },
        '&:after': {},
      },
      '& .MuiFormControl-root': {
        margin: '.75rem 0',
      },
      '& .MuiInputBase-input': {
        paddingBottom: '10px',
      },
      '& .MuiInput-formControl': {},
      '& .MuiInputLabel-root': {
        fontSize: '14px',
        lineHeight: '15px',
        color: paletteBrand.trustloop.gray,
      },
      '& .MuiButtonBase': {
        '&-root': {
          boxShadow: 'none',
          '& .MuiButton-label': {
            fontWeight: 'bold',
          },
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'rgb(97,211,238)',
          },
          '&:active': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
};
