export default {
  root: {
    width: '100%',
    height: '100%',
    '& .-primary': {
      color: '#5ABDD7',
      fontWeight: 600,
    },

    '& .form-field.MuiFormControl-root': {
      marginTop: '1rem',
    },
    '& ul, ol': {
      margin: 0,
      padding: '0 0 0 1rem',
      '& li': {
        paddingBottom: '1rem',
      },
    },
  },
};
