import { paletteBrand } from './palette';

export default {
  MuiSvgIcon: {
    colorAction: {
      color: 'white',
    },
  },
  MuiButtonBase: {
    root: {},
  },
  MuiButton: {
    root: {
      width: '100%',
      minHeight: '50px',
      paddingLeft: '2rem',
      paddingRight: '2rem',
      boxShadow: 'none',
    },
    contained: {
      boxShadow: 'none',
      '&$disabled': {
        backgroundColor: '#B8B4B2',
        color: 'white',
      },
    },
    containedPrimary: {
      '&:hover': {
        boxShadow: 'none',
      },
    },
  },
  MuiGrid: {
    container: {
      overflow: 'visible',
    },
  },
  MuiContainer: {
    root: {
      overflow: 'visible',
    },
  },
  MuiInput: {
    underline: {
      '&:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#5ABDD7',
      },
    },
  },
  MuiLink: {
    root: {
      cursor: 'pointer',
    },
  },
  MuiFormLabel: {
    root: {
      '&.Mui-focused': {
        color: `${paletteBrand.trustloop.blue} !important`,
      },
    },
  },
  MuiAppBar: {
    root: {
      boxShadow: '0px 10px 20px rgba(184, 180, 179, 0.15);',
    },
  },
  MuiTypography: {},
  MuiToolbar: {
    root: {},
    gutters: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};
