import React from 'react';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import styles from './styles';

const useStyles = makeStyles(styles);

const ExpansionPanelView = ({
  superWide = false,
  isShared = false,
  isExpanded = false,
}) => {
  const classes = useStyles({ superWide });
  const [rootExpanded, setRootExpanded] = React.useState(isExpanded);

  const handleRootChange = (event, isExpanded) => {
    setRootExpanded(isExpanded);
  };

  return (
    <div className={classes.root}>
      <Accordion
        expanded={rootExpanded}
        className={clsx('superRoot', classes.rootPanel)}
        onChange={handleRootChange}
      >
        <AccordionSummary
          className={clsx('superRoot_summary')}
          expandIcon={rootExpanded ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel-root"
          id="panel-root"
        >
          <Typography color={'primary'}>
            <strong>
              {isShared ? 'Shared Data' : 'Data you will be sharing'}
            </strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className={clsx('superRoot_details', classes.innerPanels)}
        >
          <Box flexDirection={'column'}>
            <Grid
              justifyContent={'center'}
              alignItems={'center'}
              container
              spacing={1}
            >
              <Grid className={clsx('innerExpansionPanel')} item>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.innerPanels_header}>
                      Your Contact Details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="expansionPanedDetails_contactDetails">
                      <ul>
                        <li>The full legal name(s) of account holder(s)</li>
                        <li>
                          Address(es), telephone number(s) and email address(es)
                        </li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid className={clsx('innerExpansionPanel')} item>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography className={classes.innerPanels_header}>
                      Your Account Details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="expansionPanedDetails_accountsDetail">
                      <ul>
                        <li>Your account name, number and sort-code</li>
                        <li>Your account balance</li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid className={clsx('innerExpansionPanel')} item>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography className={classes.innerPanels_header}>
                      Your Regular Payments
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="expansionPanedDetails_regularPayments">
                      <ul>
                        <li>Details of Payee agreements you have set up</li>
                        <li>Details of your Standing Orders</li>
                        <li>Recurring and future dated payments</li>
                        <li>Details of recurring and future dated payments</li>
                        <li>Your Direct Debits</li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid className={clsx('innerExpansionPanel')} item>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography className={classes.innerPanels_header}>
                      Your Account Transactions
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="expansionPanedDetails_accountTransactions">
                      <ul>
                        <li>Details of your incoming transactions</li>
                        <li>Details of your outgoing transactions</li>
                        <li>Details of your transactions</li>
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ExpansionPanelView;
