import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import styles from './styles';
import moment from 'moment';
import Confirmed from './Confirmed';
import Pending from './Pending';
import ExpiredConsentPart1 from './ExpiredConsent/Part_1';
import ExpiredConsentPart2 from './ExpiredConsent/Part_2';
import Revoked from './Revoked';

const useStyles = makeStyles(styles);

const BottomTextBlock = ({ created_at, lifetime, status }) => {
  const classes = useStyles();

  const consentValidTill = (() => {
    if (created_at) {
      return moment(created_at)
        .add(90, 'days')
        .format('Do MMM YYYY');
    }

    if (lifetime) {
      return moment(lifetime * 1000).format('DD MMM YYYY');
    }
  })();

  const RenderComponent = (() => {
    switch (status) {
      case 'confirmed':
      case 'consent-data-received':
      case 'consent-data-shared':
      case 'consent-data-sharing-rejected':
        return Confirmed;
      case 'pending':
        return Pending;
      case 'consent-expired-part-1':
        return ExpiredConsentPart1;
      case 'revoked':
        return Revoked;
      case 'consent-expired-part-2':
        return ExpiredConsentPart2;
      default:
        return Pending;
    }
  })();

  return (
    <Box className={classes.grayTextBlock} p={3} px={5}>
      <RenderComponent consentValidTill={consentValidTill} />
    </Box>
  );
};

export default BottomTextBlock;
