import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/Button';
import RejectSvg from 'svg/Reject';
import { Grid, Typography } from '@material-ui/core';
import LayoutsPage from 'layouts/Page';
import Box from '@material-ui/core/Box';
import { useHistory, useLocation } from 'react-router-dom';
import LayoutPage from '../../../../@src/layouts/Page';
import { ContentWHFullFlexXYCenter } from '../../../../components';
import PreloaderBranding from '../../../../components/PreloaderBranding';
import Container from '../../../../components/Container';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  svg: {
    textAlign: 'center',
  },
}));

function ConsentConfirmRejected() {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();

  if (!state) {
    return (
      <LayoutPage>
        <Container maxWidth={'xs'} isHeightFull>
          <ContentWHFullFlexXYCenter>
            <PreloaderBranding />
          </ContentWHFullFlexXYCenter>
        </Container>
      </LayoutPage>
    );
  }

  return (
    <LayoutsPage isFullHeight>
      <div className={classes.root}>
        <Grid container justifyContent={'center'} alignItems={'center'} spacing={1}>
          <Grid item xs={12}>
            <RejectSvg className={classes.svg} />
          </Grid>
          <Grid item xs={12}>
            <Typography align={'center'} variant={'h4'}>
              Rejected
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align={'center'}>
              You rejected <strong>TrustLoop</strong>’s consent request which
              means neither <strong>TrustLoop</strong> nor{' '}
              <strong>{state.customerName}</strong> will be able to access any
              of your bank data.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box width="100%" pt={10}>
              <Button
                wide
                style={{ flexGrow: '1' }}
                variant={'contained'}
                color={'primary'}
                onClick={() => history.push('/')}
              >
                DONE
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </LayoutsPage>
  );
}

export default ConsentConfirmRejected;
