import Tabs from './Tabs';
import styled from 'styled-components';

const TabsStyled = styled(Tabs)`
  .tabPanel_wrapper {
    position: relative;
    min-height: 300px;
  }
  .tabLabels_wrapper {
    padding: 0;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    width: max-content;
    text-transform: uppercase;

    .tabLabel {
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      transition: all 150ms;
      &:hover {
        color: #5abdd7;
      }
      &.--active {
        background-color: #5abdd7;
        color: white;
      }
    }
  }
`;

export * from './Tabs';
export default TabsStyled;
