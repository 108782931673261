import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Status from '../../Consent/parts/Status';
import Footer from '../../Consent/parts/Footer';
import { useLocation } from 'react-router-dom';
import appConfig from '../../../../config';
import ExpansionPanelsView from '../../Consent/parts/ExpansionPanelView';
import Steps from '../../Consent/parts/Steps';
import Grid from '@material-ui/core/Grid';
import Button from '../../../../components/Button';
import Strong from '../../../components/Strong';
import { apiCallWrapper, post } from '../../../utils/fetch';
import DataSharingConfirmed from '../DataSharingConfirmed';
import DataSharingRejected from '../DataSharingRejected';
import { toast } from 'react-toastify';
import LayoutsPage from '../../../../layouts/Page';

const DataSharingRequest = () => {
  const { state } = useLocation();
  const [isProcessing, setIsProcessing] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const { consentId = '', customerName = '', bank = '' } = state || {};
  const props = {
    customerName,
    consentId,
    bank,
  };

  const handleOnConfirm = () => {
    apiCallWrapper({
      onStart: () => setIsProcessing(true),
      onAny: () => {
        setIsProcessing(false);
      },
      apiCall: () =>
        post(appConfig.endpoints.consentDataApprove, { consent_id: consentId }),
      onFinish: resp => {
        toast.success('Consent data shared successfully');
        if (resp.redirect_url) {
          window.open(resp.redirect_url, '_self')
          return;
        }
        setRedirect('confirmed');
      },
    });
  };

  const handleOnReject = () => {
    apiCallWrapper({
      onStart: () => setIsProcessing(true),
      onAny: () => {
        setIsProcessing(false);
      },
      apiCall: () =>
        post(appConfig.endpoints.consentDataReject, { consent_id: consentId }),
      onFinish: resp => {
        toast.success('Consent data sharing rejected');
        if (resp.redirect_url) {
          window.open(resp.redirect_url, '_self');
          return;
        }
        setRedirect('rejected');
      },
    });
  };

  if (redirect === 'confirmed') return <DataSharingConfirmed {...props} />;
  if (redirect === 'rejected') return <DataSharingRejected {...props} />;

  return (
    <LayoutsPage>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Steps activeStep={4} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1">{'Data sharing request'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Status
            key={'Status'}
            status={'data-sharing-request'}
            customerName={customerName}
            bankName={bank}
          />
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanelsView
            key={'ExpansionPanelsView'}
            superWide
            isExpanded
          />
        </Grid>
        <Grid item xs={12}>
          <Footer key={'Footer'} />
        </Grid>
        <Grid item xs={12}>
          <Grid key={'buttons'} container spacing={2}>
            <Grid item>
              <Typography variant={'body1'}>
                If you choose not to share your data then{' '}
                <Strong>{customerName}</Strong> may not be able to provide you with
                services
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={handleOnConfirm} wide disabled={isProcessing}>
                CONFIRM
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={handleOnReject}
                wide
                disabled={isProcessing}
                variant={'contained'}
                customColor={'warning'}
              >
                REJECT
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LayoutsPage>
  );
};

export default DataSharingRequest;
