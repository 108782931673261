import React from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const Password = ({
  meta,
  input,
  label,
  formValues,
  handlers,
  isAdornment = false,
  inputProps,
  ...rest
}) => {
  return (
    <FormControl {...rest} error={!!(meta.touched && meta.error)}>
      <InputLabel htmlFor={`${label}`}>{label}</InputLabel>
      <Input
        {...input}
        {...inputProps}
        id={`${label}`}
        type={formValues.showPassword ? 'text' : 'password'}
        endAdornment={
          isAdornment && (
            <InputAdornment position="end">
              <IconButton
                aria-readonly={'true'}
                aria-label="toggle password visibility"
                onClick={handlers.handleClickShowPassword}
                onMouseDown={handlers.handleMouseDownPassword}
              >
                {formValues.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }
      />
      {meta.touched && meta.error && (
        <FormHelperText id="email-error">{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Password;
