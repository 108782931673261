import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileSignUp } from 'store/actions/profile';
import { Grid, Link, Typography } from '@material-ui/core';
import LayoutsPageInitAuthForm from 'layouts/PageInitAuthForm';
import ModalModal from 'components/Modal/Modal';
import { rePasswordMatch } from '../../../../utils/validators';
import { Redirect, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { setProfileUserData } from '../../../../store/actions/profile';
import appConfig from '../../../../config';
import { apiCallWrapper, post } from '../../../../@src/utils/fetch';
import PreloaderBranding from '../../../../components/PreloaderBranding';
import { ContentWHFullFlexXYCenter } from '../../../../components';
import { selectIsProcessing } from '../../../../store/selectors/profile';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const title = 'Sign Up';

const SignUp = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const location = useLocation();
  const isProcessing = useSelector(selectIsProcessing);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { code } = queryString.parse(location.search);

  React.useEffect(() => {
    if (code) {
      const signUpWithCaptcha = async () => {
        const token = await executeRecaptcha('sign_up');

        apiCallWrapper({
          apiCall: () => post(appConfig.endpoints.finishRegistration, { code }),
          onFinish: resp => {
            setUserInfo(resp.psu);
            setIsLoading(false);
          },
          onFail: () => {
            setRedirect('/sign_in');
          },
          notify: true,
          recaptchaToken: token
        })
      }
      signUpWithCaptcha();
    } else {
      setIsLoading(false);
    }
  }, [code, executeRecaptcha]);

  const formFieldsMarkup = [
    {
      name: 'firstName',
      type: 'text',
      label: 'Fist Name',
      validationGroup: 'name',
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Last Name',
      validationGroup: 'name',
    },
    {
      name: 'business',
      type: 'checkbox',
      label: 'Is Business',
      hidden: true,
    },
    {
      displayIfCheckboxTrue: 'business',
      name: 'company_name',
      type: 'text',
      label: 'Company Name',
      validationGroup: 'company_name',
    },
    {
      displayIfCheckboxTrue: 'business',
      name: 'company_number',
      type: 'text',
      label: 'Company Number',
      validationGroup: 'company_number',
    },
    {
      name: 'phone',
      type: 'email',
      label: 'Email',
    },
    {
      name: 'phone',
      type: 'phone',
      label: 'Phone',
    },
    {
      type: 'password_re',
      label: 'Password',
    },
    {
      name: 'confirm_by',
      type: 'select',
      label: 'Choose validation type',
      initValue: [
        {
          label: 'SMS',
          value: 'sms',
        },
        {
          label: 'Email',
          value: 'email',
        },
      ],
    },
  ];

  const handleOnSubmit = useCallback(
    values => {
      if (code) {
        return apiCallWrapper({
          apiCall: () =>
            post(appConfig.endpoints.updateUserInfoByOtp, {
              ...userInfo,
              registration_code: code,
              ...values,
            }),
          onFinish: resp => {
            dispatch(setProfileUserData(resp.psu));
            setRedirect('/validation');
          },
          notify: true,
        });
      }

      dispatch(setProfileSignUp(values));
    },
    [dispatch, code, userInfo],
  );

  const LayoutsPageInitAuthFormParams = {
    pageTitle: title,
    formParams: {
      loading: isProcessing,
      initValues: userInfo,
      validate: rePasswordMatch,
      fieldsMarkup: formFieldsMarkup,
      handleOnSubmit,
    },
    textAboveTheForm: (
      <Grid container justify={'space-between'}>
        <Grid item>
          <Typography variant={'h2'}>{title}</Typography>
        </Grid>
        <Grid item>
          <Link variant={'subtitle1'} onClick={() => setIsModalOpen(true)}>
            Why?
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'subtitle2'}>All fields are required</Typography>
        </Grid>
      </Grid>
    ),
    textUnderTheForm: (
      <Grid item xs={12} align={'center'}>
        <Typography variant={'subtitle2'} style={{ color: '#B8B4B2' }}>
          By creating an account you are agreeing with{' '}
          <Link href={'/terms-of-use'} variant={'subtitle2'}>
            Terms of use
          </Link>{' '}
          and{' '}
          <Link href={'/privacy-policy'} variant={'subtitle2'}>
            Privacy Policy
          </Link>
        </Typography>
        <Typography
          style={{ textAlign: 'justify', paddingTop: '1rem', color: '#B8B4B2' }}
          variant="subtitle2"
        >
          TrustLoop is a trading style of Open B Gateway Limited, regulated by
          the UK Financial Conduct Authority under the Payment Services
          Regulations 2017 as an Authorised Payment Institution to provide
          account information services (Firm Reference Number: xxxxxx).
        </Typography>
      </Grid>
    ),
  };

  const modalBody = (
    <Typography>
      By creating a TrustLoop account you will be able to:
      <ul>
        <li>see and manage your consents</li>
        <li>see the bank data shared with your Customer</li>
        <li>get notifications via email if the consent is expiring</li>
        <li>see your complete account history</li>
      </ul>
    </Typography>
  );

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return (
      <ContentWHFullFlexXYCenter>
        <PreloaderBranding />
      </ContentWHFullFlexXYCenter>
    );
  }

  return (
    <>
      <LayoutsPageInitAuthForm {...LayoutsPageInitAuthFormParams} />
      <ModalModal
        header={'Sign up benefits'}
        body={modalBody}
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default SignUp;
