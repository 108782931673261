import moment from 'moment';

export const mapperPsuConsentAccount = (values, id) => ({
  consent_id: id,
  acc_data: {
    salutation: values.salutation,
    first_name: values.first_name,
    last_name: values.last_name,
    postcode: values.postcode,
    address_line_1: values.address_line_1,
    address_line_2: values.address_line_2,
    town: values.town,
    county: values.county,
    birth_date: moment(values.birth_date,"DD-MMM-YYYY").format('YYYY-MM-DD'),
    phone_number: values.phone_number,
    company_name: values.company_name,
    company_number: values.company_number,
  },
});

export const mapperPsuPreSignUp = (values, psu_email) => ({
  email: psu_email,
  firstName: values.first_name,
  lastName: values.last_name,
  company_name: values.company_name,
  company_number: values.company_number,
  phone: values.phone,
  salutation: values.salutation,
  postcode: values.postcode,
  address_line_1: values.address_line_1,
  address_line_2: values.address_line_2,
  town: values.town,
  county: values.county,
});
