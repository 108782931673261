import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import PreloaderBranding from '../../../../../../components/PreloaderBranding';
import { ConsentContext } from '../../../index';
import { LayoutTwoItemSpaceBetweenDotted } from '../../../../../../components';
import _ from 'lodash';

const General = ({ data, isProcessing }) => {
  const { customerName } = useContext(ConsentContext);
  if (!data) {
    return (
      <GeneralStyled preloader>
        <PreloaderBranding />
      </GeneralStyled>
    );
  }

  const { consent } = data;

  const { psu_account, created_at, psu_email } = consent;

  const {
    is_joint,
    address_line_1,
    address_line_2,
    county,
    first_name,
    last_name,
    postcode,
    salutation,
    town,
  } = psu_account;

  const fullLegalName = `${_.capitalize(salutation)}. ${first_name} ${last_name}`;

  return isProcessing ? (
    <GeneralStyled preloader>
      <PreloaderBranding />
    </GeneralStyled>
  ) : (
    <GeneralStyled>
      <div className={'reportRowsWrapper'}>
        <LayoutTwoItemSpaceBetweenDotted
          leftItem={'Request Date'}
          rightItem={moment(created_at).format('DD MMM YYYY HH:mm')}
        />
        <LayoutTwoItemSpaceBetweenDotted
          leftItem={'Requested By'}
          rightItem={customerName}
        />
        <LayoutTwoItemSpaceBetweenDotted
          leftItem={'Account Email'}
          rightItem={psu_email}
        />
        <LayoutTwoItemSpaceBetweenDotted
          leftItem={'Account full legal name'}
          rightItem={fullLegalName}
        />
        <LayoutTwoItemSpaceBetweenDotted
          leftItem={'Address'}
          rightItem={`${[
            county,
            town,
            postcode,
            address_line_1,
            address_line_2,
          ]}`}
        />
        <LayoutTwoItemSpaceBetweenDotted
          leftItem={'Account type'}
          rightItem={is_joint ? 'Joint' : 'Single'}
        />
      </div>
    </GeneralStyled>
  );
};

const GeneralStyled = styled.div`
  ${({ preloader }) => {
    if (preloader) {
      return css`
        min-height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
      `;
    }
  }};
  width: 100%;
  height: 100%;
  .reportRowsWrapper {
    width: 100%;
    margin: -0.75rem 0;
    display: flex;
    flex-flow: column nowrap;
  }
`;

export default General;
