import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Header from './Header';
import Footer from 'components/FooterGeneric';
import Page from './Page';
import { useLocation } from 'react-router-dom';
import { LayoutsTemplatesContext } from 'layouts/TemplatesContext';
import { getRouteByParam } from '../../utils/routes';
import { useSelector } from 'react-redux';
import { selectIsAuthSuccess } from '../../store/selectors/profile';
import Sidebar from 'components/Sidebar';

const useStyles = makeStyles(theme => theme.layouts.appMobile);

const useStylesDashboard = makeStyles(
  theme => theme.layoutsByPageType.appMobile.dashboard,
);

const useStylesDashboardCenter = makeStyles(
  theme => theme.layoutsByPageType.appMobile.dashboardCenter,
);

/* eslint-disable react/display-name */
export default ({ children }) => {
  const location = useLocation();

  const classes = useStyles();
  const classesDashboard = useStylesDashboard();
  const classesDashboardCenter = useStylesDashboardCenter();

  const routeParams = getRouteByParam(location.pathname, 'path');

  const isAuth = useSelector(selectIsAuthSuccess);

  let classesOverride = {};

  classesOverride = {
    main: classes.main,
    root: classes.root,
    header: classes.header,
    footer: classes.footer,
  };

  if (routeParams && routeParams.pageType) {
    switch (routeParams.pageType) {
      case 'dashboard':
        classesOverride = {
          ...classesOverride,
          main: classesDashboard.main,
        };
        break;
      case 'dashboardCenter':
        classesOverride = {
          ...classesOverride,
          main: classesDashboardCenter.main,
        };
        break;
      default:
        break;
    }
  }

  return (
    <LayoutsTemplatesContext.Provider
      value={{
        mainPage: Page,
        classes,
      }}
    >
      <div className={classesOverride.root}>
        <div className={classesOverride.header}>
          <Header />
        </div>
        <div style={{ width: '100%' }} className={classesOverride.main}>
          {children}
        </div>
        <div className={classesOverride.footer}>
          <Footer />
          {isAuth && <Sidebar />}
        </div>
      </div>
    </LayoutsTemplatesContext.Provider>
  );
};
