import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Container from 'components/Container';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import appConfig from 'config';
import { post } from '@src/utils/fetch';
import { useSelector } from 'react-redux';
import 'react-virtualized/styles.css';
import Button from '@material-ui/core/Button';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { getDatePeriodFromDateTime } from 'utils/common';
import AutoRenewIcon from 'components/AutoRenewIcon';
import { Redirect, useHistory } from 'react-router-dom';
import { ContentWHFullFlexXYCenter } from '../../../../components';
import PreloaderBranding from '../../../../components/PreloaderBranding';
import { apiCallWrapper } from '../../../../@src/utils/fetch';

const textOverflowEllipsis = {
  '& p': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

const useStyle = makeStyles(theme => ({
  scrollerHeader: {
    maxWidth: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    '& .MuiGrid-container': {},
    '& .MuiTypography-body1': {
      fontSize: '.75rem',
      fontWeight: '600',
    },
    '& .headerButtonWrapper': {
      paddingRight: '1rem',
      paddingLeft: '1rem',
      position: 'relative',

      '&::after': {
        top: '50%',
        right: '0',
        borderLeft: '1px solid #DDDDDD',
        content: '" "',
        height: '20px',
        width: '0px',
        transform: 'translateY(-50%)',
        position: 'absolute',
        pointerEvents: 'none',
      },
      '&:last-child': {
        '&::after': {
          display: 'none',
        },
      },
      '&:first-child': {},
      '& .headerButton': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        minWidth: '120px',
        width: '120px',
        color: '#5ABDD7',
        '&.MuiButton-root': {
          padding: 0,
          minWidth: 'auto',
          minHeight: 'auto',
          textTransform: 'none',
          '& .MuiButton-label': {
            pointerEvents: 'none',
          },
        },
      },
      '&.-active': {
        '&::after': {
          display: 'none',
        },
        backgroundColor: '#3C3C3B',
        '&.rejected': {
          backgroundColor: '#EB5757',
        },
        '&.expired': {
          backgroundColor: '#B8B4B2',
        },
        '&.confirm': {
          backgroundColor: '#5ABDD7',
        },
        '& .headerButton': {
          color: 'white',
        },
      },
    },
  },
  scrollerBody: {
    '& .scrollerRow': {
      cursor: 'pointer',
      transition: 'all 300ms',
      height: '4rem',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      '& .leftContainer': {
        '& .-leftTop': textOverflowEllipsis,
        '& .-leftBottom': {
          ...textOverflowEllipsis,
          color: '#B8B4B2',
          fontWeight: '300',
          fontSize: '.9rem',
        },
      },
      '& .rightContainer': {
        overflow: 'hidden',
        '& .-rightFirst': {
          ...textOverflowEllipsis,
          color: '#B8B4B2',
          fontSize: '.9rem',
          textAlign: 'right',
        },
        '& .-rightSecond': {
          padding: 0,
          display: 'flex',
          alignItems: 'center',
        },
      },
      '&.-even': {
        backgroundColor: '#FBFBFB',
      },
      '&:hover': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        '& .rightContainer .-rightFirst, & .leftContainer .-leftBottom': {
          color: 'white',
        },
      },
    },
  },
}));

// eslint-disable-next-line
const ScrollerRows = ({ history, scrollerData, setRedirect }) =>
  scrollerData &&
  scrollerData.map((v, i) => {
    const {
      customer: { company_name, first_name, last_name },
    } = v;

    return (
      <Grid
        onClick={event => {
          const { consent_id } = event.target.dataset;
          setRedirect(`/consent?id=${consent_id}`);
        }}
        data-consent_id={v.id}
        key={v.id}
        item
        style={{
          width: '100%',
        }}
        className={clsx('scrollerRow', i % 2 && '-even')}
      >
        <Grid
          style={{ pointerEvents: 'none' }}
          container
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Grid item className={'leftContainer'} xs={8}>
            <Grid
              container
              direction={'column'}
              spacing={1}
              wrap={'nowrap'}
              justifyContent={'flex-start'}
            >
              <Grid item className={'-leftTop'}>
                <Typography
                  style={{
                    fontVariant: 'small-caps',
                    fontWeight: '800',
                    fontSize: '1rem',
                  }}
                >
                  {company_name || `${first_name} ${last_name}`}
                </Typography>
              </Grid>
              <Grid item className={'-leftBottom'}>
                <Typography style={{}}>
                  {company_name && `${first_name} ${last_name}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={'rightContainer'} xs={4}>
            <Grid
              container
              spacing={2}
              direction={'row'}
              wrap={'nowrap'}
              justifyContent={'flex-end'}
            >
              <Grid item className={'-rightFirst'}>
                <Typography style={{}}>
                  {getDatePeriodFromDateTime(v.created_at)}
                </Typography>
              </Grid>
              <Grid item className={'-rightSecond'}>
                <ArrowRightIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  });

const ButtonProcessing = ({ handleOnClickMore, isProcessing }) => {
  return (
    <Button onClick={handleOnClickMore} variant={'contained'} color={'primary'}>
      {!isProcessing ? 'More' : <AutoRenewIcon />}
    </Button>
  );
};

const useDataFetch = (status, offset, id, setIsLoading) => {
  const [data, setData] = useState({ consents: [], hasMore: true });

  useEffect(() => {
    setIsLoading(true);

    const getReqBody = () => {
      return { psu_email: id, offset: offset, limit: 10, status: status };
    };

    const reqBody = getReqBody();

    apiCallWrapper({
      apiCall: () => post(appConfig.endpoints.consents, reqBody),
      onFinish: resp => {
        setData(data => {
          setIsLoading(false);
          const prev = offset === 0 ? [] : data.consents;
          return {
            consents: [...prev, ...resp.consents],
            hasMore: resp.has_more,
          };
        });
      },
    });
  }, [offset, id, status, setIsLoading]);

  return data;
};

const ConsentsList = () => {
  const classes = useStyle();
  const { userData } = useSelector(state => state.profile);
  const userEmail = userData.email;
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [offset, setOffset] = useState(0);
  const [redirect, setRedirect] = useState();
  const [activeTab, setActiveTab] = useState('pending');
  const history = useHistory();

  const { consents, hasMore } = useDataFetch(
    activeTab,
    offset,
    userEmail,
    offset === 0 ? setIsLoading : setIsProcessing,
  );

  const handleOnClickMore = React.useCallback(() => {
    setOffset(offset => offset + 10);
  }, [setOffset]);

  const handleActiveTabChange = React.useCallback(event => {
    event.stopPropagation();
    setActiveTab(() => {
      setOffset(0);
      return event.target.value;
    });
  }, []);

  const tabs = [
    'pending',
    'consent-data-shared',
    'consent-expired',
    'rejected',
    'revoked',
    'request-expired',
  ];

  const setIsActiveClassName = React.useCallback(
    (tab, current, tabs, className = '-active') => {
      if (tabs[tab] === current) return className;
    },
    [],
  );

  useEffect(() => {}, []);

  return (
    <div
      style={{
        paddingTop: '1rem',
        backgroundColor: 'white',
        display: 'grid',
        gridRowGap: '1rem',
        gridTemplateRows: `35px 80px 1fr`,
        gridTemplateAreas: '"header" "scroller-nav" "scroller-content"',
      }}
    >
      {redirect && <Redirect push to={redirect} />}
      <div
        style={{
          gridArea: 'header',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth={'xs'}>
          <Typography style={{ paddingBottom: 0 }} variant={'h2'}>
            Consents
          </Typography>
        </Container>
      </div>
      <div
        style={{
          gridArea: 'scroller-nav',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className={classes.scrollerHeader}
      >
        <Container maxWidth={'xs'}>
          <Grid container wrap={'nowrap'} style={{ height: '100%' }}>
            <Grid
              item
              className={clsx(
                'headerButtonWrapper',
                'new',
                setIsActiveClassName(0, activeTab, tabs),
              )}
            >
              <Button
                value={tabs[0]}
                onClick={handleActiveTabChange}
                className={clsx('headerButton')}
              >
                <Typography variant={'body1'}>New</Typography>
              </Button>
            </Grid>
            <Grid
              item
              className={clsx(
                'headerButtonWrapper',
                'confirm',
                setIsActiveClassName(1, activeTab, tabs),
              )}
            >
              <Button
                value={tabs[1]}
                onClick={handleActiveTabChange}
                className={'headerButton'}
              >
                <Typography variant={'body1'}>Data shared</Typography>
              </Button>
            </Grid>
            <Grid
              item
              className={clsx(
                'headerButtonWrapper',
                'expired',
                setIsActiveClassName(2, activeTab, tabs),
              )}
            >
              <Button
                value={tabs[2]}
                onClick={handleActiveTabChange}
                className={'headerButton'}
              >
                <Typography variant={'body1'}>Expired consents</Typography>
              </Button>
            </Grid>
            <Grid
              item
              className={clsx(
                'headerButtonWrapper',
                'rejected',
                setIsActiveClassName(3, activeTab, tabs),
              )}
            >
              <Button
                value={tabs[3]}
                onClick={handleActiveTabChange}
                className={'headerButton'}
              >
                <Typography variant={'body1'}>Rejected</Typography>
              </Button>
            </Grid>
            <Grid
              item
              className={clsx(
                'headerButtonWrapper',
                'confirmed',
                setIsActiveClassName(4, activeTab, tabs),
              )}
            >
              <Button
                value={tabs[4]}
                onClick={handleActiveTabChange}
                className={'headerButton'}
              >
                <Typography variant={'body1'}>Revoked</Typography>
              </Button>
            </Grid>
            <Grid
              item
              className={clsx(
                'headerButtonWrapper',
                'expired',
                setIsActiveClassName(5, activeTab, tabs),
              )}
            >
              <Button
                value={tabs[5]}
                onClick={handleActiveTabChange}
                className={'headerButton'}
              >
                <Typography variant={'body1'}>Expired requests</Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div
        style={{
          maxHeight: 'calc(100vh - 120px - 35px - 80px - 3rem)',
          height: 'calc(100vh - 120px - 35px - 80px - 3rem)',
          gridArea: 'scroller-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        {!isLoading ? (
          consents && (
            <Container
              maxWidth={'xs'}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Grid
                container
                wrap={'nowrap'}
                direction={'column'}
                className={classes.scrollerBody}
              >
                <Grid item>
                  <ScrollerRows
                    history={history}
                    setRedirect={setRedirect}
                    scrollerData={consents}
                  />
                </Grid>
                {hasMore && (
                  <Grid item>
                    <ButtonProcessing
                      handleOnClickMore={handleOnClickMore}
                      isProcessing={isProcessing}
                    />
                  </Grid>
                )}
              </Grid>
            </Container>
          )
        ) : (
          <Container maxWidth={'xs'} isHeightFull>
            <ContentWHFullFlexXYCenter>
              <PreloaderBranding />
            </ContentWHFullFlexXYCenter>
          </Container>
        )}
      </div>
    </div>
  );
};

export default ConsentsList;
