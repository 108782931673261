import { useEffect } from 'react';
import { defPageSize } from './index';
import { actions } from 'components/Table/reducer';
import { apiCallWrapper, post } from '@src/utils/fetch';
import appConfig from '../../../../../../../config';

const mapTransactionData = rawData => {
  return (
    rawData &&
    Array.isArray(rawData.transactions) &&
    rawData.transactions.map(t => ({
      date: t.ValueDateTime,
      amount: `${t.Amount.Amount} ${t.Amount.Currency}`,
      type: t.CreditDebitIndicator,
      balance: `${t.Balance.Amount.Amount} ${t.Balance.Amount.Currency}`,
    }))
  );
};

const useDataFetch = ({ consentId, state, dispatch }) => {
  const { offset, sortBy, sortOrder } = state;

  useEffect(() => {
    const fetchData = async () => {
      dispatch(actions.setIsProcessing(true));
      const newSortBy = sortBy => {
        switch (sortBy) {
          case 'date':
            return 'value_date_time_parsed';
          case 'amount':
            return 'amount_parsed';
          default:
            return 'value_date_time_parsed';
        }
      };

      apiCallWrapper({
        apiCall: () =>
          post(appConfig.endpoints.consentsTransactions, {
            consent_id: consentId,
            limit: defPageSize,
            offset,
            sort_by: newSortBy(sortBy),
            sort_order: sortOrder,
          }),
        onFinish: resp => {
          if (resp) {
            const data = mapTransactionData(resp);
            const total = resp.total;
            if (Array.isArray(data) && total !== undefined)
              dispatch(actions.setData(data, total));
          } else {
            dispatch(actions.setIsProcessing(false));
          }
        },
        // eslint-disable-next-line
        onFail: error => {
          dispatch(actions.setIsProcessing(false));
        },
      });
    };

    fetchData();
  }, [consentId, dispatch, offset, sortOrder, sortBy]);
};

export default useDataFetch;
