export default {
  spacing: {
    components: {
      form: {
        primary: 0,
        rePassword: 0,
      },
    },
    layouts: {
      page: {
        primary: 1,
        secondary: 2,
      },
    },
  },
};
