import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const AcceptDataSharing = ({ checked, onChange, customerName }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          name='Accept data share'
          color='primary'
        />
      }
      label={`I confirm that I have read and understood this information, and authorise Trustloop to share my online banking data with ${customerName}`}
    />
  );
}

export default AcceptDataSharing;
