import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileSignIn } from 'store/actions/profile';
import { Grid, Link, Typography } from '@material-ui/core';
import LayoutsPageInitAuthForm from 'layouts/PageInitAuthForm';
import {
  selectIsProcessing,
  selectIsValidationNeeded,
} from '../../../../store/selectors/profile';
import { Redirect } from 'react-router-dom';
import history from '../../../../utils/history';

const formFieldsMarkup = [
  {
    type: 'email',
    label: 'Email',
  },
  {
    type: 'password',
    label: 'Password',
  },
];

const title = 'Sign In';

const SignIn = () => {
  const dispatch = useDispatch();
  const isProcessing = useSelector(selectIsProcessing);
  const isValidationNeeded = useSelector(selectIsValidationNeeded);

  const handleOnSubmit = useCallback(
    values => {
      dispatch(setProfileSignIn(values));
    },
    [dispatch],
  );

  const handleTextUnderTheFromLinkOnClick = React.useCallback(
    () => history.push('/forgot_password'),
    [],
  );

  if (isValidationNeeded) {
    return (
      <Redirect
        exact={'true'}
        to={{
          pathname: '/validation',
          state: { doNotStartCountdown: true },
        }}
      />
    );
  }

  const LayoutsPageInitAuthFormParams = {
    pageTitle: title,
    formParams: {
      fieldsMarkup: formFieldsMarkup,
      handleOnSubmit,
      loading: isProcessing,
    },
    textAboveTheForm: (
      <>
        <Grid item>
          <Typography variant={'h2'}>{title}</Typography>
        </Grid>
      </>
    ),
    textUnderTheForm: (
      <Grid item xs={12} align={'center'}>
        <Link variant={'subtitle2'} onClick={handleTextUnderTheFromLinkOnClick}>
          Forgot Password?
        </Link>
      </Grid>
    ),
  };

  return <LayoutsPageInitAuthForm {...LayoutsPageInitAuthFormParams} />;
};

export default SignIn;
