import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import history from './utils/history';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { CssBaseline } from '@material-ui/core';
import store from 'config/store';
import theme from 'config/theme';
import theme_styled from 'config/theme_styled';
import config from 'config';
import App from './App';

console.debug(`Current app type: ${config.APP_TYPE}`);
console.debug(`Current backend type: ${config.BACKEND_TYPE}`);
console.debug(`Current env: ${config.ENV}`);
const root = createRoot(document.getElementById('root')); // createRoot(container!) if you use TypeScript
const ProviderWrapper = () => (
  <Provider store={store}>
    <CssBaseline />
    <StyledThemeProvider theme={theme_styled}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <App />
        </Router>
      </ThemeProvider>
    </StyledThemeProvider>
  </Provider>
);

// ReactDOM.render(<ProviderWrapper />, document.getElementById('root'));
root.render(<ProviderWrapper />);
