import React from 'react';
import Common from './Common';
import Transactions from './ConsentDetails/Transactions';
import Tabs, { Tab } from 'components/Tabs';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { TitleWithBackButton } from '../../../../../components';

const TabsStyled = styled(Tabs)`
  .tabLabels_wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: auto;
    margin: 1rem -0.5rem;
    .tabLabel {
      font-weight: 800;
    }
  }
`;

const Confirmed = ({ customerName, consentId, account, status, bank }) => {
  return (
    <div className="page_consentRequest_confirmed_wrapper">
      <TitleWithBackButton>
        <Typography variant="h2">BankLoop Report</Typography>
      </TitleWithBackButton>
      <TabsStyled defTab="General Info">
        <Tab label="General Info">
          <Common
            bankName={bank}
            account={account}
            status={status}
            consentId={consentId}
            customerName={customerName}
          />
        </Tab>
        <Tab label="Bank transactions">
          <Transactions consentId={consentId} />
        </Tab>
      </TabsStyled>
    </div>
  );
};

export default Confirmed;
