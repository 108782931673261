import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setProfileLogOut,
  setProfileValidationCodeFromSms,
} from 'store/actions/profile';
import { Grid, Link, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import LayoutsPageInitAuthForm from 'layouts/PageInitAuthForm';
import { useLocation } from 'react-router-dom';
import { apiCallWrapper, post } from '@src/utils/fetch';
import appConfig from '../../../../config';
import { toast } from 'react-toastify';
import Box from '@material-ui/core/Box';
import Button from '../../../../components/Button';
import { GridRowsWrapper } from '../../../../components';
import styled from 'styled-components';
import history from '../../../../utils/history';
import { selectIsProcessing } from '../../../../store/selectors/profile';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const title = 'Validation';

const formFieldsMarkup = [
  {
    name: 'code',
    type: 'text',
    label: 'Validation code',
    validationGroup: 'number',
  },
];

const timeoutDefValue = 10;

const TextUnderTheFormRender = ({
  timeout,
  handleOnResendValidationCode,
  handleLinkCancelValidationOnClick,
}) => (
  <Box width={'100%'}>
    <Grid item xs={12} align={'center'}>
      {timeout !== 0 && (
        <Box pb={5}>
          <TimeoutTextWrapper>
            {`Please wait ${timeout} before trying to send code again`}
          </TimeoutTextWrapper>
        </Box>
      )}
      <Box pb={5}>
        <GridRowsWrapper>
          <Button
            wide
            disabled={timeout !== 0}
            customColor={'low'}
            href="#"
            onClick={() => handleOnResendValidationCode('email')}
          >
            Resend code by Email
          </Button>
          <Button
            wide
            disabled={timeout !== 0}
            customColor={'low'}
            onClick={() => handleOnResendValidationCode('sms')}
          >
            Resend code by SMS
          </Button>
        </GridRowsWrapper>
      </Box>
      <Link onClick={handleLinkCancelValidationOnClick} variant={'subtitle2'}>
        Cancel validation
      </Link>
    </Grid>
  </Box>
);

const Validation = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [timeout, setTimeout] = useState(0);
  const { state: doNotStartCountdown = 0 } = useLocation();
  const isProcessing = useSelector(selectIsProcessing);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleResendTimeout = useCallback(
    time => {
      setTimeout(time);
      const interval = setInterval(
        () =>
          setTimeout(timeout => {
            if (timeout <= 1) {
              sessionStorage.setItem('countdown', '0');
              clearInterval(interval);
              return 0;
            }
            const newTimeout = timeout - 1;
            sessionStorage.setItem('countdown', newTimeout.toString());
            return newTimeout;
          }),
        1000,
      );
    },
    [setTimeout],
  );

  const handleLinkCancelValidationOnClick = useCallback(() => {
    dispatch(setProfileLogOut());
    history.push('sign_in');
  }, [dispatch]);

  const handleOnResendValidationCode = useCallback(
    (type = 'sms') =>
      apiCallWrapper({
        onAny: () => handleResendTimeout(timeoutDefValue),
        apiCall: () =>
          post(appConfig.endpoints.reSendConfirmCode, { confirm_by: type }),
        onFinish: () => {
          const msg = (() => {
            if (type === 'sms') {
              return 'Validation code were sent. Please check SMS';
            }
            return 'Validation code were sent. Please check your email';
          })();
          toast.success(msg);
        },
        notify: true,
      }),
    [handleResendTimeout],
  );

  const handleOnSubmit = useCallback(
    async values => {
      const data = {
        code: values.code,
      };
      const token = await executeRecaptcha('validation_code')
      dispatch(setProfileValidationCodeFromSms({data, token}));
    },
    [dispatch, executeRecaptcha],
  );

  useEffect(() => {
    const countdown = parseInt(sessionStorage.getItem('countdown'));
    if (countdown > 0 || !doNotStartCountdown) {
      handleResendTimeout(countdown || timeoutDefValue);
    }
  }, [handleResendTimeout, doNotStartCountdown]);

  const layoutsPageInitAuthFormParams = {
    pageTitle: title,
    formParams: {
      loading: isProcessing,
      fieldsMarkup: formFieldsMarkup,
      handleOnSubmit,
    },
    textUnderTheForm: (
      <TextUnderTheFormRender
        timeout={timeout}
        handleLinkCancelValidationOnClick={handleLinkCancelValidationOnClick}
        handleOnResendValidationCode={handleOnResendValidationCode}
      />
    ),
    textAboveTheForm: (
      <Grid
        container
        spacing={theme.defaults.spacing.layouts.page.primary}
        align="center"
      >
        <Grid item xs={12}>
          <Typography variant={'h2'}>{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'subtitle1'}>
            Please, enter validation code
          </Typography>
        </Grid>
      </Grid>
    ),
  };

  return <LayoutsPageInitAuthForm {...layoutsPageInitAuthFormParams} />;
};

const TimeoutTextWrapper = styled.span``;

export default Validation;
