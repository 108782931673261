import React from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';
import { ContentCenter } from 'components';
import 'react-toastify/dist/ReactToastify.css';
import ToastSuccess from '../../svg/ToastSuccess';
import ToastFail from '../../svg/ToastFail';

const CustomToastContainer = props => {
  return (
    <ToastContainer
      {...props}
      transition={ToastFade}
      position={'top-center'}
      closeButton={<ToastCloseButton />}
    />
  );
};

const ToastCloseButton = ({ type, closeToast }) => {
  let button = <ToastSuccess />;
  switch (type) {
    case 'success':
      button = <ToastSuccess />;
      break;
    case 'error':
      button = <ToastFail />;
      break;
    default:
      button = <ToastSuccess />;
      break;
  }
  return <ContentCenter onClick={closeToast}>{button}</ContentCenter>;
};

const ToastFade = cssTransition({
  enter: 'Toastify__fade-enter',
  exit: 'Toastify__fade-exit',
  duration: [300, 300],
  appendPosition: false,
});

export default CustomToastContainer;
