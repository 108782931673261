import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthSuccess } from 'store/selectors/profile';
import ProfileMenu from 'components/ProfileMenu';
import { AppBar, Grid, Link, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SvgLogo from 'svg/Logo';
import Container from 'components/Container';
import history from '../../../utils/history';

const useStyles = makeStyles(theme => theme.components.header);

function Header() {
  const classes = useStyles();
  const [userMenu, setUserMenu] = React.useState(false);
  const isAuth = useSelector(selectIsAuthSuccess);

  useEffect(() => {
    if (isAuth) {
      setUserMenu(true);
    } else {
      setUserMenu(false);
    }
  }, [isAuth, setUserMenu]);

  const handleLogoOnClick = useCallback(() => history.push('/dashboard'), []);

  return (
    <AppBar elevation={0} color={'primary'} position="static">
      <Toolbar
        classes={{
          root: classes.toolbar,
          gutters: classes.gutters,
        }}
      >
        <Container maxWidth={'xs'}>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'space-between'}
            wrap={'nowrap'}
          >
            <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <Link onClick={handleLogoOnClick}>
                <SvgLogo className={classes.logo} width={160} />
              </Link>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {userMenu && <ProfileMenu />}
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
