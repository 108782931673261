import clsx from 'clsx';
import React from 'react';
import moment from 'moment';

export const defSortIcons = () => (
  <>
    <i className="sortIcon -up fa fa-chevron-up" aria-hidden="true" />
    <i className="sortIcon -down fa fa-chevron-down" aria-hidden="true" />
  </>
);

export const renderTHeaderTitle = ({
  title,
  sort = false,
  myClassName = '-tCenter',
  sortIcons = defSortIcons(),
  isText = true,
}) => (
  <div className={clsx(isText && 'textCell', myClassName)}>
    <span className="title_wrapper">{title}</span>
    <span className="sort_wrapper">{sort && sortIcons}</span>
  </div>
);

/* eslint-disable react/display-name */
export const cellRender = ({ title, isText = true }) => props => (
  <div className={clsx(isText && 'textCell', title)}>{props.value}</div>
);

/* eslint-disable react/display-name */
export const cellRenderDate = ({ title }) => props => (
  <div className={title}>
    {props.value && moment(props.value).format('MMM-YY')}
  </div>
);

export const onPageChange = ({ defPageSize, setOffset }) => props => {
  setOffset(props * defPageSize);
  return props;
};

export const handlePagination = ({
  sortOrder,
  sortBy,
  setSort,
  defPageSize,
  offset,
  total,
}) => props => {
  const { id, desc } = props.sorted[0];

  if (sortBy !== id || sortOrder !== desc) {
    setSort({
      by: id,
      order: desc,
    });
  }

  if (total > offset + defPageSize) {
    props.canNext = true;
  }
  if (offset > 0) {
    props.canPrevious = true;
  }

  if (total >= defPageSize) {
    props.page = offset / defPageSize;
  } else {
    props.page = 0;
  }

  if (total) {
    props.pages = Math.floor(total / defPageSize);
    if (total % defPageSize > 0) {
      props.pages = props.pages + 1;
    }
  }

  return props;
};

export const nextText = () => (
  <span>
    <i className="fa fa-chevron-right" aria-hidden="true" />
  </span>
);

export const prevText = () => (
  <span>
    <i className="fa fa-chevron-left" aria-hidden="true" />
  </span>
);

export const expiredTimeFormat = expiredInRaw => {
  return expiredInRaw > 23
    ? `${Math.floor(expiredInRaw / (24 * 3600))}d`
    : `${expiredInRaw}h`;
};

export const formatPeriod = period => {
  let till = 0;
  let from = 0;

  switch (period) {
    case 'current_month':
      till = moment(Date.now()).unix();
      from = moment(Date.now())
        .startOf('months')
        .unix();
      break;
    case 'current_week':
      till = moment(Date.now()).unix();
      from = moment(Date.now())
        .startOf('week')
        .unix();
      break;
    case 'past_month':
      till = moment(Date.now())
        .subtract(1, 'month')
        .endOf('month')
        .unix();
      from = moment(Date.now())
        .subtract(1, 'month')
        .startOf('month')
        .unix();
      break;
    case 'all':
    default:
      till = 0;
      from = 0;
  }
  return { till, from, period };
};

export default {
  cellRender,
  cellRenderDate,
  renderTHeaderTitle,
  handlePagination,
  onPageChange,
  formatPeriod,
};
