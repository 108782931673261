import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { setProfileLogOut } from '../../store/actions/profile';
import { useDispatch } from 'react-redux';

const Sidebar = ({ className }) => {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleClose = () => {
    setOpen(false);
  };

  const toggleOpen = () => {
    setOpen(open => !open);
  };

  return (
    <div
      className={className}
      onClick={e => {
        e.preventDefault();
      }}
    >
      <div className={'fabWrapper'}>
        <Fab color={'primary'} onClick={() => toggleOpen()}>
          {!open ? <MenuIcon /> : <CloseIcon />}
        </Fab>
      </div>
      <div id={'sidebar'} className={clsx(open && 'isOpen')}>
        <Button
          onClick={() => {
            handleClose();
            history.push('/account');
          }}
        >
          <Typography
            className={clsx(pathname === '/account' && 'isActive')}
            variant={'h5'}
          >
            Account
          </Typography>
        </Button>
        <Button
          onClick={() => {
            handleClose();
            history.push('/action_log');
          }}
        >
          <Typography
            className={clsx(pathname === '/action_log' && 'isActive')}
            variant={'h5'}
          >
            Action Log
          </Typography>
        </Button>
        <Button
          onClick={() => {
            handleClose();
            history.push('/dashboard');
          }}
        >
          <Typography
            className={clsx(pathname === '/dashboard' && 'isActive')}
            variant={'h5'}
          >
            Consents
          </Typography>
        </Button>
        <Button
          onClick={() => {
            handleClose();
            dispatch(setProfileLogOut());
          }}
        >
          <Typography variant={'h5'}>Logout</Typography>
        </Button>
      </div>
    </div>
  );
};

const SidebarStyled = styled(Sidebar)`
  .fabWrapper {
    position: fixed;
    z-index: 2000;
    right: 1em;
    bottom: 1rem;
    .MuiFab-root {
      width: 3rem;
      height: 3rem;
    }
  }

  #sidebar {
    position: fixed;
    background-color: white;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    height: 100vh;
    transform: translateX(-100vw);
    transition: 300ms all;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    & .MuiTypography-root.isActive {
      background-color: #5abdd7;
      padding: 1rem;
      color: white;
    }
  }
  #sidebar.isOpen {
    transform: translateX(0);
  }
`;

export default SidebarStyled;
