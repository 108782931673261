import styled from 'styled-components';

const H2 = styled.h2`
  color: #5abdd7;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
`;

export default H2;
