import React, { useContext } from 'react';
import { ConsentContext } from '../index';
import { TitleWithBackButton } from '../../../../components';
import { Typography } from '@material-ui/core';
import Status from '../parts/Status';

const Rejected = () => {
  const { customerName } = useContext(ConsentContext);

  return [
    <TitleWithBackButton key={'TitleWithBackButton'}>
      <Typography variant="h1">{'BankLoop Request'}</Typography>
    </TitleWithBackButton>,
    <Status key={'Status'} status={'rejected'} customerName={customerName} />,
  ];
};

export default Rejected;
