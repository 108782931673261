import React, { useState } from 'react';
import LayoutsPage from '../../../../layouts/Page';
import RejectSvg from '../../../../svg/Reject';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Button from '../../../../components/Button';
import { Redirect, useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const DataSharingRejected = ({ customerName, consentId }) => {
  const classes = useStyles();
  const history = useHistory();
  const [redirect, setRedirect] = useState(null);

  /*
   * Maybe additional SMS or email validation
   */
  /*const handleOnRevoke = useCallback(
    consent_id => {
      apiCallWrapper({
        notify: true,
        onStart: () => setIsProcessing(true),
        onAny: () => setIsProcessing(false),
        apiCall: () =>
          post(appConfig.endpoints.consentsRevoke, {
            consent_id: consent_id,
          }),
        onFinish: resp => {
          toast.success('Successfully revoked');
          history.push('/');
        },
        onFail: e => {
          toast.error('Server connection error. Please, try again later');
          history.push('/');
        },
      });
    },
    [history],
  );*/

  if (redirect === 'reject') {
    return <Redirect to={`/consent?id=${consentId}`} />;
  }

  return (
    <LayoutsPage>
      <div className={classes.root}>
        <RejectSvg />
        <Typography variant={'h4'}>Rejected</Typography>
        <Grid container spacing={1} justify={'center'} alignItems={'center'}>
          <Grid item xs={12}>
            <Typography align={'center'}>
              You rejected <strong>TrustLoop</strong>’s consent request which
              means neither <strong>TrustLoop</strong> nor{' '}
              <strong>{customerName}</strong> will be able to access any of your
              bank data.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align={'center'}>
              You can revoke <strong>TrustLoop</strong>’s access to your online
              banking data by clicking revoke
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              wide
              style={{ flexGrow: '1' }}
              variant={'contained'}
              color={'primary'}
              onClick={() => setRedirect('reject')}
            >
              REVOKE
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              wide
              style={{ flexGrow: '1' }}
              variant={'contained'}
              color={'primary'}
              onClick={() => history.push('/')}
            >
              DONE
            </Button>
          </Grid>
        </Grid>
      </div>
    </LayoutsPage>
  );
};

export default DataSharingRejected;
