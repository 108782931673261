export default {
  root: ({ superWide }) => {
    let styles = {
      height: '100%',
      margin: '1rem 0',

      '& .MuiExpansionPanel-root': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '& .MuiExpansionPanelSummary-root': {
          padding: '0 1rem 0 1rem',
        },
        '& .MuiExpansionPanelDetails-root': {
          backgroundColor: 'transparent',
          padding: '.5rem 1rem 0 1rem',
        },
      },
      '& .superRoot': {
        '& .superRoot_summary': {
          padding: '0 0 0 0',
        },
        '& .superRoot_details': {
          padding: '0 0 0 0',
        },
      },
      '& .innerExpansionPanel': {
        width: '100%',
        '& .MuiExpansionPanelDetails-root': {
          backgroundColor: '#f1f1f1',
          paddingBottom: '1rem',
        },
      },
    };

    if (superWide) {
      styles = {
        ...styles,
        '& .superRoot': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          padding: '1rem 0 1rem 0',
          marginLeft: '-1rem',
          marginRight: '-1rem',
          '& .superRoot_details': {
            backgroundColor: 'transparent',
            padding: '0 0 0 0',
          },
          '& .superRoot_summary': {
            backgroundColor: 'transparent',
            padding: '1rem 1rem 1rem 1rem !important',
            borderTop: '1px solid #EEEEEE',
            borderBottom: '1px solid #EEEEEE',
          },
          '& > .MuiCollapse-container': {
            marginTop: '1rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          },
        },
      };
    }

    return styles;
  },
  rootPanel: {
    '&>.MuiExpansionPanelDetails-root': {
      padding: '0 0 0 0',
    },
  },
  innerPanels: {
    padding: '0 0 0 0',
    '& .MuiExpansionPanel-root::before': {
      display: 'none !important',
    },
    '& .MuiPaper-root': {
      '& .MuiExpansionPanelSummary-root': {
        padding: '0 1rem 0 1rem',
        backgroundColor: '#f1f1f1',
      },
    },
  },
  innerPanels_header: {
    fontWeight: 600,
  },
};
