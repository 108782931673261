import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import profile from 'store/middleware/profile';
import reducers from 'store/reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers(reducers);

const middleware = [
  profile,
  thunk,
];

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware.filter(m => !!m))),
);

export default store;
