import React from 'react';
import { Typography } from '@material-ui/core';
import styles from '../styles';

const ExpiredConsentPart1 = () => (
  <>
    <Typography style={{ fontSize: '1.1rem', paddingBottom: '1rem' }}>
      Access to Bank (CURRENT ACCOUNT) requires renewal
    </Typography>
    <Typography>
      Please re-authenticate with your account provider for us to continue
      accessing the information you previously consented to.
      <br />
      Check details in our <span style={styles.primary}>Terms</span> of use and{' '}
      <span style={styles.primary}>Privacy Policy</span>
    </Typography>
  </>
);

export default ExpiredConsentPart1;
