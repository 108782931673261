import React from 'react';
import Box from '@material-ui/core/Box';
import { LayoutTwoItemSpaceBetweenDotted } from '../../../../../components';
import Typography from '@material-ui/core/Typography';

const Confirmed = ({ customerName, bankName, account, status }) => {
  let statusDisplayText = 'CONFIRMED';

  switch (status) {
    case 'consent-data-received':
      statusDisplayText = 'CONFIRMED';
      break;
    case 'consent-data-shared':
      statusDisplayText = 'SHARED';
      break;
    case 'consent-data-sharing-rejected':
      statusDisplayText = 'SHARING REJECTED';
      break;
    default:
      statusDisplayText = 'CONFIRMED';
  }

  return (
    <Box py={2}>
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Requested by'}
        rightItem={customerName}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Reason of request'}
        rightItem={'BankLoop'}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Bank'}
        rightItem={<strong>{bankName.toUpperCase()}</strong> || '-'}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Bank account(s)'}
        rightItem={account || 'Current account'}
      />
      <LayoutTwoItemSpaceBetweenDotted
        leftItem={'Status'}
        rightItem={
          <div>
            <Typography color={'primary'} style={{ fontWeight: '800' }}>
              {statusDisplayText}
            </Typography>
          </div>
        }
      />
    </Box>
  );
};

export default Confirmed;
