import appConfig from '../config';

export function getUserDataFromStorage() {
  const userData = appConfig.storage.getItem('userData');
  return userData && JSON.parse(userData);
}

export function removeUserDataFromStorage() {
  return appConfig.storage.removeItem('userData');
}

export function writeUserDataToStorage(userData = null) {
  return (
    userData && appConfig.storage.setItem('userData', JSON.stringify(userData))
  );
}
