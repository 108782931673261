import React, { useMemo } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField as TextFieldInput
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { makeStyles } from '@material-ui/styles';
import { Field } from 'react-final-form-html5-validation';
import { TextField } from 'final-form-material-ui';
import Password from 'components/Form/fields/Password';
import { composeValidators, validationByFieldType } from 'utils/validators';
import PostcodeAutocomplete from './fields/PostcodeAutocomplete';
import { postcodePattern } from '../../utils/validators';

const useStyle = makeStyles(() => ({
  checkbox: {
    paddingTop: '1rem',
    '& .MuiTypography-body1': {},
    '& .MuiButtonBase-root': {},
  },
}));

export const formFieldsRenderer = (config, argsObj) => {
  return config.map((field, key) => {
    let customProps = {
      disabled: field.disabled || false,
    };
    let customInputProps = {};
    let fieldId = `${field.id}`;
    if (field.name) {
      fieldId = `${field.name}`;
    }

    let validatorsList = [];

    const classes = useStyle();

    const gridItemParams = field.grid || {
      xs: 12,
      className: 'fieldInputCommon',
    };

    let isHidden = {
      display: field.hidden ? 'none' : 'block',
    };

    const textAbove = <Grid item>{field.textAbove}</Grid>;

    if (field.displayIfCheckboxTrue) {
      if (argsObj.values[field.displayIfCheckboxTrue] !== true) {
        isHidden = {
          display: 'none',
        };
        customProps.validate = () => undefined;
      }
    }

    switch (field.type) {
      case 'area':
      case 'text':
      case 'number':
        if (validationByFieldType[field.name]) {
          validatorsList = [...validationByFieldType[field.name]];
        }

        if (validationByFieldType[field.validationGroup]) {
          validatorsList = [...validationByFieldType[field.validationGroup]];
        }

        if (field.singleValidator) {
          validatorsList.push(field.singleValidator);
        }

        if (field.validators) {
          validatorsList.push(...field.validators);
        }

        if (field.validatorsOverride) {
          validatorsList = field.validatorsOverride;
        }

        if (field.type === 'area') {
          customInputProps.rows = 5;
          customInputProps.multiline = true;
        }

        if (field.type === 'number') {
          customInputProps.min = field.min;
          customInputProps.max = field.max;
        }

        return (
          <Grid style={isHidden} key={key} item {...gridItemParams}>
            {textAbove}
            <Field
              name={field.name}
              label={field.label}
              /* eslint-disable react/display-name */
              component={useMemo(
                () => ({ input, inputProps, ...rest }) => {
                  return (
                    <TextField input={{ ...input, ...inputProps }} {...rest} />
                  );
                },
                [],
              )}
              fullWidth={true}
              validate={composeValidators(validatorsList)}
              type={field.type || 'text'}
              inputProps={customInputProps}
              {...customProps}
            />
          </Grid>
        );
      case 'phone':
        return (
          <Grid key={key} item {...gridItemParams}>
            <Field
              name={field.name}
              validate={composeValidators(validationByFieldType['phone'])}
              {...customProps}
            >
            {props => {
              return (
                <InputMask
                  mask="+44 99999 999 9999"
                  maskChar={null}
                  {...props.input}
                >
                  {() => 
                    <TextFieldInput
                      fullWidth={true}
                      label={field.label}
                      error={!!(props.meta.touched && props.meta.error)}
                      helperText={props.meta.touched && props.meta.error}
                      {...props.input}
                    />}
                </InputMask>
              )}}
            </Field>
          </Grid>
        );
      case 'email':
        return (
          <Grid key={key} item {...gridItemParams}>
            <Field
              name={`email`}
              label={field.label}
              type={'text'}
              component={TextField}
              fullWidth={true}
              inputProps={customInputProps}
              validate={composeValidators(validationByFieldType['email'])}
              {...customProps}
            />
          </Grid>
        );
      case 'checkbox':
        return (
          <Grid
            key={key}
            style={isHidden}
            className={classes.checkbox}
            item
            {...gridItemParams}
          >
            {textAbove}
            <Field
              name={fieldId}
              type={'checkbox'}
              fullWidth={true}
              inputProps={customInputProps}
              {...customProps}
            >
              {props => {
                return (
                  <FormControlLabel
                    label={field.label}
                    control={<Checkbox {...props.input} color="primary" />}
                    labelPlacement="end"
                  />
                );
              }}
            </Field>
          </Grid>
        );
      case 'password':
        return (
          <Grid key={key} item {...gridItemParams}>
            <Field
              name={`password`}
              label={`Password`}
              component={Password}
              formValues={argsObj.formValues}
              fullWidth={true}
              handlers={argsObj.handlers.password}
              isAdornment={true}
              inputProps={customInputProps}
              required
              {...customProps}
            />
          </Grid>
        );
      case 'select':
        return (
          <Grid key={key} item {...gridItemParams}>
            {textAbove}
            <Field
              name={`${fieldId}`}
              fullWidth={true}
              component={Select}
              validate={composeValidators(validationByFieldType['select'])}
              inputProps={customInputProps}
              {...customProps}
            >
              {props => {
                return (
                  <FormControl
                    {...props}
                    className="form-field"
                    fullWidth
                    component="div"
                    error={!!(props.meta.touched && props.meta.error)}
                  >
                    <InputLabel htmlFor={fieldId}>{field.label}</InputLabel>
                    <Select {...props.input} id={fieldId} type={fieldId}>
                      {field.initValue.map(v => (
                        <MenuItem key={v.label} value={v.value}>
                          {v.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {props.meta.touched && props.meta.error && (
                      <FormHelperText id={`${fieldId}-error`}>
                        {props.meta.error}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            </Field>
          </Grid>
        );
      case 'postcodeAutocomplete':
        return (
          <Grid key={key} item xs={12}>
            <Field
              name={`postcode`}
              component={PostcodeAutocomplete}
              validateFunc={value =>
                postcodePattern.test(value) && value.length > 5
              }
              validate={composeValidators(field.validators)}
              handleOnOptionSelect={value =>
                argsObj.form.mutators[field.mutatorName](value)
              }
              inputProps={customInputProps}
              {...customProps}
            />
          </Grid>
        );
      case 'password_re':
        return (
          <Grid key={key} container spacing={1}>
            <Grid item xs={12}>
              <Field
                name={`password`}
                label={`Password`}
                component={Password}
                formValues={argsObj.formValues}
                fullWidth={true}
                handlers={argsObj.handlers.password}
                validate={composeValidators(validationByFieldType['password'])}
                inputProps={customInputProps}
                {...customProps}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={`re_password`}
                label={`Repeat password`}
                component={Password}
                formValues={argsObj.formValues}
                handlers={argsObj.handlers.password}
                isAdornment={true}
                fullWidth={true}
                inputProps={customInputProps}
                {...customProps}
              />
            </Grid>
          </Grid>
        );
      default:
        throw new Error('renderFormFields: Type is not recognized');
    }
  });
};

export default formFieldsRenderer;
