import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsAuthSuccess,
  selectIsValidationNeeded,
} from 'store/selectors/profile';
import { setProfileAutoSignIn } from 'store/actions/profile';
import clsx from 'clsx';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/styles';
import LayoutsTemplates from 'layouts/Templates';
import ReCaptchaProvider from './ReCaptchaProvider';
import config from 'config';
import { renderRoutes } from 'utils/routes';
import PageNotFound from 'containers/404';
import CustomToastContainer from './components/CustomToastContainer';
import 'assets/styles/index.css';
import 'assets/fonts/fonts.css';
const useStyles = makeStyles({});

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthSuccess = useSelector(selectIsAuthSuccess);
  const isValidationNeeded = useSelector(selectIsValidationNeeded);

  const currentUrl = location.pathname + location.search;

  useEffect(() => {
    if (!isAuthSuccess && !isValidationNeeded) {
      dispatch(setProfileAutoSignIn(currentUrl));
    }
  }, [dispatch, currentUrl, isAuthSuccess, isValidationNeeded]);

  const routesFinal = useMemo(
    () => renderRoutes(config.routes, isAuthSuccess),
    [isAuthSuccess],
  );

  return (
    <div
      style={{ outline: 'none', overflowX: 'hidden' }}
      className={clsx('App', classes.root)}
    >
      <LayoutsTemplates>
        <ReCaptchaProvider>
          <Switch>
            {routesFinal}
            {isAuthSuccess ? (
              <Redirect
                exact={true}
                from="/"
                to={config.defaultRedirectPrivateRoute}
              />
            ) : (
              <Redirect
                exact={true}
                from="/"
                to={config.defaultRedirectPublicRoute}
              />
            )}
            <Redirect exact={true} from="/home" to="/" />
            <Route exact={false} path="/" component={PageNotFound} />
          </Switch>
        </ReCaptchaProvider>
      </LayoutsTemplates>
      <CustomToastContainer autoClose={config.toastAutoCloseTimeout} />
    </div>
  );
}

export default App;
